import { editTripApi } from "./duck/services";
import { enqueueSnackbar } from "../Master/Notifier/duck";
import { UPDATE_TRIP } from "./duck";

export const SubmitEditTrip = parentProps => (values, dispatch, props) => {
  const {
    auth: { token },
    trip: {
      tripdata: { trip_id }
    },
    setDialogOpen
  } = parentProps;

  const formData = {
    trip_id: String(trip_id),
    name: values.name,
    origin: values.origin.address,
    from_dt: values.from_dt,
    to_dt: values.to_dt,
    lat: values.origin.lat,
    longi: values.origin.lng,
    city: values.origin.city,
    type: values.type
  };

  return editTripApi(formData, token)
    .then(res => {
      if (res.data.status === 200) {
        dispatch({
          type: UPDATE_TRIP,
          payload: formData
        });
        dispatch(
          enqueueSnackbar({
            message: "Trip details updated",
            variant: "success"
          })
        );
        setDialogOpen(false);
      } else {
        dispatch(
          enqueueSnackbar({
            message: "Some error occured",
            variant: "error"
          })
        );
      }
    })
    .catch(err => {
      dispatch(
        enqueueSnackbar({
          message: "Some error occured",
          variant: "error"
        })
      );
    });
};
