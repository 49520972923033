import React from "react";
import { GridContainer, MapContainer, PlanContainer } from "./styles";
import GoogleMap from "../common/GoogleMap";
import Plan from "./Plan";
import { MY_ID } from "../constants";

const TripExplorerPure = (props) => {
  const {
    markers,
    trip: {
      tripdata: { lat, longi },
    },
    match: {
      params: { type, id },
    },
    checkboxes,
    handleCheckboxChange,
    handleActivityNote,
    duration,
    setDuration,
    fetchTrip,
  } = props;

  return (
    <GridContainer container>
      <MapContainer item xs={12} md={6}>
        <GoogleMap
          setDuration={setDuration}
          lat={+lat}
          lng={+longi}
          markers={markers}
        />
      </MapContainer>
      <PlanContainer item md={6} xs={12}>
        <Plan
          checkboxes={checkboxes}
          handleCheckboxChange={handleCheckboxChange}
          handleActivityNote={handleActivityNote}
          type={type}
          duration={duration}
          fetchTrip={() => fetchTrip(id, MY_ID)}
        />
      </PlanContainer>
    </GridContainer>
  );
};

export default TripExplorerPure;
