import {
  GET_TRIP_REQUEST,
  GET_TRIP_FAILURE,
  GET_TRIP_SUCCESS,
  GET_IMPORT_TRIP_REQUEST,
  GET_IMPORT_TRIP_FAILURE,
  GET_IMPORT_TRIP_SUCCESS
} from "./types";
import { fetchTripApi } from "./services";
import { enqueueSnackbar } from "../../Master/Notifier/duck";
import { MY_ID } from "../../constants";

export const fetchTrip = (id, type) => {
  
  return (dispatch, getState) => {
    dispatch({
      type: GET_TRIP_REQUEST
    });
    const state = getState();
    return fetchTripApi(id, !type ? MY_ID : type, state.auth.token)
      .then(res => {
        if (res.data.status === 200) {
          dispatch({
            type: GET_TRIP_SUCCESS,
            payload: res.data
          });
        } else {
          dispatch({
            type: GET_TRIP_FAILURE,
            payload: res.data
          });
          dispatch(
            enqueueSnackbar({
              message: "Something went wrong",
              variant: "error"
            })
          );
        }
      })
      .catch(err => {
        dispatch(
          enqueueSnackbar({
            message: "Something went wrong",
            variant: "error"
          })
        );
        dispatch({
          type: GET_TRIP_FAILURE,
          payload: err
        });
      });
  };
};

export const fetchOtherTrip = (id, type) => {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_IMPORT_TRIP_REQUEST
    });
    const state = getState();
    try {
      const res = await fetchTripApi(id, type, state.auth.token);
      if (res.data.status === 200) {
        dispatch({
          type: GET_IMPORT_TRIP_SUCCESS,
          payload: res.data
        });
      }
      else {
        dispatch({
          type: GET_IMPORT_TRIP_FAILURE,
          payload: res.data
        });
      }
    }
    catch (err) {
      dispatch({
        type: GET_IMPORT_TRIP_FAILURE,
        payload: err
      });
    }
  };
};
