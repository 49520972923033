import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

export const GridContainer = styled(Grid)`
  min-height: calc(100vh - 80px);
  margin-top: 80px;
`;

export const MapAndPlanner = styled(Grid)`
  align-content: flex-start;
  max-height: calc(100vh - 80px);
  ${(props) => props.theme.breakpoints.down("sm")} {
    max-height: 100%;
    overflow: hidden;
  }
`;

export const MapGrid = styled(Grid)`
  height: 100%;
  z-index: 2;
`;

export const PlannerGrid = styled(Grid)`
  overflow-y: auto;
  ${(props) => props.theme.breakpoints.down("sm")} {
    height: 100%;
  }
  height: calc(100vh - ${(props) => props.height}px);
  z-index: 1;
  background: ${(props) => props.theme.appBar};
`;

export const GoogleSearchContainer = styled(Grid)`
  flex: 1;
  overflow-y: auto;
  max-height: calc(100vh - 80px);
  width: 100%;
  border-left: 0.5px solid rgba(255, 255, 255, 0.37);
  ${(props) => props.theme.breakpoints.down("sm")} {
    border-left: none;
    flex: unset;
    max-height: 100%;
    overflow: hidden;
  }
`;
