import React from "react";
import {
  required,
  number,
  minLength,
} from "../../../common/FormFields/validations";
import MenuItem from "@material-ui/core/MenuItem";
import { Field } from "redux-form";
import ReduxFormTextField from "../../../common/FormFields/TextField";

const minLength10 = minLength(10);

const PRIVACY_OPTIONS = [
  {
    label: "Private",
    value: "1",
  },
  {
    label: "Public",
    value: "2",
  },
];

const FormPure = () => {
  return (
    <div>
      <Field
        className="form-input"
        name="profile_type"
        label="Privacy"
        select
        component={ReduxFormTextField}
        validations={[required]}
      >
        {PRIVACY_OPTIONS.map((opt, index) => (
          <MenuItem key={index} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </Field>
      <Field
        className="form-input"
        name="email"
        label="Email"
        type="email"
        required
        disabled
        component={ReduxFormTextField}
        validations={[required]}
      />
      <Field
        className="form-input"
        name="mobile"
        label="Mobile"
        component={ReduxFormTextField}
        validations={[number, minLength10]}
      />
    </div>
  );
};

export default FormPure;
