import React from "react";
import { DatePicker } from "@material-ui/pickers";

const ReduxDatePicker = (props) => {
	// console.log(props);
	const {
		input: { value, onChange, onBlur },
		meta: { error, touched },
		multi,
		label,
		required,
		...rest
	} = props;
	return (
		<DatePicker
			DialogProps={{
				PaperProps: {
					className: "date-picker",
					style: {
						background: "#2E2E2E",
					},
				},
			}}
			required={required}
			color="secondary"
			autoOk
			helperText={error && touched ? error : ""}
			label={label}
			clearable
			value={value}
			format={"dd/MM/yyyy"}
			invalidLabel={"dd/mm/yyyy"}
			onChange={onChange}
			onOpen={onBlur}
			error={error && touched ? true : false}
			{...rest}
		/>
	);
};

export default ReduxDatePicker;
