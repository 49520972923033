import { reduxForm } from "redux-form";
import { compose } from "recompose";
import { sendResetLink } from "../services";
import { enqueueSnackbar } from "../../Master/Notifier/duck";

const sendResetEmail = (values, dispatch, props) => {
  return sendResetLink(values.email)
    .then((res) => {
      if (res.data.message === "Success") {
        dispatch(
          enqueueSnackbar({
            message: "Email sent successfully",
            variant: "success",
          })
        );
      } else if (res.data.status === 502) {
        dispatch(
          enqueueSnackbar({
            message: "Email id not found",
            variant: "error",
          })
        );
      } else {
        dispatch(
          enqueueSnackbar({
            message: "Some error occured",
            variant: "error",
          })
        );
      }
    })
    .catch((err) =>
      dispatch(
        enqueueSnackbar({
          message: "Some error occured",
          variant: "error",
        })
      )
    );
};

const GenerateHandlers = compose(
  reduxForm({
    form: "forgot-password-form",
    onSubmit: sendResetEmail,
  })
);

export default GenerateHandlers;
