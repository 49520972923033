import { connect } from 'react-redux';
import { compose } from 'recompose';

const mapStateToProps = state => {
  return { auth: state.auth };
};

const AuthRoute = compose(
  connect(
    mapStateToProps,
    null
  )
);

export default AuthRoute;
