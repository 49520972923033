import React from "react";
import { ReactComponent as OnboardingVector } from "../assets/onboarding/onboarding-bg.svg";
import Close from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

export const OnboardingWrapper = ({ children, handleClose }) => {
  return (
    <section className="onboarding-wrapper">
      <article className="bg-container">
        <OnboardingVector />
      </article>
      <article className="component-container">
        <IconButton className="close-btn" onClick={handleClose}>
          <Close color="primary" />
        </IconButton>
        {children}
      </article>
    </section>
  );
};
