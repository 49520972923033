import React, { useState, useEffect } from "react";
import { Field } from "redux-form";
import {
  required,
  minLength,
  confirmPassword,
} from "../../common/FormFields/validations";
import MenuItem from "@material-ui/core/MenuItem";
import ReduxFormTextField from "../../common/FormFields/TextField";
import ResponsiveDialog from "../../common/ResponsiveDialog";
import { OnboardingWrapper } from "../OnboardingWrapper";
import "../index.scss";
import { Typography, Button } from "@material-ui/core";
import LoadingButton from "../../common/LoadingButton";
import { getCurrencyApi } from "../../Profile/EditProfile/services";

const minLength8 = minLength(8);

const SignupPure = (props) => {
  const { submitting, handleSubmit, reset, isOpen, setIsOpen, setLoginOpen } =
    props;

  const [currency, setCurrency] = useState([]);

  useEffect(() => {
    getCurrencyApi()
      .then((res) => setCurrency(res.data.data))
      .catch((err) => console.log(err));
  }, []);
  return (
    <ResponsiveDialog
      showActions={false}
      showTitle={false}
      contentClass="onboarding-modal-content"
      dialogClass="onboarding-dialog"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      renderContent={() => {
        return (
          <OnboardingWrapper
            handleClose={() => {
              reset(SignupPure);
              setIsOpen(false);
            }}
          >
            <div className="onboarding-main-content">
              <Typography gutterBottom variant="h4" color="secondary">
                Welcome,Create your account
              </Typography>
              <form className="onboarding-form" onSubmit={handleSubmit}>
                <div className="form-item">
                  <Field
                    name="email"
                    type="email"
                    validate={required}
                    required
                    component={ReduxFormTextField}
                    label="Email"
                  />
                </div>
                <div className="form-item">
                  <Field
                    name="password"
                    required
                    validate={[required, minLength8]}
                    type={"password"}
                    component={ReduxFormTextField}
                    label="Password"
                  />
                </div>
                <div className="form-item">
                  <Field
                    name="confirmPassword"
                    required
                    validate={[required, minLength8, confirmPassword]}
                    type={"password"}
                    component={ReduxFormTextField}
                    label="Confirm Password"
                  />
                </div>
                <div className="form-item">
                  <Field
                    name="first_name"
                    required
                    validate={required}
                    component={ReduxFormTextField}
                    label="First Name"
                  />
                </div>
                <div className="form-item">
                  <Field
                    name="last_name"
                    required
                    validate={required}
                    component={ReduxFormTextField}
                    label="Last Name"
                  />
                </div>
                <div className="form-item">
                  <Field
                    className="form-input"
                    name="currency"
                    required
                    component={ReduxFormTextField}
                    label="Currency"
                    select
                  >
                    {currency.map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                    ))}
                  </Field>
                </div>
                {/*<div className="form-item">
									<Field
										name="access_code"
										required
										validate={required}
										component={ReduxFormTextField}
										label="Access Code"
									/>
					</div>*/}
                <Typography variant="caption">
                  *By siging up, you agree to our Terms, Data Policy and Cookies
                  Policy
                </Typography>
                <div>
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    type={"submit"}
                    submitting={submitting}
                  >
                    Sign up
                  </LoadingButton>
                </div>
              </form>
              <div className="onboarding-options">
                <Typography variant="caption">
                  Already have an account ?
                </Typography>
                <Button
                  onClick={() => {
                    reset(SignupPure);
                    setIsOpen(false);
                    setLoginOpen(true);
                  }}
                  color="secondary"
                  variant="text"
                >
                  Login
                </Button>
              </div>
            </div>
          </OnboardingWrapper>
        );
      }}
    />
  );
};

export default SignupPure;
