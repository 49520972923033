import { connect } from "react-redux";
import { compose, withState, withHandlers } from "recompose";
import { enqueueSnackbar } from "../../Master/Notifier/duck";

const mapStateToProps = state => {
  return {
    trip: state.trip,
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, variant) =>
      dispatch(
        enqueueSnackbar({
          message: message,
          variant: variant
        })
      )
  };
};

const TourPlanHandlers = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withState("isDialogOpen", "setDialogOpen", false),
  withState("selected", "setSelected", {}),
  withHandlers({
    handleClickUpload: props => (day, activity, activityIndex) => {
      const {
        setDialogOpen,
        setSelected,
        trip: {
          tripdata: { trip_id }
        }
      } = props;
      setDialogOpen(true);
      setSelected({ day, activity, trip_id, activityIndex });
    }
  })
);

export default TourPlanHandlers;
