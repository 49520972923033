import React from "react";
import { PlanContainer } from "./styles";
import PlanHeading from "./PlanHeading";
import PlanForm from "./PlanForm";
import { getDuration } from "../../common/utils";

const PlanPure = (props) => {
  const {
    handleSubmit,
    submitting,
    trip,
    trip: {
      tripdata: { lat, longi },
    },
    setDialogOpen,
    addActivity,
    handleRecomendationClick,
    values,
    action,
    duration,
    auth: {
      userData: { currency },
    },
    importChips,
    removeImportChip,
    showSnackBar,
  } = props;

  return (
    <PlanContainer>
      <form onSubmit={handleSubmit}>
        <PlanHeading
          action={action}
          trip={trip}
          formValues={values}
          currency={currency}
          submitting={submitting}
          setDialogOpen={setDialogOpen}
        />

        <PlanForm
          formValues={values}
          handleRecomendationClick={handleRecomendationClick}
          addActivity={addActivity}
          lat={+lat}
          lng={+longi}
          trip={trip}
          drivingTime={getDuration(values, duration)}
          importChips={importChips}
          removeImportChip={removeImportChip}
          showSnackBar={showSnackBar}
        />
      </form>
    </PlanContainer>
  );
};

export default PlanPure;
