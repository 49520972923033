import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { Draggable } from "react-beautiful-dnd";
import Tooltip from "@material-ui/core/Tooltip";
import ReduxFormTextField from "../../../common/FormFields/TextField";

import {
  ActivityPointer,
  ActivityContainer,
  ActivityPointerCotainer,
  ActivityFieldGrid,
  DeleteIconGrid,
  ActivityGrid,
  DrivingTime,
  DrivingTimeContainer,
  IconGrid,
  ActivityTimeContainer,
} from "./styles";
import {
  minValue,
  required,
  requiredAddress,
} from "../../../common/FormFields/validations";
import { Field } from "redux-form";
import TextField from "../../../common/FormFields/TextField";
import FileInputField from "../../../common/FormFields/FileUpload";
import GoogleAutocomplete from "../../../common/FormFields/googlePlaceAutocomplete";
import { ReactComponent as DeleteIcon } from "../../../assets/planner/delete.svg";
import { ReactComponent as RecommendationIcon } from "../../../assets/planner/recommendation.svg";
import { ReactComponent as AttachmentIcon } from "../../../assets/planner/attachment.svg";

import GreenRoadIcon from "../../../assets/green-car.svg";
import { ActivityColors, getActivitySvg } from "../../../common/utils";
import { ACTIVITY_TYPES, RECOMMENDATION_HELP_TEXT } from "../../../constants";
import { docUploadApi } from "../../duck/services";
import { Typography } from "@material-ui/core";

const getPlacename = (formValues, currentActivityIndex, currentDayIndex) => {
  let place = "";
  const plan = formValues.plan;

  for (let dayIndex = currentDayIndex; dayIndex >= 0; dayIndex--) {
    for (
      let activityIndex =
        dayIndex === currentDayIndex
          ? currentActivityIndex - 1
          : plan[dayIndex] && plan[dayIndex].activities
          ? plan[dayIndex].activities.length
          : 0;
      activityIndex >= 0 && !place;
      activityIndex--
    ) {
      if (
        plan[dayIndex].activities[activityIndex].activityname &&
        plan[dayIndex].activities[activityIndex].to &&
        plan[dayIndex].activities[activityIndex].to.city
      ) {
        place = plan[dayIndex].activities[activityIndex].to;
        break;
      }

      if (
        plan[dayIndex] &&
        plan[dayIndex].activities &&
        plan[dayIndex].activities[activityIndex] &&
        plan[dayIndex].activities[activityIndex].activityname
      ) {
        place = plan[dayIndex].activities[activityIndex].activityname;
        break;
      }
    }
    if (!place && plan[dayIndex] && plan[dayIndex].startingplace) {
      place = plan[dayIndex].startingplace;
      break;
    }
  }
  return place ? place : { address: "" };
};

const getActivityType = (plan, currentActivityIndex, currentDayIndex) => {
  if (
    plan &&
    plan[currentDayIndex] &&
    plan[currentDayIndex].activities &&
    plan[currentDayIndex].activities[currentActivityIndex]
  ) {
    return plan[currentDayIndex].activities[currentActivityIndex].activitytype;
  }
};

const renderActivityIcon = (plan, actIndex, dayIndex) => {
  const type = getActivityType(plan, actIndex, dayIndex);
  return getActivitySvg(type);
};

const renderActivityType = (plan, actIndex, dayIndex, lat, lng, activity) => {
  const type = getActivityType(plan, actIndex, dayIndex);

  if (type === "flight" || type === "rail" || type === "road") {
    return (
      <ActivityFieldGrid direction="column" container item md={7} xs={12}>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Field
            lat={lat}
            lng={lng}
            name={`${activity}.activityname`}
            label="From"
            placeholder="From"
            component={GoogleAutocomplete}
            validate={requiredAddress}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            lat={lat}
            lng={lng}
            name={`${activity}.to`}
            label="To"
            placeholder="To"
            component={GoogleAutocomplete}
            validate={requiredAddress}
          />
        </Grid>
      </ActivityFieldGrid>
    );
  }

  return (
    <ActivityFieldGrid item md={7} xs={12}>
      <Field
        lat={lat}
        lng={lng}
        name={`${activity}.activityname`}
        label="Activity Name"
        placeholder="Activity Name"
        component={GoogleAutocomplete}
        validate={requiredAddress}
      />
    </ActivityFieldGrid>
  );
};

const ActivityFieldArray = (props) => {
  const {
    fields,
    dayIndex,
    lat,
    lng,
    handleRecomendationClick,

    drivingTime,
    formValues,
    token,

    auth: {
      userData: { currency },
    },
    showSnackBar,
  } = props;

  return fields.map((activity, index) => (
    <React.Fragment>
      <Draggable
        key={index}
        draggableId={`activity-${dayIndex}-${index}`}
        index={index}
      >
        {(provided, snapshot) => (
          <ActivityContainer
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <div className="activity-content" key={index}>
              <ActivityPointerCotainer {...provided.dragHandleProps}>
                {index === 0 && (
                  <div style={{ position: "relative" }}>
                    {drivingTime[`day-${dayIndex}`][`day-${dayIndex}`] && (
                      <DrivingTimeContainer>
                        <img src={GreenRoadIcon} alt="driving" />
                        <DrivingTime color="primary">
                          {drivingTime[`day-${dayIndex}`][`day-${dayIndex}`]}
                        </DrivingTime>
                      </DrivingTimeContainer>
                    )}
                  </div>
                )}

                <ActivityPointer
                  color={ActivityColors[dayIndex % ActivityColors.length]}
                >
                  <div>
                    <Typography variant="subtitle1">
                      {`${dayIndex + 1}`}
                      {(index + 10).toString(36)}
                    </Typography>
                  </div>
                  <div className="icon-div">
                    {renderActivityIcon(
                      (formValues || {}).plan,
                      index,
                      dayIndex
                    )}
                  </div>
                </ActivityPointer>
              </ActivityPointerCotainer>
              <ActivityGrid container spacing={2}>
                <Grid container item sm md xs={12} spacing={4}>
                  <ActivityFieldGrid item md={5} xs={12}>
                    <Field
                      name={`${activity}.activitytype`}
                      label="Type"
                      select
                      placeholder="Activity Type"
                      component={TextField}
                      validate={required}
                      style={{ marginBottom: "10px" }}
                    >
                      {ACTIVITY_TYPES.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                    <Field
                      name={`${activity}.activitycost`}
                      label="Cost"
                      type="number"
                      className="form-input"
                      id="standard-adornment-amount"
                      currency={currency}
                      component={ReduxFormTextField}
                      validate={minValue}
                    ></Field>
                    <Field
                      name={`${activity}.note`}
                      label="Note"
                      type="text"
                      className="form-input"
                      component={ReduxFormTextField}
                    ></Field>
                  </ActivityFieldGrid>

                  {renderActivityType(
                    (formValues || {}).plan,
                    index,
                    dayIndex,
                    lat,
                    lng,
                    activity
                  )}
                </Grid>

                <IconGrid item container>
                  <Grid item className="recommendation">
                    <Tooltip
                      placement="top"
                      classes={{
                        tooltip: props.classes.customWidth,
                      }}
                      title={RECOMMENDATION_HELP_TEXT}
                    >
                      <RecommendationIcon
                        onClick={() => {
                          handleRecomendationClick(
                            getPlacename(formValues, index, dayIndex),
                            formValues.plan[dayIndex].activities[index]
                              .activitytype
                          );
                        }}
                        ndationIcon
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item className="attachment">
                    {!(
                      formValues.plan[dayIndex].activities[index] &&
                      formValues.plan[dayIndex].activities[index].docname
                    ) && <AttachmentIcon />}
                    <Field
                      name={`${activity}.docname`}
                      component={FileInputField}
                      style={{ width: "16px" }}
                      clearable
                      withUpload
                      onUpload={({ file, data }, callback) => {
                        const formData = new FormData();
                        formData.append("upl", file);
                        formData.append("trip_activity_id", "1568");
                        docUploadApi(token, formData)
                          .then((res) => {
                            callback(res.data.docname);
                          })
                          .catch((err) => {
                            showSnackBar("Error while uploading doc", "error");
                            callback(null);
                          });
                      }}
                    />
                  </Grid>
                  <DeleteIconGrid item>
                    <IconButton
                      className="activity-delete"
                      onClick={() => fields.remove(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </DeleteIconGrid>
                </IconGrid>
              </ActivityGrid>
            </div>
            <div style={{ position: "relative" }}>
              {drivingTime[`day-${dayIndex}`][`activity-${index}`] && (
                <ActivityTimeContainer>
                  <img src={GreenRoadIcon} alt="driving" />
                  <DrivingTime color="primary">
                    {drivingTime[`day-${dayIndex}`][`activity-${index}`]}
                  </DrivingTime>
                </ActivityTimeContainer>
              )}
            </div>
          </ActivityContainer>
        )}
      </Draggable>
    </React.Fragment>
  ));
};

export default ActivityFieldArray;
