import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Field } from "redux-form";
import GoogleAutocomplete from "../../common/FormFields/googlePlaceAutocomplete";
import { GridContainer, StyledLoadingButton, StyledDialog } from "./styles";
import ReduxFormTextField from "../../common/FormFields/TextField";
import { required, validateDate, validateGoogleOrigin } from "../../common/FormFields/validations";
import ReduxDatePicker from "../../common/FormFields/DatePicker";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Close from "@material-ui/icons/Close";
import { PRIVACY_OPTIONS } from "../../constants";

const NewTourDialogPure = (props) => {
  const {
    submitting,
    handleSubmit,
    fullScreen,
    heading,
    subheading,
    buttonText,
    handleClose,
    open,
    lat,
    lng,
  } = props;
  return (
    <StyledDialog fullScreen={fullScreen} open={open}>
      <DialogTitle className="dialog-title" disableTypography={true}>
        <Grid
          container
          wrap="nowrap"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography className="dialog-heading" color="secondary">
              {heading}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color="primary" onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <form className="dialog-form" onSubmit={handleSubmit}>
        <Typography className="subheading" variant="caption">
          {subheading}
        </Typography>
        <GridContainer direction="row" spacing={4} container>
          <Grid item md={6} xs={12}>
            <Field
              name="name"
              component={ReduxFormTextField}
              validate={required}
              label="Trip Name"
              placeholder="Enter your trip name"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              required
              name="origin"
              label="Origin"
              validate={validateGoogleOrigin}
              placeholder="Origin"
              position="fixed"
              component={GoogleAutocomplete}
              lat={lat}
              lng={lng}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name="from_dt"
              validate={[validateDate, required]}
              fullWidth
              component={ReduxDatePicker}
              required
              label="Start Date"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name="to_dt"
              validate={[validateDate, required]}
              fullWidth
              required
              component={ReduxDatePicker}
              label="End Date"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name="type"
              label={"Privacy"}
              placeholder={"Privacy"}
              select
              component={ReduxFormTextField}
              validate={required}
              required
            >
              {PRIVACY_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Field>
          </Grid>

          <Grid item xs={12}>
            <StyledLoadingButton
              variant="contained"
              color="secondary"
              type={"submit"}
              submitting={submitting}
            >
              {buttonText}
            </StyledLoadingButton>
          </Grid>
        </GridContainer>
      </form>
    </StyledDialog>
  );
};

export default withMobileDialog()(NewTourDialogPure);
