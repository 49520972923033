import { axiosInst, axiosConfig } from "../../../../axios";

export const unreadCountApi = token => {
  return axiosInst.post("/unreadcount", {}, axiosConfig(token));
};

export const readNotificationApi = (token, id) => {
  return axiosInst.post(
    "/readnotification",
    {
      noti_id: String(id)
    },
    axiosConfig(token)
  );
};

export const getNotificationsApi = token => {
  return axiosInst.post("/notification", {}, axiosConfig(token));
};

export const accpetRejectFollowRequestApi = (token, reqId, notId, state) => {
  return axiosInst.post(
    "/reqprocess",
    {
      req_id: reqId,
      noti_id: notId,
      accept: state ? 2 : 3
    },
    axiosConfig(token)
  );
};
