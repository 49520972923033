import { GET_NEARBY_PLACES, RESET_NEARBY_PLACE } from "./types";

const INITIAL_STATE = {
  place: undefined,
  type: undefined
};

export const nearbyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_NEARBY_PLACES: {
      return {
        ...state,
        ...action.payload
      };
    }

    case RESET_NEARBY_PLACE: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};
