import styled from "styled-components";
import Typography from "@material-ui/core/Typography";

export const ProfileGrid = styled.div`
  && {
    margin: 40px 0px;
    padding: 0 30px;

    ${(props) => props.theme.breakpoints.down("sm")} {
      padding: 0 15px;
    }
  }
  .photo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
`;

export const StyledHeadings = styled(Typography)`
  && {
    margin-bottom: 10px;
    svg {
      min-height: 22px;
      min-width: 22px;
      margin-right: 8%;
    }
    display: flex;
    justify-content: initial;
    align-items: center;
  }
`;

export const StyledName = styled(Typography)`
  && {
    font-family: "Work Sans", sans-serif;
    color: ${(props) =>
      props.userId === 1 ? props.theme.neonRed : props.theme.lightGreen};
    letter-spacing: 0.015em;
    font-weight: 600;
  }
`;

export const ProfileMobileContainer = styled.div`
  display: flex;
  padding: 10px 2% 0 2%;
  background: ${(props) => props.theme.primaryBlack};
  position: sticky;
  top: 80px;
  z-index: 999;
  .photo-container {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: 20px;

    img {
      width: 38px;
      height: 38px;
      border-radius: 100%;
      margin-right: 10px;
    }
  }

  .user-info-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    p {
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      margin: 10px;
    }
  }
`;
