import {
  NOTIFICATION_COUNT,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE
} from "./types";

const INITIAL_STATE = {
  notifications: [],
  loading: undefined,
  error: undefined,
  unreadCount: 0
};

export const notificationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NOTIFICATION_COUNT: {
      return {
        ...state,
        unreadCount: action.payload
      };
    }

    case GET_NOTIFICATIONS_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        notifications: action.payload
      };
    }

    case GET_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true
      };
    }

    default:
      return state;
  }
};
