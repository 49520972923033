import React from "react";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import Close from "@material-ui/icons/Close";
import { reduxForm, Field } from "redux-form";

import { StyledDialog } from "./styles";
import LoadingButton from "../../common/LoadingButton";
import RadioGroup from "../../common/FormFields/RadioGroup";
import { PRIVACY_OPTIONS } from "../../constants";
import { updatePhotoPrivacy } from "./duck";

const handleSubmit = (values, dispatch, props) => {
  const { handleClose, photo, updatePrivacy } = props;
  return new Promise((res) => {
    return updatePrivacy(photo.photo_id, values.privacy, (err, data) => {
      if (!err) {
        handleClose();
      }
      return res();
    });
  });
};

const SettingsDialog = (props) => {
  const {
    isOpen: open,
    handleClose,
    fullScreen,
    photo,
    submitting,
    handleSubmit,
  } = props;

  return (
    <StyledDialog
      fullWidth={true}
      maxWidth="xs"
      fullScreen={fullScreen}
      open={open}
    >
      <DialogTitle disableTypography={true} className="title">
        <Grid
          container
          wrap="nowrap"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography className="heading" color="secondary">
              {(photo || {}).origin} Photo Settings
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              position: "absolute",
              top: "0",
              right: "0",
            }}
          >
            <IconButton color="primary" onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="dialog-body">
        <form onSubmit={(values) => handleSubmit(values)}>
          <Grid direction="row" spacing={32} container>
            <Grid item xs={12}>
              <Field
                name="privacy"
                component={RadioGroup}
                label="Privacy Settings :"
                options={PRIVACY_OPTIONS}
                classNames={{
                  label: "privacy-label",
                  option: "privacy-option",
                  checked: "privacy-option-checked",
                }}
              />
            </Grid>
            <Grid style={{ marginTop: "20px" }} item xs={12}>
              <LoadingButton
                variant="contained"
                color="secondary"
                type={"submit"}
                submitting={submitting}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </div>
    </StyledDialog>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePrivacy: (photoId, type, callback) =>
      dispatch(updatePhotoPrivacy(photoId, type, callback)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: "photo-privacy-dialog",
    destroyOnUnmount: true,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  })(withMobileDialog()(SettingsDialog))
);
