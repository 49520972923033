import React from "react";
import { Field } from "redux-form";
import FileInputField from "../../../common/FormFields/FileUpload";
import { StyledPhotoDiv } from "./styles";

const photoFieldPure = (props) => {
  const { name, validations, width, isEditMode, children, ...rest } = props;

  return (
    <StyledPhotoDiv>
      {children}
      <Field name={name} component={FileInputField} {...rest} />
    </StyledPhotoDiv>
  );
};

export default photoFieldPure;
