import React from "react";
import {
  compose,
  withProps,
  withPropsOnChange,
  withState,
  withHandlers,
} from "recompose";
import { withGoogleMap } from "react-google-maps";
import { getDirections, isEqual } from "../utils";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  const { nearby } = state;
  return {
    nearby,
  };
};

const GoogleMapHandlers = compose(
  connect(mapStateToProps, null),
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withState("directions", "setDirections", null),
  withState("flightDirections", "setFlightDirections", null),
  withState("map", "setRef", null),
  withState("zoom", "onZoomChange", 8),
  withHandlers(() => {
    return {
      onMapMounted: ({ setRef }) => (ref) => {
        setRef(ref);
      },
      onZoomChanged: ({ onZoomChange, map }) => () => {
        onZoomChange(map.getZoom());
      },
    };
  }),
  withGoogleMap,
  withPropsOnChange(
    (props, nextProps) => {
      return !isEqual(props.markers, nextProps.markers);
    },
    (props) => {
      const {
        markers,
        setDirections,
        setDuration,
        setFlightDirections,
      } = props;
      if (markers.ungrouped && markers.ungrouped.length > 1) {
        getDirections(markers.ungrouped).then((res) => {
          const duration = res.routes[0].legs;
          if (setDuration) {
            setDuration(duration);
          }
        });
        if (markers.grouped) {
          const directions = [];
          const flightDirections = [];
          const directionPromises = [];
          // const flightPromises = [];
          Object.keys(markers.grouped).forEach((markerKey) => {
            if (
              markers.grouped[markerKey] &&
              markers.grouped[markerKey][0] &&
              markers.grouped[markerKey][0].type === "flight"
            ) {
              flightDirections.push(markers.grouped[markerKey]);
            } else {
              directionPromises.push(getDirections(markers.grouped[markerKey]));
            }
          });
          Promise.all(directionPromises).then((res) => {
            res.forEach((r) => directions.push(r));
            setDirections(directions);
          });
          setFlightDirections(flightDirections);
        }
      } else {
        setDirections(null);
      }
    }
  )
);

export default GoogleMapHandlers;

/* <MyMapComponent isMarkerShown /> */
