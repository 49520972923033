import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

const RoutesHandlers = compose(
  withRouter,
  connect(
    mapStateToProps,
    null
  )
);

export default RoutesHandlers;
