import React from "react";
import { Route, Redirect } from "react-router-dom";

const LoginRoutePure = ({ component, render, auth, ...rest }) => {
  const { isLoggedIn } = auth;
  if (isLoggedIn) {
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{
              pathname: "/profile",
              state: { from: props.location }
            }}
          />
        )}
      />
    );
  } else {
    return <Route {...rest} component={component} render={render} />;
  }
};

export default LoginRoutePure;
