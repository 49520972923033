import React from "react";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { getPhotos, likePhoto } from "./duck";
import LoadingScreen from "../../common/LoadingScreen";
import NoContent from "../../common/NoContent";
import Lightbox from "../../common/Lightbox";
import { ReactComponent as LikeIcon } from "../../assets/profile/like.svg";
import PhotoCard from "../../common/PhotoCard/PhotoCard";
import { Link } from "react-router-dom";
import {
  COMMUNITY_ID,
  GENERIC_ERROR_MESSAGE,
  NO_CONTENT_PHOTOS,
} from "../../constants";
import NotFound from "../../common/NotFound";
import { uid } from "react-uid";
import ImportTripDialog from "../../common/ImportTripDialog/ImportTripDialog";
import { ReactComponent as ImportIcon } from "../../assets/profile/import.svg";

const Community = (props) => {
  const { getPhotos, error, photos, loading, pageno, isLast, search, like } =
    props;

  const [isFetching, setIsFetching] = React.useState(false);

  const [isOpen, setIsOpen] = React.useState(false);
  const [photoIndex, setIndex] = React.useState(0);
  const [isImportDialogOpen, setImportDialog] = React.useState(false);
  const [selectedTrip, setTrip] = React.useState();

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 390
    ) {
      setIsFetching(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, false);
    getPhotos(0);
    return () => window.removeEventListener("scroll", handleScroll, false);
  }, [getPhotos]);

  React.useEffect(() => {
    console.log(isFetching, "+++++");

    if (!isFetching || !photos || isLast || search) return;
    getPhotos(pageno + 1, () => setIsFetching(false));
  }, [isFetching, photos, isLast, search, getPhotos, pageno]);

  if (error) {
    return <NotFound errorMsg={GENERIC_ERROR_MESSAGE} />;
  }

  if (loading && !photos) {
    return <LoadingScreen />;
  }

  if (photos.length === 0) {
    return <NoContent heading={NO_CONTENT_PHOTOS} alignText="center" />;
  }

  const onPhotoClick = (index) => {
    setIndex(index);
    setIsOpen(true);
  };

  const likePhoto = (id, status) => {
    like(id, !status);
  };

  return (
    <>
      <Grid container wrap="wrap" spacing={4}>
        {photos.map((photo, index) => (
          <Grid key={uid(photo, index)} item md={4} xs={6}>
            <PhotoCard
              key={uid(photo, index)}
              photo={photo}
              location={photo.location}
              onPhotoClick={() => onPhotoClick(index)}
              renderHeader={(photo) => (
                <div className="user-heading">
                  <Avatar
                    alt={photo.username}
                    src={photo.profilepic}
                    component={Link}
                    to={`/user/${photo.user_id}`}
                  />
                  <Typography
                    component={Link}
                    to={`/user/${photo.user_id}`}
                    className="username"
                  >
                    @{photo.username}
                  </Typography>
                </div>
              )}
              renderActions={() => (
                <>
                  <LikeIcon
                    className={photo.is_like ? "like-btn liked" : "like-btn"}
                    onClick={() => likePhoto(photo.photo_id, photo.is_like)}
                  />
                  <ImportIcon
                    onClick={() => {
                      const importPhoto = { ...photo };
                      importPhoto.location_to = photo.place_to;
                      importPhoto.city_to = photo.city_to;
                      importPhoto.lat_to = photo.place_to_lat;
                      importPhoto.longi_to = photo.place_to_longi;
                      setTrip(importPhoto);
                      setImportDialog(true);
                    }}
                  />
                </>
              )}
            />
          </Grid>
        ))}
      </Grid>
      <Lightbox
        setIndex={setIndex}
        photoIndex={photoIndex}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        photos={(photos || []).map((photo) => photo.trippic)}
      />
      <ImportTripDialog
        isOpen={isImportDialogOpen}
        handleClose={() => setImportDialog(false)}
        tripToImport={selectedTrip}
        isPhoto={true}
        triptype={"communityTrips"}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    photos: {
      communityError,
      communityLoading,
      community,
      communityIsLast,
      communityPageNo,
      communitySearch,
    },
  } = state;
  return {
    error: communityError,
    loading: communityLoading,
    photos: community,
    isLast: communityIsLast,
    pageno: communityPageNo,
    search: communitySearch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPhotos: (page, callback) =>
      dispatch(getPhotos(COMMUNITY_ID, page, undefined, callback)),
    like: (id, status) => dispatch(likePhoto(COMMUNITY_ID, id, status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Community);
