import React, { memo } from "react";
import Resizable from "re-resizable";
import { MapGrid } from "./styles";
import GoogleMap from "../common/GoogleMap";
import { isEqual } from "../common/utils";

export const DesktopMapComponent = props => {
  const { lat, longi, markers, setDuration, resizeCallback } = props;

  return (
    <Resizable
      enable={{
        top: false,
        right: false,
        bottom: true,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false
      }}
      style={{
        zIndex: 2,
        position: "sticky",
        top: 0
      }}
      defaultSize={{ width: "100%", height: "300px" }}
      onResize={resizeCallback}
    >
      <MapGrid item xs={12}>
        <GoogleMap
          setDuration={setDuration}
          lat={+lat}
          lng={+longi}
          markers={markers}
        />
      </MapGrid>
    </Resizable>
  );
};

const arePropsEqual = (prevProps, nextProps) => {
  const { lat, longi, markers } = prevProps;
  const { lat: prevLat, longi: prevLongi, markers: prevMarkers } = nextProps;
  return (
    lat === prevLat && longi === prevLongi && isEqual(markers, prevMarkers)
  );
};

export const DesktopMap = memo(DesktopMapComponent, arePropsEqual);
