import Pure from "./pure";
import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    trip: state.trip
  };
};

export default connect(mapStateToProps, null)(Pure);
