import React from "react";
import {
  FormLabel,
  FormHelperText,
  withStyles,
  withTheme,
  FormControlLabel,
  Radio,
  Typography,
} from "@material-ui/core";
import { StyledRadioGroup, multiStyles } from "./style";

const RadioGroup = ({
  input: { name, onChange, value, ...rest },
  label,
  row,
  required,
  meta: { touched, error },
  helperText,
  options,
  classNames,
}) => (
  <div>
    <div>
      <FormLabel
        error={error && touched}
        required={required}
        variant="subtitle2"
        component={Typography}
        gutterBottom
        color="primary"
        className={(classNames || {}).label}
      >
        {label}
      </FormLabel>
      <StyledRadioGroup
        color="primary"
        row={row}
        name={name}
        value={value}
        onChange={onChange}
        {...rest}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            color="primary"
            control={
              <Radio
                color="primary"
                classes={{
                  checked: (classNames || {}).checked,
                  colorPrimary: "primary-color",
                }}
              />
            }
            label={option.label}
            className={(classNames || {}).option}
          />
        ))}
      </StyledRadioGroup>
      {error && touched && (
        <FormHelperText error={error && touched ? true : false}>
          {error && touched ? error : helperText}
        </FormHelperText>
      )}
    </div>
  </div>
);

export default withTheme(withStyles(multiStyles)(RadioGroup));
