import React from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { TripCardCotainer } from "./styles";
import NoContent from "../../common/NoContent";
import { getTrips } from "../duck";
import LoadingScreen from "../../common/LoadingScreen";
import FriendTripCard from "./TripCard/FriendTripCard";
import { COMMUNITY_ID, GENERIC_ERROR_MESSAGE } from "../../constants";
import NotFound from "../../common/NotFound";
import { uid } from "react-uid";
import ImportTripDialog from "../../common/ImportTripDialog/ImportTripDialog";

const Community = (props) => {
  const {
    error,
    loading,
    trips,
    gettrips,
    pageno,
    isLast,
    search,
    auth: {
      userData: { currency },
    },
  } = props;
  const [isFetching, setIsFetching] = React.useState(false);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 390
    ) {
      setIsFetching(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, false);
    gettrips(0);
    return () => window.removeEventListener("scroll", handleScroll, false);
  }, [gettrips]);

  React.useEffect(() => {
    console.log(isFetching, "+++++");
    if (!isFetching || !trips || isLast || search) return;
    gettrips(pageno + 1, () => setIsFetching(false));
  }, [isFetching, trips, isLast, search, gettrips, pageno]);

  React.useEffect(() => {
    gettrips(COMMUNITY_ID);
  }, [gettrips]);

  const [isImportDialogOpen, setImportDialog] = React.useState(false);
  const [selectedTrip, setTrip] = React.useState();

  if (error) {
    return <NotFound errorMsg={GENERIC_ERROR_MESSAGE} />;
  }

  if (loading && !trips) {
    return <LoadingScreen />;
  }

  if (trips.length === 0) {
    return (
      <NoContent heading="Havent Started Planning yet?" alignText="center" />
    );
  }

  return (
    <>
      <Grid container wrap="wrap" spacing={4}>
        {trips.map((trip, index) => (
          <TripCardCotainer key={uid(trip, index)} item md={4} xs={12}>
            <FriendTripCard
              trip={trip}
              currency={currency}
              handleImportPlan={() => {
                setTrip(trip);
                setImportDialog(true);
              }}
              type="community"
            />
          </TripCardCotainer>
        ))}
      </Grid>
      <ImportTripDialog
        isOpen={isImportDialogOpen}
        handleClose={() => setImportDialog(false)}
        tripToImport={selectedTrip}
        type="community"
        triptype={"communityTrips"}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    trips: {
      communityError,
      communityLoading,
      communityTrips,
      communityPageNo,
      communityIsLast,
      communitySearch,
    },
  } = state;

  return {
    auth: state.auth,
    trips: communityTrips,
    error: communityError,
    loading: communityLoading,
    isLast: communityIsLast,
    pageno: communityPageNo,
    search: communitySearch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    gettrips: (page, callback) =>
      dispatch(getTrips(COMMUNITY_ID, page, undefined, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Community);
