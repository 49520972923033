import React from "react";
import Grid from "@material-ui/core/Grid";
import ResponsiveDialog from "../../common/ResponsiveDialog";

import { Field } from "redux-form";
import { Typography } from "@material-ui/core";

import ReduxFormTextField from "../../common/FormFields/TextField";
import LoadingButton from "../../common/LoadingButton";
import { StyledGrid } from "../../common/Styles/GeneralStyles";
import {
	required,
	confirmPassword,
	minLength,
} from "../../common/FormFields/validations";

import { OnboardingWrapper } from "../OnboardingWrapper";

const minLength8 = minLength(8);

const LoginPure = (props) => {
	const { submitting, handleSubmit, reset, setIsOpen, isOpen, history } = props;
	return (
		<ResponsiveDialog
			showActions={false}
			showTitle={false}
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			dialogClass="onboarding-dialog"
			contentClass="onboarding-modal-content"
			renderContent={() => (
				<OnboardingWrapper
					handleClose={() => {
						reset(LoginPure);
						setIsOpen(false);
						history.push("/");
					}}
				>
					<div className="onboarding-main-content">
						<Typography gutterBottom variant="h4" color="secondary">
							Create new password
						</Typography>
						<form onSubmit={handleSubmit} className="onboarding-form">
							<Grid container>
								<StyledGrid margin="0px 0px 15px 0px" item xs={10}>
									<Field
										name="password"
										type="password"
										required
										component={ReduxFormTextField}
										label="New Password"
										validate={[required, minLength8]}
									/>
								</StyledGrid>
								<StyledGrid margin="0px 0px 15px 0px" item xs={10}>
									<Field
										name="confirmPassword"
										type="password"
										required
										component={ReduxFormTextField}
										label="Confirm Password"
										validate={[required, confirmPassword]}
									/>
								</StyledGrid>
								<StyledGrid
									margin="0px 0px 5px 0px"
									align="center"
									item
									xs={12}
								>
									<LoadingButton
										variant="contained"
										color="secondary"
										type={"submit"}
										submitting={submitting}
									>
										Reset Password
									</LoadingButton>
								</StyledGrid>
							</Grid>
						</form>
					</div>
				</OnboardingWrapper>
			)}
		/>
	);
};

export default LoginPure;
