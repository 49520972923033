import { deletePhotoApi } from "./services";
import { enqueueSnackbar } from "../../Master/Notifier/duck";
import { GET_NEARBY_PLACES, RESET_NEARBY_PLACE } from "./types";

export const deletePhoto = (photoId, callback) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const res = await deletePhotoApi(photoId, state.auth.token);
      if (res.data.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: "Photo deleted",
            variant: "success"
          })
        );
        if (callback) {
          callback();
        }
      } else {
        dispatch(
          enqueueSnackbar({
            message: "Some error occured",
            variant: "error"
          })
        );
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: "Some error occured",
          variant: "error"
        })
      );
    }
  };
};

export const getNearbyPlaces = (place, type) => {
  return dispatch => {
    dispatch({
      type: GET_NEARBY_PLACES,
      payload: {
        place,
        type
      }
    });
  };
};

export const resetPlace = () => {
  return dispatch => {
    dispatch({
      type: RESET_NEARBY_PLACE
    });
  };
};
