import React from "react";
import { MapAndPlanner, PlannerGrid } from "./styles";
import Plan from "./Plan";

export const MapAndPlannerComponent = (props) => {
  const {
    handleRecomendationClick,
    initialValues,
    setDialogOpen,
    history,
    action,
    renderMap,
    duration,
    currHeight,
    importChips,
    removeImportChip,
  } = props;

  return (
    <MapAndPlanner container item>
      {renderMap(props)}
      <PlannerGrid height={84 + currHeight} item xs={12}>
        <Plan
          handleRecomendationClick={handleRecomendationClick}
          initialValues={initialValues}
          setDialogOpen={setDialogOpen}
          history={history}
          action={action}
          duration={duration}
          importChips={importChips}
          removeImportChip={removeImportChip}
        />
      </PlannerGrid>
    </MapAndPlanner>
  );
};
