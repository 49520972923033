import { reduxForm } from "redux-form";
import { compose } from "recompose";
import { setNewPassword } from "../services";
import { parse } from "query-string";
import { enqueueSnackbar } from "../../Master/Notifier/duck";

const resetSubmit = (values, dispatch, props) => {
	// console.log(props);
	const {
		location: { search },
		history,
	} = props;
	const parsed = parse(search);

	return setNewPassword(values.password, parsed.token)
		.then((res) => {
			if (res.data.status === 200) {
				dispatch(
					enqueueSnackbar({
						message: "Password changed",
						variant: "success",
					})
				);
				history.push("/login");
			} else if (res.data.status === 502) {
				dispatch(
					enqueueSnackbar({
						message: "Token has expired",
						variant: "error",
					})
				);
			} else {
				// console.log(res);
				dispatch(
					enqueueSnackbar({
						message: "Something went wrong",
						variant: "error",
					})
				);
			}
		})
		.catch((err) =>
			dispatch(
				enqueueSnackbar({
					message: "Some error occured",
					variant: "error",
				})
			)
		);
};

const LoginHandlers = compose(
	reduxForm({
		form: "reset-form",
		onSubmit: resetSubmit,
	})
);

export default LoginHandlers;
