import styled from "styled-components";

export const UserCard = styled.div`
  display: flex;
  background: ${(props) => props.theme.primaryBlack} !important;
  border-radius: 15px;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .name {
    color: ${(props) => props.theme.lightGreen};
    text-decoration: none;
    font-size: 1.25rem;
    font-weight: 600;
    margin: 15px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    flex-shrink: 0;
    box-sizing: border-box;
    flex-basis: 100%;
  }

  .user-img {
    min-height: 120px;
    min-width: 120px;
    max-width: 120px;
    max-height: 120px;
    border-radius: 100%;
    object-fit: cover;
  }
  .following-btn {
    color: ${(props) => props.theme.secondaryColor};
    border-color: ${(props) => props.theme.secondaryColor};
    text-transform: uppercase;
    font-weight: 600;
  }

  .follow-btn {
    color: ${(props) => props.theme.lightGreen} !important;
    border-color: ${(props) => props.theme.lightGreen} !important;
    text-transform: uppercase;
    font-weight: 600;
  }
`;

export const CardContainer = styled.div``;
