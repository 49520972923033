import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, withState } from "recompose";

const mapStateToProps = state => {
  return { auth: state.auth };
};

const mapDispatchToProps = dispatch => {
  return {
    // logout: props => dispatch(logout(props))
  };
};

const MasterHandlers = compose(
  withRouter,
  withState("isDrawerOpen", "setDrawerOpen", false),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default MasterHandlers;
