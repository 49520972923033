import React from "react";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { CardContainer, UserCard } from "./styles";
import { getName, getFollowStatus } from "../../common/utils";
import LoadingButton from "../../common/LoadingButton";
import { FOLLOWING, REQUESTED } from "../../constants";
import { follow, unfollow } from "./duck/actions";
import NoPic from "../../assets/no-profile-pic.png";
import { USER_URL } from "../../urls";

const getBtnClass = (status) => {
  if (status === FOLLOWING) {
    return "following-btn";
  }
  return "follow-btn";
};

const PeopleCard = ({ person, follow, unfollow }) => {
  const status = getFollowStatus(person.isfollowing, person.reqpending);

  const [submitting, setSubmitting] = React.useState(false);

  const handleClick = () => {
    setSubmitting(true);
    if (status === FOLLOWING || status === REQUESTED) {
      return unfollow(person.user_id, () => setSubmitting(false));
    }

    return follow(person.user_id, () => setSubmitting(false));
  };

  const name = getName(person);

  return (
    <CardContainer key={person.user_id}>
      <UserCard>
        <Link to={`${USER_URL}/${person.user_id}`}>
          <img className="user-img" src={person.prof_pic || NoPic} alt={name} />
        </Link>
        <Typography
          component={Link}
          to={`${USER_URL}/${person.user_id}`}
          className="name"
          gutterBottom
        >
          {name}
        </Typography>
        <LoadingButton
          submitting={submitting}
          onClick={handleClick}
          className={getBtnClass(status)}
          disabled={status === REQUESTED}
          variant="outlined"
        >
          {status}
        </LoadingButton>
      </UserCard>
    </CardContainer>
  );
};

const mapStateToProps = (state) => {
  const {
    people: {
      followersError,
      followersLoading,
      followers,
      followersPageNo,
      followersIsLast,
    },
  } = state;

  return {
    error: followersError,
    loading: followersLoading,
    people: followers,
    isLast: followersIsLast,
    pageno: followersPageNo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    follow: (userId, callback) => dispatch(follow(userId, callback)),
    unfollow: (userId, callback) => dispatch(unfollow(userId, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PeopleCard);
