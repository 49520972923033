import { compose, withState } from "recompose";
import { connect } from "react-redux";
import { deleteTrip } from "../../duck";

const mapStateToProps = (state) => {
  return {
    deleteTripStatus: state.deleteTripStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteTrip: (tripId, checkins) => dispatch(deleteTrip(tripId, checkins)),
  };
};

const TripCardHandlers = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState("anchorEl", "setAnchorEl", null),
  withState("open", "isOpen", false)
);

export default TripCardHandlers;
