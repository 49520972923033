import {
  GET_FOLLOWERS_REQUEST,
  GET_FOLLOWERS_SUCCESS,
  GET_FOLLOWERS_FAILURE,
  GET_FOLLOWING_REQUEST,
  GET_FOLLOWING_SUCCESS,
  GET_COMMUNITY_REQUEST,
  GET_COMMUNITY_SUCCESS,
  GET_COMMUNITY_FAILURE,
  GET_FOLLOWING_FAILURE,
  UPDATE_FOLLOWING,
  UPDATE_COMMUNITY,
  UPDATE_FOLLOWERS,
  REQUEST_COMMUNITY,
  REQUEST_FOLLOWERS,
  REQUEST_FOLLOWING
} from "./types";

const INITIAL_STATE = {
  followers: undefined,
  following: undefined,
  community: undefined,
  followersSearch: undefined,
  followingSearch: undefined,
  communitySearch: undefined,
  followersLoading: true,
  followingLoading: true,
  communityLoading: true,
  followersError: undefined,
  followingError: false,
  communityError: false,
  followersPageNo: 0,
  followingPageNo: 0,
  communityPageNo: 0,
  followingIsLast: true,
  followersIsLast: true,
  communityIsLast: true
};

export const peopleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_FOLLOWERS_REQUEST: {
      return {
        ...state,
        followersLoading: true
      };
    }

    case GET_FOLLOWING_REQUEST: {
      return {
        ...state,
        followingLoading: true
      };
    }

    case GET_COMMUNITY_REQUEST: {
      return {
        ...state,
        communityLoading: true
      };
    }

    case GET_FOLLOWERS_SUCCESS: {
      const existing = state.followers;
      return {
        ...state,
        followersLoading: false,
        followersError: undefined,
        followers: (action.pageno === 0 ? [] : existing || []).concat(
          action.payload
        ),
        followersPageNo: action.pageno,
        followersIsLast: action.isLast,
        followersSearch: action.search
      };
    }

    case GET_FOLLOWING_SUCCESS: {
      const existing = state.following;
      return {
        ...state,
        followingLoading: false,
        followingError: undefined,
        following: (action.pageno === 0 ? [] : existing || []).concat(
          action.payload
        ),
        followingPageNo: action.pageno,
        followingIsLast: action.isLast,
        followingSearch: action.search
      };
    }

    case UPDATE_FOLLOWING: {
      const existing = (state.following || []).map(person => {
        if (+person.user_id === +action.payload.userId) {
          return {
            ...person,
            isfollowing: action.payload.following ? 1 : 0
          };
        }
        return person;
      });
      return {
        ...state,
        following: existing
      };
    }

    case UPDATE_COMMUNITY: {
      const existing = (state.community || []).map(person => {
        if (+person.user_id === +action.payload.userId) {
          return {
            ...person,
            isfollowing: action.payload.following ? 1 : 0
          };
        }
        return person;
      });
      return {
        ...state,
        community: existing
      };
    }

    case UPDATE_FOLLOWERS: {
      const existing = (state.followers || []).map(person => {
        if (+person.user_id === +action.payload.userId) {
          return {
            ...person,
            isfollowing: action.payload.following ? 1 : 0
          };
        }
        return person;
      });
      return {
        ...state,
        followers: existing
      };
    }

    case REQUEST_FOLLOWING: {
      const existing = (state.following || []).map(person => {
        if (+person.user_id === +action.payload.userId) {
          return {
            ...person,
            reqpending: action.payload.requested ? 1 : 0
          };
        }
        return person;
      });
      return {
        ...state,
        following: existing
      };
    }

    case REQUEST_COMMUNITY: {
      const existing = (state.community || []).map(person => {
        if (+person.user_id === +action.payload.userId) {
          return {
            ...person,
            reqpending: action.payload.requested ? 1 : 0
          };
        }
        return person;
      });
      return {
        ...state,
        community: existing
      };
    }

    case REQUEST_FOLLOWERS: {
      const existing = (state.followers || []).map(person => {
        if (+person.user_id === +action.payload.userId) {
          return {
            ...person,
            reqpending: action.payload.requested ? 1 : 0
          };
        }
        return person;
      });
      return {
        ...state,
        followers: existing
      };
    }

    case GET_COMMUNITY_SUCCESS: {
      const existing = state.community;
      return {
        ...state,
        communityLoading: false,
        communityError: undefined,
        community: (action.pageno === 0 ? [] : existing || []).concat(
          action.payload
        ),
        communityPageNo: action.pageno,
        communityIsLast: action.isLast,
        communitySearch: action.search
      };
    }

    case GET_FOLLOWERS_FAILURE: {
      return {
        ...state,
        followersLoading: false,
        followersError: true
      };
    }

    case GET_FOLLOWING_FAILURE: {
      return {
        ...state,
        followingLoading: false,
        followingError: true
      };
    }

    case GET_COMMUNITY_FAILURE: {
      return {
        ...state,
        communityLoading: false,
        communityError: true
      };
    }

    default:
      return state;
  }
};
