import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
} from "./types";
import { SubmissionError } from "redux-form";
import { loginApi } from "../services";
import { enqueueSnackbar } from "../../Master/Notifier/duck";
import { GENERIC_ERROR_MESSAGE } from "../../constants";

export const logout = () => {
  return (dispatch) => {
    dispatch({
      type: LOGOUT_REQUEST,
    });
  };
};

export const login = (values, setIsOpen) => {
  return (dispatch) => {
    dispatch({
      type: LOGIN_REQUEST,
    });
    return loginApi(values)
      .then((res) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data.data,
        });
        setIsOpen(false);
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            message:
              (err.response.data && err.response.data.message) ||
              GENERIC_ERROR_MESSAGE,
            variant: "error",
          })
        );
        dispatch({
          type: LOGIN_FAILURE,
          payload: err,
        });

        throw new SubmissionError({ _error: err });
      });
  };
};
