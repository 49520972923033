import { createMuiTheme } from "@material-ui/core/styles";

const variables = {
  primaryColor: "rgba(255, 255, 255, 0.87)",
  secondaryWhite: "rgba(255, 255, 255, 0.6)",
  secondaryColor: "#F26724",
  secondaryLigt: "#f2672470",
  primaryBlack:
    "linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #121212",
  secondaryBlack: "#2B2B2B",
  appBar: "#2E2E2E",
  error: "#F91C1C",
  paleWhite: "#2E2E2E",
  white: "#FFFFFF",
  viewportFullHeight: `calc(100vh - 8em)`,
  bgColor: "#2E2E2E",
  actionBtnGreen: "#438c8b",
  lightGreen: "#08E8DE",
  paleGreen: "#58A4B0",
  green: "rgba(67, 140, 139)",
  neonRed: "#FF3E6D",
};

const baseTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    subtitle1: {
      color: variables.primaryColor,
      fontFamily: "Work Sans, sans-serif",
      fontSize: "1rem",
      letterSpacing: "0.0015em",
    },
    subtitle2: {
      color: variables.primaryColor,
      fontFamily: "Work Sans, sans-serif",
      fontWeight: 600,
      fontSize: "14px",
      letterSpacing: "0.001em",
    },
    h1: {
      color: variables.primaryColor,
      fontFamily: "Amatic SC, cursive",
      fontWeight: 300,
      fontSize: "64px",
      letterSpacing: "-0.005em",
    },
    h2: {
      color: variables.primaryColor,
      fontFamily: "Amatic SC, cursive",
      fontSize: "48px",
    },
    h3: {
      color: variables.primaryColor,
      fontFamily: "Amatic SC, cursive",
      fontSize: "34px",
      letterSpacing: "0.0025em",
    },
    h4: {
      color: variables.primaryColor,
      fontFamily: "Amatic SC, cursive",
      fontSize: "1.5rem",
      fontWeight: 900,
      letterSpacing: "0.0025em",
    },
    h5: {
      color: variables.primaryColor,
      fontFamily: "Amatic SC, cursive",
      fontWeight: 500,
      fontSize: "1.25rem",
      letterSpacing: "0.0025em",
    },
    body1: {
      color: variables.primaryColor,
      fontFamily: "Work Sans, sans-serif",
      fontWeight: 500,
      fontSize: "1rem",
      letterSpacing: "0.005em",
    },
    body2: {
      color: variables.primaryColor,
      fontFamily: "Work Sans, sans-serif",
      fontSize: "0.75rem",
      letterSpacing: "0.0025em",
    },
    caption: {
      color: variables.primaryColor,
      fontFamily: "Work Sans, sans-serif",
      fontSize: "0.875rem",
      // letterSpacing: "0.004em",
    },
  },

  palette: {
    primary: {
      main: variables.primaryColor,
      light: variables.secondaryWhite,
    },
    secondary: {
      main: variables.secondaryColor,
    },
    white: {
      main: variables.white,
    },
    error: {
      main: variables.error,
    },
  },
  ...variables,
});

export const muiTheme = {
  ...baseTheme,
  overrides: {
    MuiTypography: {
      root: {
        overflowWrap: "break-word",
      },
    },
    MuiButton: {
      root: {
        fontFamily: "Work Sans, sans-serif",
        fontWeight: 900,
      },
      text: {
        fontSize: "0.875rem",
        fontWeight: 900,
      },
      outlined: {
        fontSize: "0.875rem",
      },
      contained: {
        fontSize: "0.875rem",
        fontWeight: 900,
        "&$disabled": {
          color: baseTheme.white,
          backgroundColor: baseTheme.secondaryColor,
        },
      },
      disabled: {
        color: baseTheme.white,
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: baseTheme.appBar,
        color: baseTheme.secondaryWhite,
      },
    },
    MuiFormControlLabel: {
      root: {
        color: baseTheme.palette.primary.main,
      },
      label: {
        color: baseTheme.palette.primary.main,
      },
    },
    MuiFormLabel: {
      root: {
        color: "rgba(255, 255, 255, 0.87) !important",
      },
      focused: {
        color: baseTheme.palette.primary.main,
      },
      error: {
        color: `${baseTheme.palette.error.main} !important`,
      },
      asterisk: {
        color: baseTheme.palette.error.main,
      },
    },
    MuiInputLabel: {
      root: {
        color: baseTheme.palette.primary.main,
      },
      focused: {
        color: baseTheme.palette.primary.main,
      },
      shrink: {
        color: baseTheme.palette.primary.main,
        fontSize: "0.75rem",
      },
    },
    MuiInput: {
      root: {
        color: baseTheme.palette.primary.main,
      },
      focused: {
        color: baseTheme.palette.primary.main,
      },
      disabled: {
        color: baseTheme.palette.primary.main,
        opacity: "0.5 !important",
      },
      underline: {
        "&:before": {
          borderBottom: `1px solid ${baseTheme.palette.primary.main}`,
        },
        "&$error:after": {
          borderBottomColor: "#F91C1C",
        },
        "&:after": {
          borderBottom: `1px solid ${baseTheme.palette.primary.main}`,
        },
        "&:hover:not($disabled):before": {
          borderBottom: `1px solid ${baseTheme.palette.primary.main}`,
        },
      },
    },
    MuiSelect: {
      icon: {
        color: baseTheme.palette.primary.main,
      },
    },
    MuiFormHelperText: {
      error: {
        color: "#F91C1C !important",
      },
    },
    MuiSnackbarContent: {
      root: {
        flexWrap: "no-wrap",
      },
    },
  },
};
