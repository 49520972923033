import React from "react";
import PhotoCard from "../common/PhotoCard/PhotoCard";
import SettingsDialog from "../Profile/Photos/SettingsDialog";
import { ReactComponent as FollowIcon } from "../assets/follow.svg";
import { ReactComponent as DeleteIcon } from "../assets/profile/delete.svg";
import { ReactComponent as SettingsIcon } from "../assets/profile/settings.svg";

import ImportIcon from "../assets/import.svg";
import ImportTripDialog from "../common/ImportTripDialog/ImportTripDialog";
import { COMMUNITY_ID, FRIENDS_ID } from "../constants";
import { likePhoto } from "../Profile/Photos/duck";
import { connect } from "react-redux";
import { getImportActivity } from "../common/utils";
import "./index.scss";

function PhotosGrid(props) {
  const {
    photos,
    setIndex,
    setImages,
    setIsOpen,
    deletePhoto,
    type,
    like,
    activity,
    fetchTrip,
  } = props;

  const [isSettingsOpen, setSettingsOpen] = React.useState(false);
  const [currPhoto, setCurrPhoto] = React.useState(undefined);
  const [isImportDialogOpen, setImportDialog] = React.useState(false);

  const handleSettingsClose = () => {
    setSettingsOpen(false);
    setCurrPhoto(undefined);
  };

  const handleSettingsOpen = (photo) => {
    setCurrPhoto(photo);
    setSettingsOpen(true);
  };

  const likePhoto = (id, status) => {
    like(id, !status, type === "following" ? FRIENDS_ID : COMMUNITY_ID);
  };

  const renderCardActions = (photo) => {
    if (!type) {
      return (
        <>
          <DeleteIcon
            style={{ cursor: "pointer", width: "15px", height: "18px" }}
            onClick={() => deletePhoto(photo.photo_id)}
          />
          <SettingsIcon
            style={{ cursor: "pointer", width: "15px", height: "18px" }}
            onClick={() => handleSettingsOpen(photo)}
          />
        </>
      );
    }
    if (type) {
      return (
        <>
          <FollowIcon
            className={photo.liked ? "like-btn liked" : "like-btn"}
            onClick={() => likePhoto(photo.photo_id, photo.liked)}
            style={{ cursor: "pointer", width: "15px" }}
          />
          <img
            src={ImportIcon}
            alt="import"
            style={{ cursor: "pointer", width: "15px" }}
            onClick={() => {
              setCurrPhoto(photo);
              setImportDialog(true);
            }}
          />
        </>
      );
    }

    return null;
  };

  return (
    <div className="photos-grid">
      {photos.map((photo, index) => {
        return (
          <div className="grid-card" key={index}>
            <PhotoCard
              style={{
                maxWidth: "120px",
                minHeight: "120px",
                maxHeight: "120px",
                margin: "0",
                borderRadius: "5px"
              }}
              photo={{ trippic: photo.photo }}
              ispersonal={!type}
              onPhotoClick={() => {
                setIsOpen(true);
                setImages(photos);
                setIndex(index);
              }}
              renderActions={() => renderCardActions(photo)}
              imgStyle={{
                padding: "0",
                minHeight: "90px",
                maxHeight: "90px",
              }}
              btnStlye={{
                height: "30px",
              }}
            />
          </div>
        );
      })}
      {/* TODO send photo id, origin and access type */}
      {!type && (
        <SettingsDialog
          isOpen={isSettingsOpen}
          photo={currPhoto}
          handleClose={() => {
            fetchTrip();
            handleSettingsClose();
          }}
          initialValues={{
            privacy: String((currPhoto || {}).access_type),
          }}
        />
      )}
      {type && (
        <ImportTripDialog
          isOpen={isImportDialogOpen}
          handleClose={() => setImportDialog(false)}
          tripToImport={getImportActivity(activity)}
          isPhoto={true}
        />
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    like: (id, status, type) =>
      dispatch(likePhoto(type, id, status, "trip-explorer")),
  };
};

export default connect(null, mapDispatchToProps)(PhotosGrid);
