import { axiosInst, axiosConfig } from "../../axios";
import { enqueueSnackbar } from "../../Master/Notifier/duck";
// import { UPDATE_TRIP } from "../duck";

const getTripIdAndActivityId = (tripData) => {
  // const days = {};
  const idData = tripData.map((day) => {
    let idObj = {
      id: day.trip_day_id,
      data: day,
    };
    let activityIds = [];
    day.activitydata.forEach((activity) => {
      activityIds.push({
        id: activity.activity_id,
        data: activity,
      });
    });
    idObj.activityIds = activityIds;
    return idObj;
  });
  return idData;
};

const setActiveDayAndActivity = (existingIds, newData) => {
  existingIds.forEach((day, dayIndex) => {
    if (!newData[dayIndex]) {
      newData.push({
        ...day.data,
        trip_day_id: day.id,
        active: 0,
      });
      if (newData[dayIndex].activitydata.length) {
        let removedActivites = [];
        newData[dayIndex].activitydata.forEach((activity) => {
          removedActivites.push({
            ...activity,
            trip_activity_id: activity.activity_id,
            active: 0,
          });
        });
        delete newData[dayIndex].activitydata;
        newData[dayIndex].activity = removedActivites;
      }
    } else {
      if (
        day.activityIds &&
        newData[dayIndex].activity &&
        newData[dayIndex].activity.length < day.activityIds.length
      ) {
        day.activityIds.forEach((activity, index) => {
          if (!newData[dayIndex].activity[index]) {
            newData[dayIndex].activity.push({
              ...activity.data,
              trip_activity_id: activity.id,
              active: 0,
            });
          }
        });
      }
    }
  });
};

export const submitForm = (values, dispatch, props) => {
  // value => Plan data
  // props => tripdata and activitydata
  const {
    auth,
    trip: {
      tripdata: { trip_id },
      data: tripData,
    },
    history,
  } = props;
  const existingIds = getTripIdAndActivityId(tripData);
  const message = [];
  const upl = [];
  values.plan.forEach((day, index) => {
    const dayObj = {
      day: index + 1,
      Origin: day.startingplace.address,
      city: day.startingplace.city,
      lat: day.startingplace.lat,
      long: day.startingplace.lng,
      active: 1,
      type: String(day.type),
    };

    const activities =
      day.activities &&
      day.activities.map((activity, activityIndex) => {
        const Activity = {
          place_to: activity.to ? activity.to.address : "",
          place_to_lat: activity.to ? activity.to.lat : "",
          place_to_long: activity.to ? activity.to.lng : "",
          place_to_city: activity.to ? activity.to.city : "",
          place_from: activity.activityname.address,
          city: activity.activityname.city,
          activity_cost: activity.activitycost ? activity.activitycost : "",
          lat: activity.activityname.lat,
          long: activity.activityname.lng,
          note: activity.note,
           order_id: activityIndex + 1,
          activity_type: activity.activitytype,
          active: 1,
          activity_status: activity.activityname.activity_status || 0,
          pics: activity.activityname.pics || "",
          comments: activity.activityname.comments || "",
          type: String(activity.type),
          docname: activity.docname || "",
        };

        if (
          existingIds[index] &&
          existingIds[index].activityIds[activityIndex]
        ) {
          Activity.trip_activity_id =
            existingIds[index].activityIds[activityIndex].id;
        }

        return Activity;
      });

    dayObj.activity = activities || [];

    if (existingIds[index]) dayObj.trip_day_id = existingIds[index].id;
    message.push(dayObj);
  });

  setActiveDayAndActivity(existingIds, message);
  const data = {
    trip_id,
    message: JSON.stringify(message),
    trip_local_cost: values.plan.reduce((acc, day) => {
      const dayTotal = day.activities.reduce((acc1, activity) => {
        const aCost = activity.activitycost ? Number(activity.activitycost) : 0;
        return acc1 + aCost;
      }, 0);
      return acc + dayTotal;
    }, 0),
    // trip cost total value
    upl,
  };

  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append([key], data[key]);
  });

  return axiosInst
    .post("/ongoingeditdtl", formData, axiosConfig(auth.token))
    .then((res) => {
      if (res.data.status === 200) {
        // dispatch({
        //   type: "UPDATE_TRIP",
        //   payload: formData,
        // });
        dispatch(
          enqueueSnackbar({
            message: "Trip info saved",
            variant: "success",
          })
        );

        history.push("/profile");
      } else {
        dispatch(
          enqueueSnackbar({
            message: "Some error occured",
            variant: "error",
          })
        );
      }
    })
    .catch((err) => {
      if (
        err.response.data.status === 506 &&
        err.response.data.message ===
          "Opeartion failed or Access level cannot be less then child access level"
      ) {
        dispatch(
          enqueueSnackbar({
            message: "Check privacy level for itinerary",
            variant: "error",
          })
        );
      } else if (
        err.response.data.status === 400 &&
        err.response.data.message === "Mandatory Field trip id and message"
      ) {
        dispatch(
          enqueueSnackbar({
            message: "Trip needs atleast one day",
            variant: "error",
          })
        );
      } else {
        dispatch(
          enqueueSnackbar({
            message: err.response.data.message,
            variant: "error",
          })
        );
      }
    });
};
