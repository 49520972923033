import { axiosInst, axiosConfig } from "../../axios";

export const createTripApi = (values, token) => {
  return axiosInst.post("/createtrip", { ...values }, axiosConfig(token));
};

export const editTripApi = (values, token) => {
  return axiosInst.post("/edittrip", values, axiosConfig(token));
};

export const fetchTripApi = (id, type, token) => {
  
  return axiosInst.post(
    "/viewdetails",
    {
      trip_id: id,
      type: String(type)
    },
    axiosConfig(token)
  );
};

export const importTripApi = (values, token) => {
  
  return axiosInst.post("/importtrip", { ...values }, axiosConfig(token));
};

export const docUploadApi = (token, formData) => {
  return axiosInst.post("/docupload", formData, axiosConfig(token));
};
