import React from "react";
import {
  DayPointer,
  DayHeadingContainer,
  DayFieldGrid,
  DayDeleteIconGrid,
  AddActivityGrid,
  ActivityTextScreen,
  ActivityTextSmallScreen,
  AddDayButton,
} from "./styles";
import { requiredAddress } from "../../../common/FormFields/validations";
import { Field, FieldArray } from "redux-form";
import GoogleAutocomplete from "../../../common/FormFields/googlePlaceAutocomplete";
import ActivityFieldArray from "./ActivityFieldArray";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { ReactComponent as DeleteIcon } from "../../../assets/planner/delete.svg";
import { ReactComponent as PlusIcon } from "../../../assets/planner/add.svg";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { DayColors } from "../../../common/utils";
import moment from "moment";
import { Typography } from "@material-ui/core";

const DayFieldArray = (props) => {
  const {
    formValues,
    fields,
    lat,
    lng,
    addActivity,
    handleRecomendationClick,
    classes,
    trip: { tripdata },
    drivingTime,
    token,
    dayRef,
    showSnackBar,
    auth,
  } = props;

  const tripAccess = tripdata.trip_access_type;

  return (
    <>
      <Droppable type="days" droppableId="day">
        {/* {console.log(dayRef)} */}
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {/* <Tabs>
              {fields.map((day, index) => {
                return <Tab label={`Day ${index + 1}`} />;
              })}
            </Tabs> */}

            {fields.map((day, index) => (
              <Draggable key={index} draggableId={`day-${index}`} index={index}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.draggableProps}>
                    <div
                      style={{ padding: "8px" }}
                      ref={(el) => (dayRef.current[index] = el)}
                    >
                      <DayHeadingContainer container spacing={2} wrap="nowrap">
                        <DayPointer
                          item
                          xs="auto"
                          color={DayColors[index % DayColors.length]}
                          {...provided.dragHandleProps}
                        >
                          <Typography variant="subtitle1">
                            Day {index + 1}
                          </Typography>
                          <Typography variant="body2">
                            {moment(tripdata.from_dt)
                              .add(index, "day")
                              .format("ddd Do")}
                          </Typography>
                        </DayPointer>
                        <DayFieldGrid item xs>
                          <Field
                            label="Starting Place"
                            name={`${day}.startingplace`}
                            placeholder="Starting Place"
                            component={GoogleAutocomplete}
                            onSelect={(res) => {
                              return handleRecomendationClick(res, "Day");
                            }}
                            position="absolute"
                            validate={requiredAddress}
                            lat={+lat}
                            lng={+lng}
                          />
                        </DayFieldGrid>
                        <DayDeleteIconGrid item xs="auto">
                          <IconButton onClick={() => fields.remove(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </DayDeleteIconGrid>
                        <AddActivityGrid item xs="auto">
                          <Button
                            className="add-activity-btn"
                            onClick={() => addActivity(`${day}.activities`)}
                            color="secondary"
                            variant="outlined"
                          >
                            <PlusIcon />
                            <ActivityTextScreen>ACTIVITY</ActivityTextScreen>
                            <ActivityTextSmallScreen>
                              Act
                            </ActivityTextSmallScreen>
                          </Button>
                        </AddActivityGrid>
                      </DayHeadingContainer>
                    </div>
                    <Droppable
                      type="activity"
                      droppableId={`day-${index}-activities`}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          style={{
                            minHeight: "70px",
                            borderLeft: "1px solid rgba(255, 255, 255, 0.37)",
                            paddingBottom: "10px",
                          }}
                          {...provided.droppableProps}
                        >
                          <FieldArray
                            classes={classes}
                            formValues={formValues}
                            lat={+lat}
                            lng={+lng}
                            name={`${day}.activities`}
                            daykey={day}
                            handleActivityNote={props.handleActivityNote}
                            day={props.trip.data[index]}
                            dayIndex={index}
                            trip={props.trip}
                            auth={auth}
                            handleRecomendationClick={handleRecomendationClick}
                            component={ActivityFieldArray}
                            tripAccess={tripAccess}
                            dayAccess={fields.get(index).type}
                            drivingTime={drivingTime}
                            daysLen={fields.length}
                            token={token}
                            showSnackBar={showSnackBar}
                          />
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <AddDayButton
        color="secondary"
        onClick={() => fields.push({})}
        variant="outlined"
      >
        <PlusIcon />
        Day
      </AddDayButton>
    </>
  );
};

export default DayFieldArray;
