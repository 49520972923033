import types from "./types";

export const enqueueSnackbar = notification => ({
  type: types.ENQUEUE_SNACKBAR,
  payload: {
    key: new Date().getTime() + Math.random(),
    ...notification
  }
});

export const removeSnackbar = key => ({
  type: types.REMOVE_SNACKBAR,
  payload: key
});
