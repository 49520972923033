import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { AutoWidthGrid } from "../../common/Styles/GeneralStyles";
import Toolbar from "@material-ui/core/Toolbar";

export const LinksContainer = styled(AutoWidthGrid)`
  > div {
    margin: 0 8px;
    svg {
      min-height: 19px;
      min-width: 19px;
      max-height: 19px;
      max-width: 19px;
    }
  }
`;

export const StyledToolBar = styled(Toolbar)`
  && {
    height: 80px;
    ${(props) => props.theme.breakpoints.down("sm")} {
      padding-right: 40px;
    }

    ${(props) => props.theme.breakpoints.down("xs")} {
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
    }
  }
`;
export const StyledAppBar = styled(AppBar)`
  && {
    height: 80px;
    background-color: ${(props) => props.theme.appBar};
    padding: 0px;
    box-shadow: none;
  }
`;

export const OtherHeadings = styled(Typography)`
  && {
    font-weight: 400;
    line-height: normal;
    font-size: 1rem;
    color: #ffffff;
    text-decoration: none;
  }
`;

export const HeadingGrid = styled(Grid)`
  ${(props) => props.theme.breakpoints.down("sm")} {
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }
`;

export const HeadingContainer = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;
`;
