import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import ReduxFormTextField from "../../../common/FormFields/TextField";
import countryList from "../../../common/country";
// import { currencyList } from "../../../common/currency";
import { Field } from "redux-form";
import {
  required,
  spaces,
  fullname,
} from "../../../common/FormFields/validations";

const FormPure = (props) => {
  const { currency } = props;
  console.log(currency);
  return (
    <div>
      <Field
        className="form-input"
        name="fullName"
        label="Name"
        validate={[required, fullname]}
        component={ReduxFormTextField}
      />
      <Field
        className="form-input"
        name="user_name"
        required
        component={ReduxFormTextField}
        label="Username"
        validations={[required, spaces]}
      />
      <Field
        className="form-input"
        name="country"
        component={ReduxFormTextField}
        label="Country"
        select
      >
        {countryList.map((country, index) => (
          <MenuItem key={index} value={country.value}>
            {country.label}
          </MenuItem>
        ))}
      </Field>
      <Field
        className="form-input"
        name="currency"
        component={ReduxFormTextField}
        label="Currency"
        select
      >
        {currency.map((currency) => (
          <MenuItem key={currency} value={currency}>
            {currency}
          </MenuItem>
        ))}
      </Field>
      <Field
        className="form-input"
        name="desc"
        rows={2}
        multiline
        component={ReduxFormTextField}
        label="Bio"
      />
    </div>
  );
};

export default FormPure;
