import {
	GET_FOLLOWERS_REQUEST,
	GET_FOLLOWERS_SUCCESS,
	GET_FOLLOWERS_FAILURE,
	GET_FOLLOWING_REQUEST,
	GET_FOLLOWING_SUCCESS,
	GET_COMMUNITY_REQUEST,
	GET_COMMUNITY_SUCCESS,
	GET_COMMUNITY_FAILURE,
	GET_FOLLOWING_FAILURE,
	UPDATE_FOLLOWING,
	UPDATE_COMMUNITY,
	UPDATE_FOLLOWERS,
	REQUEST_FOLLOWING,
	REQUEST_FOLLOWERS,
	REQUEST_COMMUNITY,
} from "./types";

import { getPeopleApi, followApi, unfollowApi } from "./services";

import { enqueueSnackbar } from "../../../Master/Notifier/duck";
import { API_ERROR, MY_ID, FRIENDS_ID } from "../../../constants";

const dispatchSuccessActionsOnType = (
	tripType,
	dispatch,
	data,
	pageno,
	search
) => {
	if (tripType === MY_ID) {
		return dispatch({
			type: GET_FOLLOWING_SUCCESS,
			payload: data,
			pageno,
			isLast: !(data.length > 0),
			search,
		});
	}

	if (tripType === FRIENDS_ID) {
		return dispatch({
			type: GET_FOLLOWERS_SUCCESS,
			payload: data,
			pageno,
			isLast: !(data.length > 0),
			search,
		});
	}

	return dispatch({
		type: GET_COMMUNITY_SUCCESS,
		payload: data,
		pageno,
		isLast: !(data.length > 0),
		search,
	});
};

const dispatchRequestActionsOnType = (tripType, dispatch) => {
	if (tripType === MY_ID) {
		return dispatch({
			type: GET_FOLLOWING_REQUEST,
		});
	}

	if (tripType === FRIENDS_ID) {
		return dispatch({
			type: GET_FOLLOWERS_REQUEST,
		});
	}

	return dispatch({
		type: GET_COMMUNITY_REQUEST,
	});
};

const dispatchErrorActionsOnType = (tripType, dispatch) => {
	if (tripType === MY_ID) {
		return dispatch({
			type: GET_FOLLOWING_FAILURE,
		});
	}

	if (tripType === FRIENDS_ID) {
		return dispatch({
			type: GET_FOLLOWERS_FAILURE,
		});
	}

	return dispatch({
		type: GET_COMMUNITY_FAILURE,
	});
};

export const getPeople = (tripType, pageno, name, callback) => {
	return async (dispatch, getState) => {
		dispatchRequestActionsOnType(tripType, dispatch);
		const state = getState();
		try {
			const res = await getPeopleApi(state.auth.token, tripType, pageno, name);
			if (res.data.status === 200) {
				if (callback) {
					callback();
				}
				return dispatchSuccessActionsOnType(
					tripType,
					dispatch,
					res.data.data,
					pageno,
					name
				);
			}
		} catch (err) {
			if (callback) {
				callback();
			}
			if (err.response.data.status === 391) {
				return dispatchSuccessActionsOnType(tripType, dispatch, [], name);
			}
			dispatch(
				enqueueSnackbar({
					message: API_ERROR,
					variant: "error",
				})
			);
			dispatchErrorActionsOnType(tripType, dispatch);
		}
	};
};

export const follow = (userId, callback) => {
	return async (dispatch, getState) => {
		const state = getState();
		try {
			const res = await followApi(state.auth.token, userId);
			if (res.data.status === 200) {
				if (
					res.data &&
					res.data.message.includes("Request sent for approval")
				) {
					dispatch({
						type: REQUEST_FOLLOWING,
						payload: {
							requested: true,
							userId,
						},
					});
					dispatch({
						type: REQUEST_COMMUNITY,
						payload: {
							requested: true,
							userId,
						},
					});
					dispatch({
						type: REQUEST_FOLLOWERS,
						payload: {
							requested: true,
							userId,
						},
					});
				} else {
					dispatch({
						type: UPDATE_FOLLOWING,
						payload: {
							following: true,
							userId,
						},
					});
					dispatch({
						type: UPDATE_COMMUNITY,
						payload: {
							following: true,
							userId,
						},
					});
					dispatch({
						type: UPDATE_FOLLOWERS,
						payload: {
							following: true,
							userId,
						},
					});
				}
				if (callback) {
					callback();
				}
			}
		} catch (err) {
			if (callback) {
				callback(err);
			}
			dispatch(
				enqueueSnackbar({
					message: API_ERROR,
					variant: "error",
				})
			);
		}
	};
};

export const unfollow = (userId, callback) => {
	return async (dispatch, getState) => {
		const state = getState();
		try {
			const res = await unfollowApi(state.auth.token, userId);
			if (res.data.status === 200) {
				dispatch({
					type: UPDATE_FOLLOWING,
					payload: {
						following: false,
						userId,
					},
				});
				dispatch({
					type: UPDATE_COMMUNITY,
					payload: {
						following: false,
						userId,
					},
				});
				dispatch({
					type: UPDATE_FOLLOWERS,
					payload: {
						following: false,
						userId,
					},
				});
				if (callback) {
					callback(null, res.data);
				}
			}
		} catch (err) {
			if (callback) {
				callback(err);
			}
			dispatch(
				enqueueSnackbar({
					message: API_ERROR,
					variant: "error",
				})
			);
		}
	};
};
