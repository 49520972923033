import React from "react";
import { StyledMainDiv } from "./style";
import AppBar from "./AppBar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Close from "@material-ui/icons/Close";
import Notifier from "./Notifier";
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Drawer from "./Drawer";

const MasterPure = (props) => {
  const {
    children,
    auth: { isLoggedIn, user },
    isDrawerOpen,
    setDrawerOpen,
    location: { pathname },
  } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <SnackbarProvider
        maxSnack={2}
        action={
          <IconButton size="small" color="inherit">
            <Close style={{ color: "#fff" }} />
          </IconButton>
        }
      >
        <>
          <Notifier />
          <AppBar
            isLoggedIn={isLoggedIn}
            user={user}
            setDrawerOpen={setDrawerOpen}
            pathname={pathname}
          />
          <Hidden mdUp>
            <Drawer
              isLoggedIn={isLoggedIn}
              isDrawerOpen={isDrawerOpen}
              setDrawerOpen={setDrawerOpen}
              pathname={pathname}
            />
          </Hidden>
          <StyledMainDiv>{children}</StyledMainDiv>
        </>
      </SnackbarProvider>
    </MuiPickersUtilsProvider>
  );
};

export default MasterPure;
