import React from "react";
import Grid from "@material-ui/core/Grid";
import { PlanContainer } from "./styles";
import PlanHeading from "./PlanHeading";
import PlanDetails from "./PlanDetails";
import PhotoUploadDialog from "../PhotoUploadDialog";

const PlanPure = (props) => {
  const {
    trip,
    isDialogOpen,
    setDialogOpen,
    selected,
    handleClickUpload,
    checkboxes,
    handleCheckboxChange,
    handleActivityNote,
    type,
    duration,
    fetchTrip,
    auth,
  } = props;

  return (
    <PlanContainer>
      <Grid container>
        <Grid item xs={12}>
          <PlanHeading trip={trip} type={type} auth={auth} />
        </Grid>
        <Grid item xs={12} className="plan-container">
          <PlanDetails
            checkboxes={checkboxes}
            handleCheckboxChange={handleCheckboxChange}
            handleActivityNote={handleActivityNote}
            trip={trip}
            handleClickUpload={handleClickUpload}
            type={type}
            auth={auth}
            duration={duration}
            fetchTrip={fetchTrip}
          />
        </Grid>
      </Grid>
      {isDialogOpen && !type && (
        <PhotoUploadDialog
          open={isDialogOpen}
          selected={selected}
          handleClose={() => setDialogOpen(false)}
          initialValues={{
            privacy: ["3", "3", "3", "3", "3", "3", "3"],
            caption: selected.activity.comments,
          }}
          fetchTrip={fetchTrip}
        />
      )}
    </PlanContainer>
  );
};

export default PlanPure;
