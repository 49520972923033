import React from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import List from "@material-ui/core/List";
import { connect } from "react-redux";
import { getPhotos, getUserPhotos, likePhoto } from "./duck";
import LoadingScreen from "../../common/LoadingScreen";
import NoContent from "../../common/NoContent";
import Lightbox from "../../common/Lightbox";
import { ReactComponent as LikeIcon } from "../../assets/profile/like.svg";
import NotFound from "../../common/NotFound";
import {
  GENERIC_ERROR_MESSAGE,
  MY_ID,
  NO_CONTENT_PERSONAL_PHOTOS,
} from "../../constants";
import SettingsDialog from "./SettingsDialog";
import { uid } from "react-uid";
import MoreHorzIcon from "@material-ui/icons/MoreHoriz";
import PhotoCard from "../../common/PhotoCard/PhotoCard";
import { deletePhoto } from "../../TripExplorer/duck/actions";
import { StyledItem } from "./styles";
import { ReactComponent as DeleteIcon } from "../../assets/profile/delete.svg";
import { ReactComponent as SettingsIcon } from "../../assets/profile/settings.svg";
import { ReactComponent as ImportIcon } from "../../assets/profile/import.svg";
import ImportTripDialog from "../../common/ImportTripDialog/ImportTripDialog";

const Actions = ({ photo, handleSettingsOpen, deletePhoto, userId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <LikeIcon className="like-btn" />
        <Typography className="likes-count">{photo.likes}</Typography>
      </div>
      <IconButton
        style={{ padding: 0 }}
        color="primary"
        aria-label="More"
        aria-owns={anchorEl ? "long-menu" : undefined}
        aria-haspopup="true"
        onClick={(event) => {
          if (isMenuOpen) {
            setAnchorEl(undefined);
            setIsMenuOpen(false);
          } else {
            setAnchorEl(event.currentTarget);
            setIsMenuOpen(true);
          }
        }}
        size="small"
      >
        <MoreHorzIcon />
      </IconButton>
      <>
        <Popper
          id="long-menu"
          open={isMenuOpen}
          anchorEl={anchorEl}
          role={undefined}
          transition
          style={{
            zIndex: 1,
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setIsMenuOpen(false)}>
                  <List>
                    <StyledItem onClick={() => handleSettingsOpen(photo)}>
                      <SettingsIcon />
                      Settings
                    </StyledItem>
                    <StyledItem onClick={() => deletePhoto(photo.photo_id)}>
                      <DeleteIcon />
                      Delete
                    </StyledItem>
                  </List>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    </>
  );
};

const Personal = (props) => {
  
  const {
    getPhotos,
    error,
    photos,
    loading,
    pageno,
    isLast,
    search,
    deletePhoto,
    userId,
    like,
  } = props;

  const [isFetching, setIsFetching] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSettingsOpen, setSettingsOpen] = React.useState(false);
  const [currPhoto, setCurrPhoto] = React.useState(undefined);
  const [photoIndex, setIndex] = React.useState(0);
  const [isImportDialogOpen, setImportDialog] = React.useState(false);
  const [selectedTrip, setTrip] = React.useState();

  const likePhoto = (id, status) => {
    like(id, !status);
  };

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 390
    ) {
      setIsFetching(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, false);
    getPhotos(0);
    return () => window.removeEventListener("scroll", handleScroll, false);
  }, [getPhotos]);

  React.useEffect(() => {
    if (!isFetching || !photos || isLast || search) return;
    getPhotos(pageno + 1, () => setIsFetching(false));
  }, [isFetching, photos, isLast, search, getPhotos, pageno]);

  if (error) {
    return <NotFound errorMsg={GENERIC_ERROR_MESSAGE} />;
  }

  if (loading && !photos) {
    return <LoadingScreen />;
  }

  if (photos.length === 0) {
    return (
      <NoContent heading={NO_CONTENT_PERSONAL_PHOTOS} alignText="center" />
    );
  }

  const onPhotoClick = (index) => {
    setIndex(index);
    setIsOpen(true);
  };

  const handleSettingsClose = () => {
    getPhotos(0, () => {
      setSettingsOpen(false);
      setCurrPhoto(undefined);
    });
  };

  const handleSettingsOpen = (photo) => {
    setCurrPhoto(photo);
    setSettingsOpen(true);
  };
  
  return (
    <>
      <Grid container wrap="wrap" spacing={4}>
        {photos.map((photo, index) => (
          <Grid key={uid(photo, index)} item md={4} xs={6}>
            <PhotoCard
              photo={photo}
              location={photo.location}
              ispersonal
              onPhotoClick={() => onPhotoClick(index)}
              renderHeader={(photo) => {
                if (userId) {
                  return (
                    <div className="user-heading">
                      <Typography className="username">
                        @{photo.username}
                      </Typography>
                    </div>
                  );
                }

                return (
                  <div className="user-heading">
                    <Typography className="username">
                      @{photo.username}
                    </Typography>
                  </div>
                );
              }}
              renderActions={() => {
                if (userId) {
                  return (
                    <>
                      <LikeIcon
                        className={
                          photo.is_like ? "like-btn liked" : "like-btn"
                        }
                        onClick={() => likePhoto(photo.photo_id, photo.is_like)}
                      />
                      <ImportIcon
                        onClick={() => {
                          const importPhoto = { ...photo };
                          importPhoto.location_to = photo.place_to;
                          importPhoto.city_to = photo.city_to;
                          importPhoto.lat_to = photo.place_to_lat;
                          importPhoto.longi_to = photo.place_to_longi;
                          setTrip(importPhoto);
                          setImportDialog(true);
                        }}
                      />
                    </>
                  );
                }
                return (
                  <Actions
                    userId={userId}
                    photo={photo}
                    handleSettingsOpen={handleSettingsOpen}
                    deletePhoto={deletePhoto}
                  />
                );
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Lightbox
        setIndex={setIndex}
        photoIndex={photoIndex}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        photos={(photos || []).map((photo) => photo.trippic)}
      />
      <SettingsDialog
        isOpen={isSettingsOpen}
        photo={currPhoto}
        handleClose={() => handleSettingsClose()}
        initialValues={{
          privacy: String((currPhoto || {}).access_type),
        }}
      />
      <ImportTripDialog
        isOpen={isImportDialogOpen}
        handleClose={() => setImportDialog(false)}
        tripToImport={selectedTrip}
        isPhoto={true}
        triptype={"trips"}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    photos: {
      personalError,
      personalLoading,
      personal,
      personalIsLast,
      personalPageNo,
      personalSearch,
    },
  } = state;
  return {
    error: personalError,
    loading: personalLoading,
    photos: personal,
    isLast: personalIsLast,
    pageno: personalPageNo,
    search: personalSearch,
  };
};

const mapDispatchToProps = (dispatch, { userId }) => {
  return {
    getPhotos: (page, callback) => {
      if (userId) {
        return dispatch(getUserPhotos(userId, page, undefined, callback));
      }
      return dispatch(getPhotos(MY_ID, page, undefined, callback));
    },
    deletePhoto: (photoId) => {
      dispatch(deletePhoto(photoId, () => dispatch(getPhotos(MY_ID, 0))));
    },
    like: (id, status) => dispatch(likePhoto(MY_ID, id, status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Personal);
