export const GET_FOLLOWERS_REQUEST = "GET_FOLLOWERS_REQUEST";
export const GET_FOLLOWING_REQUEST = "GET_FOLLOWING_REQUEST";
export const GET_COMMUNITY_REQUEST = "GET_COMMUNITY_REQUEST";

export const GET_FOLLOWERS_SUCCESS = "GET_FOLLOWERS_SUCCESS";
export const GET_FOLLOWING_SUCCESS = "GET_FOLLOWING_SUCCESS";
export const GET_COMMUNITY_SUCCESS = "GET_COMMUNITY_SUCCESS";

export const GET_FOLLOWERS_FAILURE = "GET_FOLLOWERS_FAILURE";
export const GET_FOLLOWING_FAILURE = "GET_FOLLOWING_FAILURE";
export const GET_COMMUNITY_FAILURE = "GET_COMMUNITY_FAILURE";

export const UPDATE_FOLLOWING = "UPDATE_FOLLOWING";
export const UPDATE_COMMUNITY = "UPDATE_COMMUNITY";
export const UPDATE_FOLLOWERS = "UPDATE_FOLLOWERS";

export const REQUEST_FOLLOWING = "REQUEST_FOLLOWING";
export const REQUEST_COMMUNITY = "REQUEST_COMMUNITY";
export const REQUEST_FOLLOWERS = "REQUEST_FOLLOWERS";
