import React from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const sideList = isLoggedIn => (
  <List>
    {!isLoggedIn && (
      <ListItem button component={Link} to="/login">
        <ListItemText primary={"Sign-In"} />
      </ListItem>
    )}
    {/* {["Sample Trips", "About Us", "Help ?"].map((text, index) => (
      <ListItem button key={text}>
        <ListItemText primary={text} />
      </ListItem>
    ))} */}
  </List>
);

const DrawerPure = props => {
  const { isDrawerOpen, setDrawerOpen, isLoggedIn } = props;
  return (
    <SwipeableDrawer
      open={isDrawerOpen}
      onClose={() => setDrawerOpen(false)}
      onOpen={() => setDrawerOpen(true)}
    >
      <div
        tabIndex={0}
        role="button"
        onClick={() => setDrawerOpen(false)}
        onKeyDown={() => setDrawerOpen(false)}
      >
        {sideList(isLoggedIn)}
      </div>
    </SwipeableDrawer>
  );
};

export default DrawerPure;
