import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

export const GridContainer = styled(Grid)`
  min-height: calc(100vh - 80px);
  margin-top: 80px;
  overflow-y: auto;
  overflow-x: hidden;
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
  }
`;

export const MapContainer = styled(Grid)`
  height: calc(100vh - 80px);
  overflow: hidden;
  ${(props) => props.theme.breakpoints.down("sm")} {
    height: calc(40vh - 80px);
  }
`;

export const PlanContainer = styled(Grid)`
  height: calc(100vh - 80px);
  overflow-y: auto;
  overflow-x: hidden;
  ${(props) => props.theme.breakpoints.down("sm")} {
    overflow: hidden;
    height: 100%;
  }
`;

export const LeftGrid = styled(Grid)`
  min-height: ${(props) => props.height};
  border-right: 2px solid black;
`;
