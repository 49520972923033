import React from "react";
import Login from "./Login";
import Signup from "./Signup";
import Generate from "./Generate";
import Validate from "./Validate";

import { useHistory, useLocation } from "react-router-dom";

/**
 * Onboarding Context Provider component that will provide the app with login/signup/forgot modals
 */

export const OnboardingContext = React.createContext({
	setForgotOpen: (state) => {},
	setLoginOpen: (state) => {},
	setSignupOpen: (state) => {},
	setValidateOpen: (state) => {},
});

const OnboardingProvider = ({ children }) => {
	const [isLoginOpen, setLoginOpen] = React.useState(false);
	const [isSignupOpen, setSignupOpen] = React.useState(false);
	const [isForgotOpen, setForgotOpen] = React.useState(false);
	const [isValidateOpen, setValidateOpen] = React.useState(false);

	const history = useHistory();
	const location = useLocation();

	return (
		<OnboardingContext.Provider
			value={{
				setLoginOpen: (state) => {
					setLoginOpen(state);
				},
				setSignupOpen: (state) => {
					setSignupOpen(state);
				},
				setForgotOpen: (state) => {
					setForgotOpen(state);
				},
				setValidateOpen: (state) => {
					setValidateOpen(state);
				},
			}}
		>
			{children}
			<Login
				isOpen={isLoginOpen}
				setIsOpen={setLoginOpen}
				setSignupOpen={setSignupOpen}
				setForgotOpen={setForgotOpen}
			/>
			<Signup
				isOpen={isSignupOpen}
				setIsOpen={setSignupOpen}
				setLoginOpen={setLoginOpen}
			/>
			<Generate
				isOpen={isForgotOpen}
				setIsOpen={setForgotOpen}
				setLoginOpen={setLoginOpen}
			/>
			<Validate
				isOpen={isValidateOpen}
				setIsOpen={setValidateOpen}
				setLoginOpen={setLoginOpen}
				location={location}
				history={history}
			></Validate>
		</OnboardingContext.Provider>
	);
};

export default OnboardingProvider;
