import React from 'react';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as NotFoundIcon } from '../../assets/not-found.svg';
import { NotFoundContainer } from './styles';

const NotFound = (props) => {
  const { errorMsg, description } = props;
  return (
    <NotFoundContainer>
      {errorMsg && (
        <Typography variant="h5" color="secondary">
          {errorMsg}
        </Typography>
      )}
      <NotFoundIcon />
      {description && (
        <Typography variant="body1">
          {description}
        </Typography>
      )}
    </NotFoundContainer>
  );
}

export default NotFound;
