import React from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { getPeople } from "./duck/actions";
import {
  MY_ID,
  GENERIC_ERROR_MESSAGE,
  NO_CONTENT_PEOPLE,
} from "../../constants";
import LoadingScreen from "../../common/LoadingScreen";
import NotFound from "../../common/NotFound";
import NoContent from "../../common/NoContent";
import PeopleCard from "./PeopleCard";
import { uid } from "react-uid";

const Following = (props) => {
  const {
    getFollowing,
    error,
    loading,
    people,
    pageno,
    isLast,
    search,
  } = props;
  const [isFetching, setIsFetching] = React.useState(false);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 390
    ) {
      setIsFetching(true);
    }
  };

  React.useEffect(() => {
    getFollowing(0);
    window.addEventListener("scroll", handleScroll, false);
    return () => window.removeEventListener("scroll", handleScroll, false);
  }, [getFollowing]);

  React.useEffect(() => {
    if (!isFetching || !people || isLast || search) return;
    getFollowing(pageno + 1, () => setIsFetching(false));
  }, [isFetching, people, isLast, search, getFollowing, pageno]);

  if (error) {
    return <NotFound errorMsg={GENERIC_ERROR_MESSAGE} />;
  }

  if (loading && !people) {
    return <LoadingScreen />;
  }

  if (people.length === 0) {
    return <NoContent heading={NO_CONTENT_PEOPLE} alignText="center" />;
  }

  return (
    <Grid container wrap="wrap" spacing={4}>
      {people.map((person, index) => (
        <Grid key={uid(person, index)} item md={4} xs={6}>
          <PeopleCard person={person} />
        </Grid>
      ))}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const {
    people: {
      followingError,
      followingLoading,
      following,
      followingIsLast,
      followingPageNo,
      followingSearch,
    },
  } = state;
  return {
    error: followingError,
    loading: followingLoading,
    // people: following ? following.filter(people => people.isfollowing) : following,
    people: following,
    isLast: followingIsLast,
    pageno: followingPageNo,
    search: followingSearch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFollowing: (offset, callback) =>
      dispatch(getPeople(MY_ID, offset, undefined, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Following);
