import { compose, withHandlers, withState } from "recompose";
import { connect } from "react-redux";
import { logout } from "../../Onboarding/duck";
import withWidth from "@material-ui/core/withWidth";

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout())
  };
};

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

const AppBarHandlers = compose(
  withWidth(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withState("anchorEl", "setAnchorEl", null),
  withHandlers({
    handleClick: props => event => {
      const { setAnchorEl } = props;
      setAnchorEl(event.currentTarget);
    },
    handleClose: props => () => {
      const { setAnchorEl } = props;
      setAnchorEl(null);
    }
  })
);

export default AppBarHandlers;
