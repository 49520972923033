import { connect } from "react-redux";
import { compose } from "redux";
import { bindActionCreators } from "redux";
import { withSnackbar } from "notistack";
import { removeSnackbar } from "./duck";
import withWidth from "@material-ui/core/withWidth";

const mapStateToProps = state => ({
  notifications: state.snackbar.notifications
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ removeSnackbar }, dispatch);

const NotifierHandlers = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withSnackbar,
  withWidth()
);

export default NotifierHandlers;
