import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { arrayPush, getFormValues, initialize } from "redux-form";
import { ArrayMove } from "../../../common/utils";
import { withStyles } from "@material-ui/core";
import { ACTIVITY_TYPES } from "../../../constants";
import { axiosConfig, axiosInst } from "../../../axios";

const styles = (theme) => ({
  customWidth: {
    maxWidth: 200,
  },
});

const mapStateToProps = (state) => {
  return {
    values: getFormValues("trip-plan-form")(state),
    trip: state.trip,
    importTrip: state.importTrip,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addActivity: (fieldName) =>
      dispatch(
        arrayPush("trip-plan-form", fieldName, {
          activitytype: ACTIVITY_TYPES[0].value,
        })
      ),
    initialize: (data) => dispatch(initialize("trip-plan-form", data)),
  };
};

const PlanFormHandlers = compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    handleDragEnd: (props) => (eve) => {
      const {
        values: { plan },
        initialize,
        importChips,
        removeImportChip,
      } = props;
      const { source, destination, type } = eve;
      if (type === "activity" && destination) {
        // handle activity drop

        if (source.droppableId === "activity-chips") {
          const data = importChips[source.index];
          const splitDestination = destination.droppableId.split("-");

          // if day doesnt have data, create an empty object and assign activity an empty array
          if (!plan[splitDestination[1]]) {
            plan[splitDestination[1]] = {};
          }

          // if day has data but no activity
          if (!plan[splitDestination[1]].activities) {
            plan[splitDestination[1]].activities = [];
          }

          plan[splitDestination[1]].activities.splice(destination.index, 0, {
            activityname: {
              lat: +data.lat,
              lng: +data.longi,
              address: data.origin || " ",
              city: data.city,
            },
            activitytype: data.activityType,

            type: data.type,
            to: {
              lat: +data.latTo,
              lng: +data.longiTo,
              address: data.locationTo || " ",
              city: data.cityTo,
            },
          });
          // remove the chip from source
          removeImportChip(data.chipIndex);
        } else if (source.droppableId === destination.droppableId) {
          // if activity is interchanged in the same day
          const splitId = source.droppableId.split("-");
          ArrayMove(
            plan[splitId[1]].activities,
            source.index,
            destination.index
          );
        } else {
          const splitSource = source.droppableId.split("-");
          const splitDestination = destination.droppableId.split("-");
          // insert the activity to destination

          // if day doesnt have data, create an empty object and assign activity an empty array
          if (!plan[splitDestination[1]]) {
            plan[splitDestination[1]] = {};
          }

          // if day has data but no activity
          if (!plan[splitDestination[1]].activities) {
            plan[splitDestination[1]].activities = [];
          }

          plan[splitDestination[1]].activities.splice(
            destination.index,
            0,
            plan[splitSource[1]].activities[source.index]
          );
          // remove the activity from source
          plan[splitSource[1]].activities.splice(source.index, 1);
        }
        initialize({ plan });
      } else if (type === "days" && destination) {
        // handle day drop
        ArrayMove(plan, source.index, destination.index);
        initialize({ plan });
      }
    },
  }),
  withHandlers({
    handleActivityNote: (props) => (activity, day, onNoteChange) => {
      const {
        checkboxes,
        trip: {
          tripdata: { trip_id },
        },

        auth: { token },
      } = props;

      if (onNoteChange) {
        return axiosInst
          .post(
            "/ongoingeditstatus",
            {
              trip_id: String(trip_id),
              trip_activity_id: String(activity.activity_id),
              trip_day_id: String(day.trip_day_id),
              activity_status: checkboxes[activity.activity_id] ? 1 : 0,
              note: onNoteChange,
            },
            axiosConfig(token)
          )
          .then((res) => console.log(res))
          .catch((err) => console.log(err));
      }
    },
  })
);

export default PlanFormHandlers;
