import React from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import {
  COMMUNITY_ID,
  GENERIC_ERROR_MESSAGE,
  NO_CONTENT_COMMUNITY,
} from "../../constants";
import { getPeople } from "./duck/actions";
import LoadingScreen from "../../common/LoadingScreen/pure";
import NotFound from "../../common/NotFound";
import NoContent from "../../common/NoContent";
import PeopleCard from "./PeopleCard";
import { uid } from "react-uid";

const Community = (props) => {
  const {
    getCommunity,
    error,
    people,
    loading,
    pageno,
    isLast,
    search,
  } = props;

  const [isFetching, setIsFetching] = React.useState(false);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 390
    ) {
      setIsFetching(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, false);
    getCommunity(0);
    return () => window.removeEventListener("scroll", handleScroll, false);
  }, [getCommunity]);

  React.useEffect(() => {
    if (!isFetching || !people || isLast || search) return;
    getCommunity(pageno + 1, () => setIsFetching(false));
  }, [getCommunity, isFetching, isLast, pageno, people, search]);

  if (error) {
    return <NotFound errorMsg={GENERIC_ERROR_MESSAGE} />;
  }

  if (loading && !people) {
    return <LoadingScreen />;
  }

  if (people.length === 0) {
    return <NoContent heading={NO_CONTENT_COMMUNITY} alignText="center" />;
  }

  return (
    <Grid container wrap="wrap" spacing={4}>
      {people.map((person, index) => (
        <Grid key={uid(person, index)} item md={4} xs={6}>
          <PeopleCard person={person} />
        </Grid>
      ))}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const {
    people: {
      communityError,
      communityLoading,
      community,
      communityIsLast,
      communityPageNo,
      communitySearch,
    },
  } = state;

  return {
    error: communityError,
    loading: communityLoading,
    people: community,
    isLast: communityIsLast,
    pageno: communityPageNo,
    search: communitySearch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCommunity: (page, callback) =>
      dispatch(getPeople(COMMUNITY_ID, page, undefined, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Community);
