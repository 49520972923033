import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { StyledTabs, StyledTab } from "../styles";
import Personal from "./Personal";
import Friends from "./Friends";
import SearchBar from "../../common/SearchBar";
import ActionBtn from "../ActionBtn";
import { CREATE_TRIP_URL } from "../../urls";
import ComponentWrapper from "../ComponentWrapper";
import Community from "./Community";
import { getTrips, getUserTrips } from "../duck";
import { getType } from "../../common/utils";

const TABS = ["Trips", "Following", "Community"];

const USER_TABS = ["Trips"];

const Trips = ({ getTrips, userId }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [isSearchVisible, setSearchVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTabIndex(0);
  }, [userId]);

  const handleSearch = (val) => {
    setSearchVal(val);
    getTrips(getType(tabIndex), val);
  };

  const handleTabChange = (e, index) => {
    setTabIndex(index);
    setSearchVal("");
  };

  const getTabs = () => {
    if (userId) {
      return USER_TABS;
    }

    return TABS;
  };

  return (
    <>
      <StyledTabs
        value={tabIndex}
        onChange={handleTabChange}
        isSearchVisible={isSearchVisible ? 1 : 0}
      >
        {getTabs().map(
          (tab) =>
            !isSearchVisible && (
              <StyledTab key={tab} label={tab} disableRipple />
            )
        )}
        {!userId && <ActionBtn to={CREATE_TRIP_URL} label="Create Trip" />}
        <StyledTab
          className="search-bar search-bar-desktop"
          disabled
          label={
            <SearchBar
              onChange={handleSearch}
              initialSearchValue={searchVal}
              label="Search Trips"
            />
          }
        />
        <StyledTab
          className="search-bar search-bar-mobile"
          disabled
          label={
            <div className="search-container">
              {!isSearchVisible && (
                <SearchIcon onClick={() => setSearchVisible(true)} />
              )}
              {isSearchVisible && (
                <span>
                  <SearchBar
                    onChange={handleSearch}
                    initialSearchValue={searchVal}
                    label="Search Trips"
                  />
                  <ClearIcon onClick={() => setSearchVisible(false)} />
                </span>
              )}
            </div>
          }
        />
      </StyledTabs>
      <ComponentWrapper>
        {tabIndex === 0 && <Personal userId={userId} />}
        {tabIndex === 1 && <Friends />}
        {tabIndex === 2 && <Community />}
      </ComponentWrapper>
    </>
  );
};

const mapDispatchToProps = (dispatch, { userId }) => {
  if (userId) {
    return {
      getTrips: (tripType, name) => dispatch(getUserTrips(userId, 0, name)),
    };
  }
  return {
    getTrips: (tripType, name) => dispatch(getTrips(tripType, 0, name)),
  };
};

export default connect(null, mapDispatchToProps)(Trips);
