import CommunityIcon from "./assets/community.svg";
import PrivateIcon from "./assets/private.svg";
import FriendsIcon from "./assets/friends.svg";

export const GENERIC_ERROR_MESSAGE = "Something went wrong !";

export const API_ERROR = "Some error occured";

export const PAGE_NOT_FOUND = "Page Not Found !";

export const PAGE_NOT_FOUND_DESC =
  "Oops! The page you are looking for doesn't exist";

export const PROFILE_UPDATED_SUCCESS = "Profile updated";

export const MY_ID = 3;

export const FRIENDS_ID = 2;

export const COMMUNITY_ID = 1;

export const ACTIVITY_TYPES = [
  {
    value: "restaurant",
    label: "Restaurant"
  },
  {
    value: "event",
    label: "Event"
  },
  {
    value: "places",
    label: "Places"
  },
  {
    value: "hotel",
    label: "Hotel"
  },
  {
    value: "rail",
    label: "Rail"
  },
  {
    value: "flight",
    label: "Flight"
  }
];

export const FOLLOWING = "Following";

export const FOLLOW = "Follow";

export const REQUESTED = "Requested";

export const NO_CONTENT_PEOPLE = "Start following people";

export const NO_CONTENT_PHOTOS = "No photos";

export const NO_CONTENT_FOLLOWERS = "You don't have any followers";

export const NO_CONTENT_COMMUNITY = "Let's create everlasting travel memories";

export const NO_CONTENT_PERSONAL_PHOTOS = "No photos. Take a break and travel!";

export const UPDATE_PRIVACY_SUCCESS = "Privacy settings updated!";

export const PHOTO_UPLOAD_SUCCESS = "Photos uploaded";

export const PRIVACY_OPTIONS = [
  {
    label: "Public [ To Community]",
    value: String(COMMUNITY_ID),
    icon: CommunityIcon
  },
  {
    label: "Public [ To Followers ]",
    value: String(FRIENDS_ID),
    icon: FriendsIcon
  },
  {
    label: "Private [ Default ]",
    value: String(MY_ID),
    icon: PrivateIcon
  }
];

export const RECOMMENDATION_HELP_TEXT =
  "Recommends hotels/restaurants/places/ events based on type selected and your previous location ";
