import types from "./types";

const defaultState = {
  notifications: []
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.payload
          }
        ]
      };

    case types.REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.payload
        )
      };

    default:
      return state;
  }
};
