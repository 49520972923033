import React from "react";
import { Field } from "redux-form";
import ReduxFormTextField from "../../common/FormFields/TextField";
import LoadingButton from "../../common/LoadingButton";
import { required } from "../../common/FormFields/validations";
import ResponsiveDialog from "../../common/ResponsiveDialog";
import { OnboardingWrapper } from "../OnboardingWrapper";
import { Typography, Button } from "@material-ui/core";

import "../index.scss";

const GeneratePure = (props) => {
  const { submitting, handleSubmit, isOpen, setIsOpen, setLoginOpen } = props;
  return (
    <ResponsiveDialog
      showActions={false}
      showTitle={false}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dialogClass="onboarding-dialog"
      contentClass="onboarding-modal-content"
      renderContent={() => {
        return (
          <OnboardingWrapper handleClose={() => setIsOpen(false)}>
            <div className="onboarding-main-content">
              <Typography gutterBottom variant="h4" color="secondary">
                An email with instructions to reset your password will be sent
                to your registered email address
              </Typography>
              <form className="onboarding-form" onSubmit={handleSubmit}>
                <div className="form-item">
                  <Field
                    name="email"
                    required
                    component={ReduxFormTextField}
                    label="Registered Email"
                    validate={required}
                  />
                </div>
                <div>
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    type={"submit"}
                    submitting={submitting}
                  >
                    Send Email
                  </LoadingButton>
                </div>
              </form>
              <div className="onboarding-options">
                <Typography variant="caption">
                  Already have an account ?
                </Typography>
                <Button
                  onClick={() => {
                    setIsOpen(false);
                    setLoginOpen(true);
                  }}
                  color="secondary"
                  variant="text"
                >
                  Login
                </Button>
              </div>
            </div>
          </OnboardingWrapper>
        );
      }}
    />
  );
};

export default GeneratePure;
