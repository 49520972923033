import React from "react";
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  InfoWindow,
  Polyline,
} from "react-google-maps";
import Day1 from "./day1.png";
import Day2 from "./day2.png";
import Day3 from "./day3.png";
import Day4 from "./day4.png";
import Day5 from "./day5.png";
import Day6 from "./day6.png";
import Day7 from "./day7.png";

const iconsArray = [Day1, Day2, Day3, Day4, Day5, Day6, Day7];

const GoogleMapPure = (props) => {
  const {
    markers,
    flightDirections,
    directions,
    map,
    nearby,
    lat,
    lng,
    zoom,
    onZoomChange,
  } = props;

  const [placesService, setPlacesService] = React.useState();
  const [nearbyMarkers, setNearbyMarkers] = React.useState([]);

  React.useEffect(() => {
    if (map && !placesService) {
      const service = new window.google.maps.places.PlacesService(
        map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED
      );
      setPlacesService(service);
    }
  }, [map, placesService]);

  React.useEffect(() => {
    if (nearby.place && nearby.type && placesService) {
      onZoomChange(14);
      placesService.nearbySearch(
        {
          location: {
            lat: +nearby.place.lat,
            lng: +nearby.place.long,
          },
          radius: 2000,
          type: [nearby.type],
        },
        callback
      );
    }

    function callback(results, status) {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const places = [];
        results.forEach((res) => {
          const placeLoc = res.geometry.location;
          const marker = {
            lat: placeLoc.lat(),
            lng: placeLoc.lng(),
            name: res.name,
            type: nearby.type,
            open: false,
            icon: res.icon,
            placeId: res.place_id,
          };
          places.push(marker);
        });
        setNearbyMarkers(places);
      }
    }
  }, [nearby, placesService, onZoomChange]);

  const toggleOpen = (index) => {
    const copy = [...nearbyMarkers];
    copy[index].open = !copy[index].open;
    setNearbyMarkers(copy);
  };

  const lineSymbol = {
    path: "M 0,-0.4 0,0.4",
    strokeOpacity: 0.5,
    scale: 4,
    strokeColor: "#20a362",
  };

  return (
    <GoogleMap
      ref={props.onMapMounted}
      onZoomChanged={props.onZoomChanged}
      zoom={zoom}
      center={{
        lat: nearby.place ? +nearby.place.lat : lat,
        lng: nearby.place ? +nearby.place.long : lng,
      }}
      defaultOptions={{
        styles: [
          { elementType: "geometry", stylers: [{ color: "#2E2E2E" }] },
          {
            elementType: "labels.text.stroke",
            stylers: [{ color: "#242f3e" }],
          },
          { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
          {
            featureType: "administrative.locality",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#263c3f" }],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [{ color: "#6b9a76" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#38414e" }],
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#212a37" }],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9ca5b3" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{ color: "#746855" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#1f2835" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [{ color: "#f3d19c" }],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [{ color: "#2f3948" }],
          },
          {
            featureType: "transit.station",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#17263c" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [{ color: "#515c6d" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#17263c" }],
          },
        ],
      }}
    >
      {/* <MarkerClusterer averageCenter enableRetinaIcons gridSize={80}> */}
      {nearbyMarkers.map((marker, index) => {
        return (
          <Marker
            icon={{
              url: marker.icon,
              scaledSize: new window.google.maps.Size(20, 20),
            }}
            // label={{
            //   text: String(index + 1),
            //   color: "white",
            //   fontWeight: "600"
            // }}
            key={index}
            position={{ lat: marker.lat, lng: marker.lng }}
            onClick={() => toggleOpen(index)}
          >
            {marker.open && (
              <InfoWindow>
                <div>
                  <p style={{ color: "black" }}>{marker.name}</p>
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                      marker.name
                    )}&query_place_id=${marker.placeId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View on Google Maps
                  </a>
                </div>
              </InfoWindow>
            )}
          </Marker>
        );
      })}
      {/* </MarkerClusterer> */}

      {markers.ungrouped.map((marker, index) => {
        return (
          <Marker
            icon={iconsArray[(marker.day - 1) % 7]}
            label={{
              text: String(marker.sno),
              color: "#000000",
              fontWeight: "600",
            }}
            key={index}
            position={{ lat: marker.lat, lng: marker.lng }}
          >
            {marker.checkedIn && (
              <InfoWindow>
                <div style={{color: "black"}}>Checked in to {marker.address}</div>
              </InfoWindow>
            )}
          </Marker>
        );
      })}
      {directions &&
        directions.map((direction, index) => (
          <DirectionsRenderer
            key={index}
            options={{
              suppressMarkers: true,
            }}
            directions={direction}
          />
        ))}
      {flightDirections &&
        flightDirections.map((direction, index) => (
          <Polyline
            key={index}
            path={direction}
            options={{
              strokeOpacity: 0,
              icons: [
                {
                  icon: lineSymbol,
                  offset: "0",
                  repeat: "15px",
                },
              ],
            }}
          />
        ))}
    </GoogleMap>
  );
};

export default GoogleMapPure;
