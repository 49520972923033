import React from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import { ReactComponent as DocumentIcon } from '../../assets/document.svg';
import InputLabel from "@material-ui/core/InputLabel";
import { uid } from 'react-uid';
import { withStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/CancelOutlined";

const styles = () => ({
  photoUpload: {
    height: "100%",
    width: "100%",
    cursor: "pointer",
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0
  }
});

const readURL = (file, callback) => {
  let reader = new FileReader();
  reader.onload = callback;
  if (file) {
    reader.readAsDataURL(file);
  }
};

const adaptFileEventToValue = delegate => e => {
  e.persist();
  readURL(e.target.files[0], eve =>
    delegate({ data: eve.target.result, file: e.target.files[0] })
  );
};

const handleFileUpload = files => {
  const filePromises = [];
  Array.from(files).forEach((file, index) =>
    filePromises.push(
      new Promise((res) => {
        return readURL(file, eve =>
          res({
            data: eve.target.result,
            file: file,
            id: uid(file, index)
          })
        );
      })
    )
  );

  return Promise.all(filePromises).then(res => {
    return res;
  });
};

const multipleFiles = (e, value, onChange) => {
  if (e.target.files && e.target.files[0]) {
    let reader = new FileReader();
    const file = e.target.files[0];
    reader.readAsDataURL(file);
    handleFileUpload(e.target.files).then(res => {
      if (value && value.length > 0) {
        onChange(value.concat(res));
      } else {
        onChange(res);
      }
    });
  }
};

const FileInputField = ({
  input: { value, onChange },
  meta: { error, touched },
  multi,
  label,
  required,
  classes,
  style,
  withUpload,
  onUpload,
  clearable,
  ...rest
}) => {

  const handleUpload = (e, onChange) => {
    e.persist();
    readURL(e.target.files[0], eve => {
      onUpload({ data: eve.target.result, file: e.target.files[0] }, (url) => {
        onChange(url);
      });
    }
    );
  };

  return (
    <>
      {label && (
        <InputLabel required={required} error={error && touched ? true : false}>
          {label}
        </InputLabel>
      )}
      <input
        className={classes.photoUpload}
        style={style}
        onChange={
          withUpload ? e => handleUpload(e, onChange) :
            multi
              ? e => multipleFiles(e, value, onChange)
              : adaptFileEventToValue(onChange)
        }
        type="file"
        {...rest}
      />
      {clearable && value && (
        <span style={{ display: 'flex', alignItems: 'flex-end' }}>
          <DocumentIcon
            style={{
              height: '12px',
              width: '16px'
            }}
          />
          <CancelIcon
            style={{
              marginLeft: '5px',
              color: "red",
              cursor: "pointer",
              fontSize: '12px'
            }}
            onClick={() => onChange(null)}
          />
        </span>
      )}
      {error && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
};

export default withStyles(styles)(FileInputField);
