import React from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";

export const StyledDialog = styled(({ ...other }) => (
  <Dialog
    {...other}
    classes={{
      paper: "paperContainer",
    }}
    BackdropProps={{ classes: { root: "import-dialog-bg" } }}
  />
))`
  .dialog-title {
    background: ${(props) => props.theme.primaryBlack};

    .heading {
      font-weight: 600;
    }
  }

  .btn-creator {
    padding: 16px 24px 0 24px;
    display: flex;
    align-items: center;

    > span {
      margin-right: 5px;
    }
  }
  && .paperContainer {
    background: ${(props) => props.theme.appBar};
    border-radius: 15px;
    .import-new-root {
      padding: 0;
      opacity: 1 !important;
      color: ${(props) => props.theme.lightGreen} !important;
      text-transform: none;
      justify-content: flex-start;
      font-size: 1rem;
      font-weight: 400;
      .import-new {
        justify-content: flex-start;
        svg {
          margin-right: 10px;
        }
      }
    }

    .list-container {
      padding: 0px 24px 24px 24px;
      margin-top: 15px;
      max-height: 300px;
      overflow-y: auto;
      ${(props) => props.theme.breakpoints.down("sm")} {
        max-height: 100%;
      }
    }

    .existing-heading {
      margin-left: 40px;
    }
  }
`;

export const TripCardCotainer = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    border: ${(props) => `1px solid ${props.theme.secondaryColor}`};
    border-radius: 5px;
    flex-wrap: nowrap;
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 47px;
    align-items: center;
    padding: 0 30px;
    cursor: pointer;

    .trip-date,
    .trip-name {
      && {
        font-weight: 400;
        font-size: 1rem;
        color: ${(props) => props.theme.white};
      }
    }
  }

  &&:hover {
    background: ${(props) => props.theme.secondaryColor};
  }
`;
