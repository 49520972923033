import React from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { getPeople } from "./duck/actions";
import {
  FRIENDS_ID,
  GENERIC_ERROR_MESSAGE,
  NO_CONTENT_FOLLOWERS,
} from "../../constants";
import LoadingScreen from "../../common/LoadingScreen";
import NotFound from "../../common/NotFound";
import NoContent from "../../common/NoContent/pure";
import PeopleCard from "./PeopleCard";
import { uid } from "react-uid";

const Followers = (props) => {
  const {
    getFollowers,
    error,
    people,
    loading,
    isLast,
    pageno,
    search,
  } = props;

  const [isFetching, setIsFetching] = React.useState(false);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 390
    ) {
      setIsFetching(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, false);
    getFollowers(0);
    return () => window.removeEventListener("scroll", handleScroll, false);
  }, [getFollowers]);

  React.useEffect(() => {
    if (!isFetching || !people || isLast || search) return;
    getFollowers(pageno + 1, () => setIsFetching(false));
  }, [isFetching, people, isLast, search, getFollowers, pageno]);

  if (error) {
    return <NotFound errorMsg={GENERIC_ERROR_MESSAGE} />;
  }

  if (loading && !people) {
    return <LoadingScreen />;
  }

  if (people.length === 0) {
    return <NoContent heading={NO_CONTENT_FOLLOWERS} alignText="center" />;
  }

  return (
    <Grid container wrap="wrap" spacing={4}>
      {people.map((person, index) => (
        <Grid key={uid(person, index)} item md={4} xs={6}>
          <PeopleCard person={person} />
        </Grid>
      ))}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const {
    people: {
      followersError,
      followersLoading,
      followers,
      followersPageNo,
      followersIsLast,
      followersSearch,
    },
  } = state;

  return {
    error: followersError,
    loading: followersLoading,
    people: followers,
    isLast: followersIsLast,
    pageno: followersPageNo,
    search: followersSearch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFollowers: (page, callback) =>
      dispatch(getPeople(FRIENDS_ID, page, undefined, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Followers);
