import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";

export const TripHolder = styled(Grid)`
  && {
    background: ${(props) => props.theme.primaryBlack};
    box-sizing: border-box;
    border-radius: 15px;
    overflow: hidden;

    .trip-card-heading,
    .meta-container,
    .action-btn-container {
      padding: 5px 20px;
    }

    ${(props) => props.theme.breakpoints.down("xs")} {
      .trip-card-heading,
      .meta-container,
      .action-btn-container {
        padding: 5px;
      }
    }

    .action-btn-container {
      border-top: 1px solid rgba(255, 255, 255, 0.12);
    }
  }
`;

export const TripName = styled(Typography)`
  && {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ImageHolder = styled.img`
  && {
    height: 200px;
    width: 100%;
    object-fit: cover;

    ${(props) => props.theme.breakpoints.down("xs")} {
      max-height: 154px;
      height: 100%;
    }
  }
`;

export const StyledIconButton = styled(IconButton)`
  && {
    padding: 0;
  }
`;

export const StyledButton = styled(Button)`
  && {
    color: ${(props) =>
      props.isGreen === 1 ? props.theme.lightGreen : props.theme.primaryColor};
    font-weight: 600;
    min-width: auto;
    font-size: 1rem;
    white-space: nowrap;
    padding: 0px;
    letter-spacing: 0.05em;
    line-height: 1;
    &:hover {
      background-color: transparent;
    }
  }
`;

export const StyledLink = styled(Link)`
  && {
  }
`;

export const DeleteItem = styled(ListItem)`
  && {
    color: black;
    cursor: pointer;
    font-family: Poppins, sans-serif;
    font-size: 14px;
  }
`;

export const StyledDate = styled(Typography)`
  text-align: right;
  font-size: 12px !important;
  position: relative;
  ${(props) => props.theme.breakpoints.down("xs")} {
    font-size: 8px !important;
  }
`;

export const StyledText = styled(Typography)`
  && {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: "Work Sans", sans-serif;
    font-weight: 900;
    svg {
      min-height: 20px;
      min-width: 20px;
      height: 20px;
      width: 20px;
    }
  }
`;

export const ActionGrid = styled(Grid)``;

export const StyledUserName = styled(Typography)`
  && {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
    color: ${(props) => props.theme.lightGreen};
  }
`;

export const StyledName = styled(Typography)`
  && {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
    font-weight: 900;
  }
`;
