import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Hidden from "@material-ui/core/Hidden";
import MoreHorzIcon from "@material-ui/icons/MoreHoriz";
import {
  TripHolder,
  ImageHolder,
  TripName,
  StyledButton,
  StyledLink,
  StyledName,
  ActionGrid,
  StyledUserName,
} from "./styles";
import defaultPic from "../../../assets/default-trip-pic.png";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Link } from "react-router-dom";
import moment from "moment";
import List from "@material-ui/core/List";
import Tooltip from "@material-ui/core/Tooltip";
import { Typography } from "@material-ui/core";
import { StyledItem } from "../../Photos/styles";

const getNavLink = (type, id) => {
  if (type === "following") {
    return `/trip-explorer/${id}/following`;
  }
  return `/trip-explorer/${id}/community`;
};

const FriendTripCard = (props) => {
  const {
    trip: { name, from_dt, to_dt, pic, trip_local_cost },
    trip,
    handleImportPlan,
    type,
    currency,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, isOpen] = useState(false);

  const renderDateName = () => {
    return (
      <Grid className="trip-card-heading" item xs={12}>
        <Tooltip disableFocusListener title={name} placement="top">
          <TripName variant="h4">{name}</TripName>
        </Tooltip>
        <Typography variant="body2">
          <Grid item md={12}>
            <Grid container>
              <Grid item md={9}>
                {moment(from_dt).format("Do MMM")} -{" "}
                {moment(to_dt).format("Do MMM YY")}
              </Grid>
              <Grid item md={3} style={{ color: "#08E8DE" }}>
                {currency} {trip_local_cost ? trip_local_cost : "0"}
              </Grid>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    );
  };

  return (
    <TripHolder container wrap="wrap">
      <Hidden smDown>{renderDateName()}</Hidden>
      <Grid item sm={12} xs={4}>
        <ImageHolder src={pic || defaultPic} />
      </Grid>
      <Grid item sm={12} xs={8} container justify="space-between">
        <Hidden mdUp>{renderDateName()}</Hidden>
        <Grid
          className="meta-container"
          wrap="nowrap"
          spacing={2}
          item
          container
          alignItems="center"
        >
          <Grid item xs="auto">
            <Avatar
              component={Link}
              to={`/user/${trip.user_id}`}
              alt={name}
              src={trip.prof_pic}
            />
          </Grid>
          <Grid container item direction="column">
            <Grid item xs="auto">
              <StyledName
                variant="body1"
                component={Link}
                to={`/user/${trip.user_id}`}
              >
                {trip.first_name} {trip.last_name}
              </StyledName>
            </Grid>
            <Grid item xs="auto">
              <StyledUserName
                component={Link}
                to={`/user/${trip.user_id}`}
                variant="caption"
              >
                @{trip.user_name}
              </StyledUserName>
            </Grid>
          </Grid>
        </Grid>
        <ActionGrid
          container
          justify="space-between"
          item
          wrap="nowrap"
          alignItems="center"
          className="action-btn-container"
        >
          <Grid item>
            <StyledButton
              size="small"
              disableRipple
              isGreen={1}
              onClick={() => handleImportPlan()}
            >
              IMPORT PLAN
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton
              disableRipple
              size="small"
              component={Link}
              to={getNavLink(type, trip.trip_id)}
            >
              VIEW
            </StyledButton>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              aria-label="More"
              aria-owns={anchorEl ? "long-menu" : undefined}
              aria-haspopup="true"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                isOpen(true);
              }}
              size="small"
            >
              <MoreHorzIcon />
            </IconButton>
          </Grid>
        </ActionGrid>
      </Grid>
      <Grid item>
        <Popper
          id="long-menu"
          open={open}
          anchorEl={anchorEl}
          role={undefined}
          transition
          disablePortal
          style={{
            zIndex: 1,
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => isOpen(false)}>
                  <List>
                    <Hidden mdUp>
                      <StyledItem
                        onClick={() => handleImportPlan()}
                        component={StyledLink}
                      >
                        Import Plan
                      </StyledItem>
                    </Hidden>
                    <StyledItem
                      component={StyledLink}
                      to={getNavLink(type, trip.trip_id)}
                    >
                      View
                    </StyledItem>
                  </List>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </TripHolder>
  );
};

export default FriendTripCard;
