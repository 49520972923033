import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Grid from "@material-ui/core/Grid";
import { StyledImportHeading, StyledChip, ChipsContainer } from "./styles";
import { uid } from "react-uid";
import Close from "@material-ui/icons/Close";
import { getActivitySvg } from "../../../common/utils";

const ImportChips = (props) => {
  const { removeImportChip, importChips, loading } = props;

  const renderBody = () => {
    if (loading) {
      return "Loading...";
    }

    if (importChips.length > 0) {
      return (
        <Grid item xs>
          <Droppable
            type="activity"
            droppableId="activity-chips"
            isDropDisabled
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  minHeight: "62px",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {importChips.map((chip, index) => (
                  <Draggable
                    key={uid(chip, index)}
                    draggableId={`import-${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <StyledChip
                          icon={getActivitySvg(chip.activityType, true)}
                          label={chip.origin}
                          clickable
                          color="primary"
                          onDelete={() => removeImportChip(chip.chipIndex)}
                          deleteIcon={<Close />}
                          classes={{
                            label: "chip-label",
                            deleteIcon: "delete-icon",
                            root: "chip-root",
                            icon: "chip-icon",
                          }}
                          {...provided.dragHandleProps}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Grid>
      );
    }

    return null;
  };

  return (
    <ChipsContainer container>
      <Grid item xs="auto">
        <StyledImportHeading color="primary" variant="body2">
          Imports:
        </StyledImportHeading>
      </Grid>
      {renderBody()}
    </ChipsContainer>
  );
};

export default ImportChips;
