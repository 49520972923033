import React, { useEffect } from "react";
import ProfileCard from "./profileCardFields";
import { StyledTab, StyledTabs } from "../../styles";
import PersonFormFields from "./personalFormFields";
import AccountFormFields from "./accountFormField";
import { StyledForm } from "./styles";
import LoadingButton from "../../../common/LoadingButton";
import { Link } from "react-router-dom";
import { getCurrencyApi } from "../services";

const EditProfileForm = (props) => {
  const { onChange, tabIndex, handleSubmit, values, auth, submitting } = props;
  const [currency, setCurrency] = React.useState([]);

  useEffect(() => {
    getCurrencyApi()
      .then((res) => setCurrency(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <StyledForm onSubmit={handleSubmit}>
      <ProfileCard auth={auth} values={values} />
      <StyledTabs value={tabIndex} onChange={onChange} color="secondary">
        <StyledTab label="Personal" />
        <StyledTab label="Account" />
      </StyledTabs>
      <div className="fields-container">
        {tabIndex === 0 && <PersonFormFields currency={currency} />}
        {tabIndex === 1 && <AccountFormFields />}
      </div>
      <div className="action-btns">
        <LoadingButton
          submitting={submitting}
          type="submit"
          variant="contained"
          color="secondary"
        >
          Save Changes
        </LoadingButton>
        <LoadingButton
          variant="text"
          color="primary"
          component={Link}
          to="/profile"
          // onClick={() => reset()}
        >
          Cancel
        </LoadingButton>
      </div>
    </StyledForm>
  );
};

export default EditProfileForm;
