import { Grid, TextField, Typography } from "@material-ui/core";

import React, { useEffect, useState } from "react";
import CreateIcon from "@material-ui/icons/Create";
import {
  StyledButton,
  NotesHeader,
  NotesContainer,
  NotesBody,
  NotesActionContainer,
  CustomIconButton,
} from "./styles";

const Notes = (props) => {
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [onNoteChange, setOnNoteChange] = useState("");
  const [note, setNote] = useState(props.activity?.note);
  const { activity, handleActivityNote, day } = props;

  useEffect(() => {
    if (isNotesOpen) setOnNoteChange(note);
  }, [isNotesOpen, note]);

  const onchangeHandler = (event) => {
    if (event.target.value.length > 280) {
      return;
    }
    setOnNoteChange(event.target.value);
  };

  const handleNotesSubmit = (e) => {
    const tempActivity = { ...activity, note: onNoteChange };
    handleActivityNote(tempActivity, day, onNoteChange);
    setIsNotesOpen(false);
    setOnNoteChange("");
    setNote(onNoteChange);
  };

  if (!isNotesOpen)
    return (
      <React.Fragment>
        <NotesBody item md={7}>
          <Typography variant="body2" align="left">
            {note}
          </Typography>
        </NotesBody>

        <Grid item md={3}>
          <CustomIconButton disableRipple>
            <CreateIcon onClick={() => setIsNotesOpen(true)} />
          </CustomIconButton>
        </Grid>
      </React.Fragment>
    );
  else
    return (
      <NotesContainer item md={10} sm={12} xs={12}>
        <Grid container>
          <Grid item md={9} sm={12} xs={12}>
            <NotesHeader>
              <small>Notes</small>
              <small>
                ({onNoteChange?.length ? onNoteChange.length : 0}/280)
              </small>
            </NotesHeader>

            <TextField
              value={onNoteChange}
              fullWidth={true}
              onChange={onchangeHandler}
              style={{ padding: "0px 10px", fontSize: "12px" }}
            ></TextField>
          </Grid>
          <NotesActionContainer item md={3} sm={12} xs={12}>
            <StyledButton isGreen={1} onClick={handleNotesSubmit}>
              SAVE
            </StyledButton>
            <StyledButton isGreen={1} onClick={() => setIsNotesOpen(false)}>
              DISCARD
            </StyledButton>
          </NotesActionContainer>
        </Grid>
      </NotesContainer>
    );
};

export default Notes;
