import React from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import {
  FOLLOWING,
  FOLLOW,
  MY_ID,
  FRIENDS_ID,
  COMMUNITY_ID,
  PRIVACY_OPTIONS,
  REQUESTED,
} from "../constants";

import { ReactComponent as FlightIcon } from "../assets/flight.svg";
import { ReactComponent as FoodIcon } from "../assets/food.svg";
import { ReactComponent as PlacesIcon } from "../assets/places.svg";
import { ReactComponent as HotelIcon } from "../assets/hotel.svg";
import { ReactComponent as RailIcon } from "../assets/rail.svg";
import { ReactComponent as Eventcon } from "../assets/event.svg";

import { ReactComponent as Flight } from "../assets/planner/flight.svg";
import { ReactComponent as Food } from "../assets/planner/food.svg";
import { ReactComponent as Places } from "../assets/planner/places.svg";
import { ReactComponent as Hotel } from "../assets/planner/hotel.svg";
import { ReactComponent as Rail } from "../assets/planner/rail.svg";
import { ReactComponent as Event } from "../assets/planner/event.svg";

const findCity = (addressComponents) => {
  let country = "";
  for (let i = 0; i < addressComponents.length; i++) {
    // if (addressComponents[i].types.includes("administrative_area_level_2")) {
    //   cityName =
    //     addressComponents[i].long_name || addressComponents[i].short_name;
    // }
    // if (addressComponents[i].types.includes("administrative_area_level_1")) {
    //   stateName =
    //     addressComponents[i].long_name || addressComponents[i].short_name;
    // }

    if (addressComponents[i].types.includes("country")) {
      country =
        addressComponents[i].long_name || addressComponents[i].short_name;
    }
  }
  return country;
};

export const createMarkersOld = (tripValues) => {
  let markers = [];
  if (tripValues && tripValues.plan) {
    tripValues.plan.forEach((day, index) => {
      if (
        day &&
        day.startingplace &&
        day.startingplace.lat &&
        day.startingplace.lng
      ) {
        markers.push({
          sno: index + 1,
          day: index + 1,
          id: day.id || null,
          ...day.startingplace,
        });
        if (day.activities) {
          day.activities.forEach((activity, activityIndex) => {
            if (
              activity &&
              activity.activityname &&
              activity.activityname.lat &&
              activity.activityname.lng
            ) {
              markers.push({
                sno: `${index + 1}${(activityIndex + 10).toString(36)}`,
                day: index + 1,
                ...activity.activityname,
                activityId: activity.activityname.id,
              });
            }
            if (activity && activity.to && activity.to.lat && activity.to.lng) {
              markers.push({
                sno: `${index + 1}${(activityIndex + 10).toString(36)}`,
                day: index + 1,
                ...activity.to,
                activityId: activity.to.id,
              });
            }
          });
        }
      }
    });
  }
  return markers;
};

export const createMarkers = (tripValues) => {
  let markerTypes = 0;
  let newMarkers = {};
  let markers = [];
  if (tripValues && tripValues.plan) {
    tripValues.plan.forEach((day, index) => {
      if (
        day &&
        day.startingplace &&
        day.startingplace.lat &&
        day.startingplace.lng
      ) {
        markers.push({
          sno: index + 1,
          day: index + 1,
          id: day.id || null,
          ...day.startingplace,
        });
        if (!newMarkers[`marker-${markerTypes}`]) {
          newMarkers[`marker-${markerTypes}`] = [];
        }
        newMarkers[`marker-${markerTypes}`].push({
          sno: index + 1,
          day: index + 1,
          id: day.id || null,
          ...day.startingplace,
        });
        if (day.activities) {
          day.activities.forEach((activity, activityIndex) => {
            if (
              activity &&
              activity.activityname &&
              activity.activityname.lat &&
              activity.activityname.lng
            ) {
              markers.push({
                sno: `${index + 1}${(activityIndex + 10).toString(36)}`,
                day: index + 1,
                ...activity.activityname,
                activityId: activity.activityname.id,
              });
              if (!newMarkers[`marker-${markerTypes}`]) {
                newMarkers[`marker-${markerTypes}`] = [];
              }
              if (activity.activitytype === "flight") {
                // add flight starting pos to previous list of markers
                newMarkers[`marker-${markerTypes}`].push({
                  sno: `${index + 1}${(activityIndex + 10).toString(36)}`,
                  day: index + 1,
                  ...activity.activityname,
                  activityId: activity.activityname.id,
                });
                markerTypes++;
                if (!newMarkers[`marker-${markerTypes}`]) {
                  newMarkers[`marker-${markerTypes}`] = [];
                }
                newMarkers[`marker-${markerTypes}`].push({
                  sno: `${index + 1}${(activityIndex + 10).toString(36)}`,
                  day: index + 1,
                  ...activity.activityname,
                  activityId: activity.activityname.id,
                  type: "flight",
                });
                if (
                  activity &&
                  activity.to &&
                  activity.to.lat &&
                  activity.to.lng
                ) {
                  newMarkers[`marker-${markerTypes}`].push({
                    sno: `${index + 1}${(activityIndex + 10).toString(36)}`,
                    day: index + 1,
                    ...activity.to,
                    activityId: activity.to.id,
                    type: "flight",
                  });
                  markers.push({
                    sno: `${index + 1}${(activityIndex + 10).toString(36)}`,
                    day: index + 1,
                    ...activity.to,
                    activityId: activity.to.id,
                  });
                }
                markerTypes++;
                // pust the flight ending location to next list of markers
                if (!newMarkers[`marker-${markerTypes}`]) {
                  newMarkers[`marker-${markerTypes}`] = [];
                }
                newMarkers[`marker-${markerTypes}`].push({
                  sno: `${index + 1}${(activityIndex + 10).toString(36)}`,
                  day: index + 1,
                  ...activity.to,
                  activityId:
                    activity.to && activity.to.id ? activity.to.id : "",
                });
              } else {
                newMarkers[`marker-${markerTypes}`].push({
                  sno: `${index + 1}${(activityIndex + 10).toString(36)}`,
                  day: index + 1,
                  ...activity.activityname,
                  activityId: activity.activityname.id,
                });
              }
            }
          });
        }
      }
    });
  }

  return { grouped: newMarkers, ungrouped: markers };
};

export const getLatLngAndCity = (address) => {
  return geocodeByAddress(address).then((results) =>
    getLatLng(results[0])
      .then((latLng) => {
        const city = findCity(results[0].address_components);
        return Object.assign({ address: address, city: city }, latLng);
      })
      .catch((error) => console.error("Error", error))
  );
};

export const getDirections = (markers) => {
  let waypoints = [];
  if (markers.length > 2) {
    for (let i = 1; i < markers.length - 1; i++) {
      waypoints.push({
        location: { lat: markers[i].lat, lng: markers[i].lng },
      });
    }
  }
  const DirectionsService = new window.google.maps.DirectionsService();
  return new Promise((resolve, reject) => {
    DirectionsService.route(
      {
        origin: { lat: markers[0].lat, lng: markers[0].lng },
        destination: {
          lat: markers[markers.length - 1].lat,
          lng: markers[markers.length - 1].lng,
        },
        travelMode: "DRIVING",
        waypoints: waypoints,
      },
      (result, status) => {
        if (status === "OK") {
          return resolve(result);
        } else {
          return reject("error");
        }
      }
    );
  });
};

export const isEqual = function (value, other) {
  // Get the value type
  var type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  var valueLen =
    type === "[object Array]" ? value.length : Object.keys(value).length;
  var otherLen =
    type === "[object Array]" ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  var compare = function (item1, item2) {
    // Get the object type
    var itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === "[object Function]") {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }
    }
  };

  // Compare properties
  if (type === "[object Array]") {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
};

// move elements of an array
export const ArrayMove = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
};

export const DayColors = [
  "#E9007F",
  "#7CDFFF",
  "#C355F5",
  "#FF3E6D",
  "#FFD615",
  "#FF4C91",
  "#A8FF3E",
  "#FFFDAF",
  "#FF00C8",
  "#837DFF",
  "#FF9E74",
  "#08FFC8",
];

export const ActivityColors = [
  "#E9007F",
  "#7CDFFF",
  "#C355F5",
  "#FF3E6D",
  "#FFD615",
  "#FF4C91",
  "#A8FF3E",
  "#FFFDAF",
  "#FF00C8",
  "#837DFF",
  "#FF9E74",
  "#08FFC8",
];

// toBlob polyfill
if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
    value(callback, type, quality) {
      const dataURL = this.toDataURL(type, quality).split(",")[1];
      setTimeout(() => {
        const binStr = atob(dataURL);
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i += 1) {
          arr[i] = binStr.charCodeAt(i);
        }
        callback(new Blob([arr], { type: type || "image/png" }));
      });
    },
  });
}

export const compress = async (file, width) => {
  const reader = new FileReader();
  const srcOrientation = await getOrientation(file);
  reader.readAsDataURL(file);
  return new Promise((resolve, reject) => {
    reader.onload = (ev) => {
      const img = new Image();
      img.src = ev.target.result;
      // eslint-disable-next-line no-unused-expressions
      img.onload = () => {
        const elem = document.createElement("canvas");
        const scaleFactor = width / img.width;
        elem.width = width;
        const height = img.height * scaleFactor;
        const ctx = elem.getContext("2d");

        if (4 < srcOrientation && srcOrientation < 9) {
          elem.width = height;
          elem.height = width;
        } else {
          elem.width = width;
          elem.height = height;
        }

        // transform context before drawing image
        switch (srcOrientation) {
          case 2:
            ctx.transform(-1, 0, 0, 1, width, 0);
            break;
          case 3:
            ctx.transform(-1, 0, 0, -1, width, height);
            break;
          case 4:
            ctx.transform(1, 0, 0, -1, 0, height);
            break;
          case 5:
            ctx.transform(0, 1, 1, 0, 0, 0);
            break;
          case 6:
            ctx.transform(0, 1, -1, 0, height, 0);
            break;
          case 7:
            ctx.transform(0, -1, -1, 0, height, width);
            break;
          case 8:
            ctx.transform(0, -1, 1, 0, 0, width);
            break;
          default:
            break;
        }

        ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);

        ctx.canvas.toBlob(
          (blob) => {
            resolve(
              new File([blob], file.name, {
                type: "image/jpeg",
                lastModified: Date.now(),
              })
            );
          },
          "image/jpeg",
          1
        );
      };
    };
    reader.onerror = (error) => reject(error);
  });
};

const getOrientation = (file) => {
  var reader = new FileReader();
  reader.readAsArrayBuffer(file);
  return new Promise((resolve, reject) => {
    reader.onload = function (e) {
      var view = new DataView(e.target.result);
      if (view.getUint16(0, false) !== 0xffd8) {
        return resolve(-2);
      }
      var length = view.byteLength,
        offset = 2;
      while (offset < length) {
        if (view.getUint16(offset + 2, false) <= 8) return resolve(-1);
        var marker = view.getUint16(offset, false);
        offset += 2;
        if (marker === 0xffe1) {
          if (view.getUint32((offset += 2), false) !== 0x45786966) {
            return resolve(-1);
          }

          var little = view.getUint16((offset += 6), false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          var tags = view.getUint16(offset, little);
          offset += 2;
          for (var i = 0; i < tags; i++) {
            if (view.getUint16(offset + i * 12, little) === 0x0112) {
              return resolve(view.getUint16(offset + i * 12 + 8, little));
            }
          }
        } else if ((marker & 0xff00) !== 0xff00) {
          break;
        } else {
          offset += view.getUint16(offset, false);
        }
      }
      return resolve(-1);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const renderPlural = (count, singular, plural) => {
  if (count === 1) {
    return singular;
  }

  return plural;
};

export const getFollowStatus = (isFollowing, isRequested) => {
  if (isRequested) {
    return REQUESTED;
  }
  if (isFollowing) {
    return FOLLOWING;
  }
  return FOLLOW;
};

export const getName = (person) =>
  `${person.first_name || ""} ${person.last_name || ""}`;

export const getType = (tabIndex) => {
  if (tabIndex === 0) {
    return MY_ID;
  }

  if (tabIndex === 1) {
    return FRIENDS_ID;
  }

  return COMMUNITY_ID;
};

export const getPrivacyIconAndLabel = (type) => {
  const option = PRIVACY_OPTIONS.filter((opt) => opt.value === type)[0];
  return option;
};

export const getDuration = (formValues, duration) => {
  const drivingTime = {};
  let durationIndex = 0;
  const { plan } = formValues || { plan: [] };
  (plan || []).forEach((day, index) => {
    const dayKey = `day-${index}`;
    const activities = day.activities || [];
    if (!drivingTime[dayKey]) {
      drivingTime[dayKey] = {};
    }
    if (duration[durationIndex]) {
      drivingTime[dayKey][dayKey] = duration[durationIndex].duration.text;
    }
    activities.forEach((activity, activeIndex) => {
      durationIndex++;
      const activityKey = `activity-${activeIndex}`;
      if (
        activity.activitytype === "rail" ||
        activity.activitytype === "flight"
      ) {
        durationIndex++;
      }
      if (
        duration[durationIndex] &&
        activity.activityname &&
        activity.activityname.address &&
        ((activities[activeIndex + 1] &&
          activities[activeIndex + 1].activityname &&
          activities[activeIndex + 1].activityname.address) ||
          activeIndex === activities.length - 1)
      ) {
        drivingTime[dayKey][activityKey] =
          duration[durationIndex].duration.text;
      }
    });
    durationIndex++;
  });
  return drivingTime;
};

export const getDurationExplorer = (trip, duration) => {
  const drivingTime = {};
  let durationIndex = 0;

  trip.forEach((day, index) => {
    const dayKey = `day-${index}`;
    const activities = day.activitydata || [];
    if (!drivingTime[dayKey]) {
      drivingTime[dayKey] = {};
    }
    if (duration && duration[durationIndex]) {
      drivingTime[dayKey][dayKey] = duration[durationIndex].duration.text;
    }
    activities.forEach((activity, activeIndex) => {
      durationIndex++;
      const activityKey = `activity-${activeIndex}`;
      if (activity.type === "rail" || activity.type === "flight") {
        durationIndex++;
      }
      if (duration && duration[durationIndex]) {
        drivingTime[dayKey][activityKey] =
          duration[durationIndex].duration.text;
      }
    });
    durationIndex++;
  });
  return drivingTime;
};

export const getActivityIcon = (type, returnNull) => {
  if (type === "restaurant") {
    return FoodIcon;
  }

  if (type === "event") {
    return Eventcon;
  }

  if (type === "places") {
    return PlacesIcon;
  }

  if (type === "hotel") {
    return HotelIcon;
  }

  if (type === "rail") {
    return RailIcon;
  }

  if (type === "flight") {
    return FlightIcon;
  }

  if (returnNull) {
    return null;
  }

  return FlightIcon;
};

export const getImportActivity = (activity) => {
  const importActivity = {
    city: activity.city,
    lat: activity.lat,
    longi: activity.long,
    location: activity.place_from,
    activity_type: activity.type,
  };

  if (activity.place_to) {
    importActivity.location_to = activity.place_to;
  }
  if (activity.place_to_city) {
    importActivity.city_to = activity.place_to_city;
  }
  if (activity.place_to_lat) {
    importActivity.lat_to = activity.place_to_lat;
  }
  if (activity.place_to_long) {
    importActivity.longi_to = activity.place_to_long;
  }

  return importActivity;
};

export const activityImportChip = (tripToImport) => {
  const activity = {};
  activity.city = tripToImport.city;
  activity.lat = tripToImport.lat;
  activity.longi = tripToImport.longi;
  activity.origin = tripToImport.location;
  activity.type = "1";
  activity.activityType = tripToImport.activity_type;
  activity.locationTo = tripToImport.location_to;
  activity.cityTo = tripToImport.city_to;
  activity.latTo = tripToImport.lat_to;
  activity.longiTo = tripToImport.longi_to;
  return activity;
};

export const getActivitySvg = (type, returnNull) => {
  if (type === "restaurant") {
    return <Food />;
  }

  if (type === "event") {
    return <Event />;
  }

  if (type === "places") {
    return <Places />;
  }

  if (type === "hotel") {
    return <Hotel />;
  }

  if (type === "rail") {
    return <Rail />;
  }

  if (type === "flight") {
    return <Flight />;
  }

  if (returnNull) {
    return null;
  }

  return <FlightIcon />;
  // return <Flight />;

};
