import React from "react";
import { connect } from "react-redux";
import { ReactComponent as NotificationsIcon } from "../../../assets/profile/notification.svg";
import Badge from "@material-ui/core/Badge";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {
  getUnreadCount,
  getNotifications,
  readNotifications,
  handleRequest,
} from "./duck/actions";
import LoadingScreen from "../../../common/LoadingScreen";
import { NotificationDrawerHeading, StyledDrawer } from "./styles";
import Notification from "./Notification";

const renderNotifications = (
  loading,
  notifications,
  requestHandler,
  closeDrawer
) => {
  if (loading) {
    return <LoadingScreen inline />;
  }

  return (
    <div className="notifications-container">
      {notifications.map((notification) => {
        return (
          <Notification
            closeDrawer={closeDrawer}
            {...notification}
            requestHandler={requestHandler}
            key={notification.noti_id}
          />
        );
      })}
    </div>
  );
};

const NotificationDrawer = (props) => {
  const {
    getUnreadCount,
    notifications: { unreadCount, loading, notifications },
    getNotifications,
    readNotifications,
    handleRequest,
  } = props;
  const [isOpen, setOpen] = React.useState(false);

  React.useEffect(() => {
    getUnreadCount();
    setInterval(() => {
      getUnreadCount();
    }, 300000);
  }, [getUnreadCount]);

  React.useEffect(() => {
    if (isOpen) {
      getNotifications();
    }
  }, [getNotifications, isOpen]);

  React.useEffect(() => {
    if (isOpen && notifications.length) {
      const ids = notifications
        .filter((notification) => notification.status === 2)
        .map((notif) => notif.noti_id);
      readNotifications(ids);
    }
  }, [isOpen, notifications, readNotifications]);

  const requestHandler = (reqId, notId, userId, state) => {
    handleRequest(reqId, notId, userId, state);
  };

  return (
    <>
      <IconButton
        style={{ padding: 0 }}
        color="inherit"
        onClick={() => setOpen(true)}
      >
        <Badge badgeContent={unreadCount} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <StyledDrawer
        open={isOpen}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        anchor="right"
        classes={{
          paper: "notifications-body",
        }}
      >
        <div
          tabIndex={0}
          role="button"
          // onClick={() => setOpen(false)}
          // onKeyDown={() => setOpen(false)}
        >
          <NotificationDrawerHeading>
            <Typography color="secondary">MY NOTIFICATIONS</Typography>
            <IconButton color="primary" onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </NotificationDrawerHeading>
          {renderNotifications(loading, notifications, requestHandler, () =>
            setOpen(false)
          )}
        </div>
      </StyledDrawer>
    </>
  );
};

const mapStateToProps = (state) => {
  return { notifications: state.notifications };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUnreadCount: () => dispatch(getUnreadCount()),
    getNotifications: () => dispatch(getNotifications()),
    readNotifications: (ids) => dispatch(readNotifications(ids)),
    handleRequest: (id, notId, userId, state) =>
      dispatch(handleRequest(id, notId, userId, state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDrawer);
