import React from "react";
import { Field } from "redux-form";
import { Button, Typography } from "@material-ui/core";
import ReduxFormTextField from "../../common/FormFields/TextField";
import { required } from "../../common/FormFields/validations";
import ResponsiveDialog from "../../common/ResponsiveDialog";
import { OnboardingWrapper } from "../OnboardingWrapper";
import LoadingButton from "../../common/LoadingButton";

import "../index.scss";

const LoginComponent = (props) => {
  const {
    submitting,
    handleSubmit,
    isOpen,
    setIsOpen,
    setSignupOpen,
    setForgotOpen,
    reset,
  } = props;

  return (
    <ResponsiveDialog
      showActions={false}
      showTitle={false}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dialogClass="onboarding-dialog"
      contentClass="onboarding-modal-content"
      renderContent={() => {
        return (
          <OnboardingWrapper
            handleClose={() => {
              reset(LoginComponent);
              setIsOpen(false);
            }}
          >
            <div className="onboarding-main-content">
              <Typography gutterBottom variant="h4" color="secondary">
                Welcome Back, GET SET GO!
              </Typography>
              <form className="onboarding-form" onSubmit={handleSubmit}>
                <div className="form-item">
                  <Field
                    name="usernameEmail"
                    required
                    component={ReduxFormTextField}
                    label="Username or Email"
                    validate={required}
                  />
                </div>
                <div className="form-item">
                  <Field
                    name="password"
                    required
                    type={"password"}
                    component={ReduxFormTextField}
                    label="Password"
                    validate={required}
                  />
                </div>
                <div>
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    type={"submit"}
                    submitting={submitting}
                  >
                    Log in
                  </LoadingButton>
                </div>
              </form>
              <div className="onboarding-options">
                <Typography variant="caption">
                  Don't have an account ?
                </Typography>
                <Button
                  onClick={() => {
                    reset(LoginComponent);
                    setIsOpen(false);
                    setSignupOpen(true);
                  }}
                  color="secondary"
                  variant="text"
                >
                  Sign up
                </Button>
              </div>
              <div className="onboarding-options">
                <Typography variant="caption">Trouble logging in ?</Typography>
                <Button
                  onClick={() => {
                    reset(LoginComponent);
                    setIsOpen(false);
                    setForgotOpen(true);
                  }}
                  color="secondary"
                  variant="text"
                >
                  Forgot Password
                </Button>
              </div>
            </div>
          </OnboardingWrapper>
        );
      }}
    />
  );
};

export default LoginComponent;
