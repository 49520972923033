import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { compose, withHandlers } from "recompose";
import { login } from "../duck";

const loginSubmit = (values, dispatch, props) => {
  return dispatch(login(values, props.setIsOpen));
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const LoginHandlers = compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: "login-form",
    onSubmit: loginSubmit,
  }),
  withHandlers({
    handleGoogleResponse: (props) => (params) => {
      console.log("======google=======");
      console.log(params);
      console.log("======google=======");
    },
    handleFbResponse: (props) => (params) => {
      console.log("======FB=======");
      console.log(params);
      console.log("======FB=======");
    },
  })
);

export default LoginHandlers;
