import React from "react";
import { FieldArray } from "redux-form";
import DayFieldArray from "./DayFieldArray";
import { DragDropContext } from "react-beautiful-dnd";
import ImportChips from "./ImportChips";
// import { PaginationContainer, DayContainer } from "./styles";
// import { Typography } from "@material-ui/core";
// import moment from "moment";
// import { DayColors } from "../../../common/utils";

const PlanDetailsPure = (props) => {
  const {
    lat,
    lng,
    addActivity,
    formValues,
    handleRecomendationClick,
    handleDragEnd,
    classes,
    trip,
    drivingTime,
    importChips,
    removeImportChip,
    importTrip: { loading: importChipsLoading },
    auth: { token },
    auth,
    showSnackBar,
  } = props;

  const dayRef = React.useRef([]);

  // const handleDayClick = (day) => {
  //   dayRef.current[day - 1].scrollIntoView({
  //     behavior: "smooth",
  //     block: "start",
  //     inline: "nearest",
  //   });
  // };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {(importChipsLoading || importChips.length > 0) && (
        <ImportChips
          importChips={importChips}
          removeImportChip={removeImportChip}
          loading={importChipsLoading}
        />
      )}

      <div className="plan-form" autocomplete="off">
        {/* <PaginationContainer>
          {formValues?.plan?.map((data, index) => {
            return (
              <DayContainer
                day={index + 1}
                color={DayColors[index % DayColors.length]}
                onClick={() => handleDayClick(index + 1)}
              >
                <Typography variant="body1">Day {index + 1}</Typography>
                <Typography variant="body2">
                  {moment(trip.tripdata.from_dt)
                    .add(index, "day")
                    .format("ddd Do")}
                </Typography>
              </DayContainer>
            );
          })}
        </PaginationContainer> */}

        <FieldArray
          classes={classes}
          lat={lat}
          lng={lng}
          trip={trip}
          handleActivityNote={props.handleActivityNote}
          dayRef={dayRef}
          addActivity={addActivity}
          name={"plan"}
          formValues={formValues}
          auth={auth}
          component={DayFieldArray}
          handleRecomendationClick={handleRecomendationClick}
          drivingTime={drivingTime}
          token={token}
          showSnackBar={showSnackBar}
        />
      </div>
    </DragDropContext>
  );
};

export default PlanDetailsPure;
