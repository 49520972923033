import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export const AutoWidthGrid = styled(Grid)`
  && {
    width: auto;
  }
`;

export const ContentHeightGrid = styled(Grid)`
  && {
    height: min-content;
  }
`;

export const StyledGrid = styled(Grid)`
  && {
    flex: ${props => props.flex};
    position: ${props => props.position};
    padding: ${props => props.padding};
    margin: ${props => props.margin};
    min-height: ${props => props.minheight};
    align-self: ${props => props.alignself};
    ${props => props.theme.breakpoints.down("sm")} {
      padding: ${props => props.respadding};
    }
  }
`;

export const SmallHeading = styled(Typography)`
  && {
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0.004em;
    color: rgba(0, 0, 0, 0.8);
  }
`;

export const ButtonText = styled(Typography)`
  && {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
    text-decoration: none;
  }
`;

// export const SmallHeading = styled(Typography)`
//   font-family: Open Sans;
//   font-style: normal;
//   font-weight: 300;
//   line-height: normal;
//   font-size: 10px;
//   color: #000000;
//   text-decoration: none;
// `;
