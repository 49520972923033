import React, { useState } from "react";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { StyledTabs, StyledTab } from "../styles";
import Following from "./Following";
import Followers from "./Followers";
import Community from "./Community";
import SearchBar from "../../common/SearchBar";
import ComponentWrapper from "../ComponentWrapper";
import { getType } from "../../common/utils";
import { getPeople } from "./duck";

const People = ({ getPeople }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [isSearchVisible, setSearchVisible] = useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSearch = (val) => {
    setSearchVal(val);
    getPeople(getType(tabIndex), val);
  };

  const handleTabChange = (e, index) => {
    setTabIndex(index);
    setSearchVal("");
  };

  return (
    <>
      <StyledTabs
        value={tabIndex}
        onChange={handleTabChange}
        isSearchVisible={isSearchVisible ? 1 : 0}
      >
        {!isSearchVisible && <StyledTab label="Following" disableRipple />}
        {!isSearchVisible && <StyledTab label="Followers" disableRipple />}
        {!isSearchVisible && <StyledTab label="Community" disableRipple />}
        {!isSearchVisible && (
          <StyledTab
            className="search-bar search-bar-desktop"
            disabled
            label={
              <SearchBar
                onChange={handleSearch}
                initialSearchValue={searchVal}
                label="Search People"
              />
            }
          />
        )}
        <StyledTab
          className="search-bar search-bar-mobile"
          disabled
          label={
            <div className="search-container">
              {!isSearchVisible && (
                <SearchIcon onClick={() => setSearchVisible(true)} />
              )}
              {isSearchVisible && (
                <span>
                  <SearchBar
                    onChange={handleSearch}
                    initialSearchValue={searchVal}
                    label="Search People"
                  />
                  <ClearIcon
                    color="primary"
                    onClick={() => setSearchVisible(false)}
                  />
                </span>
              )}
            </div>
          }
        />
      </StyledTabs>
      <ComponentWrapper item xs={12}>
        {tabIndex === 0 && <Following />}
        {tabIndex === 1 && <Followers />}
        {tabIndex === 2 && <Community />}
      </ComponentWrapper>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPeople: (type, name) => dispatch(getPeople(type, 0, name)),
  };
};

export default connect(null, mapDispatchToProps)(People);
