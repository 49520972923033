import React from "react";
import styled from "styled-components";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Fab from "@material-ui/core/Fab";
import { Button } from "@material-ui/core";

export const StyledTabs = styled(({ ...other }) => (
  <Tabs
    {...other}
    classes={{
      indicator: "indicator",
      flexContainer: "flexContainer",
      root: "tabs-root",
    }}
  />
))`
  position: sticky;
  top: 80px;
  z-index: 999;
  margin-bottom: 20px;

  padding: 0 10%;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 0 16px;
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    top: 148px;
    background: ${(props) => props.theme.primaryBlack};
  }

  background: ${(props) => props.theme.appBar};

  & .search-bar {
    opacity: 1 !important;
    pointer-events: all !important;
  }

  .search-bar-desktop {
    ${(props) => props.theme.breakpoints.down("sm")} {
      display: none;
    }
  }

  .search-bar-mobile {
    ${(props) => props.theme.breakpoints.up("md")} {
      display: none;
    }

    .search-container {
      display: flex;
    }
  }

  & .indicator {
    top: 0;
    height: 5px;
    display: ${(props) => (props.isSearchVisible === 1 ? "none" : "block")};
    ${(props) => props.theme.breakpoints.down("sm")} {
      top: unset;
      bottom: 0;
      height: 2px;
    }
  }

  & .flexContainer {
    justify-content: ${(props) =>
      props.isSearchVisible === 1 ? "center" : "space-between"};
    height: 48px;
    align-items: center;
    color: ${(props) => props.theme.primaryColor};
    opacity: 1;
    .selected {
      color: ${(props) => props.theme.secondaryColor};
    }
  }
`;

export const StyledTab = styled(({ ...other }) => (
  <Tab
    {...other}
    classes={{
      selected: "selected",
    }}
  />
))`
  && {
    min-height: 0 !important;
    font-size: 1.25rem;
    opacity: 1;
    min-width: auto;
    font-family: "Work Sans", sans-serif;
    text-transform: capitalize;
    > span {
      > span {
        padding: 0;
        text-align: center;
      }
    }
  }
  &.selected {
    font-weight: 600;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      padding: 0;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      padding: 0 20px;
      font-size: 1.25rem;
      > span {
        > span {
          text-align: center;
        }
      }
    }
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    && {
      min-width: auto;
      padding: 0;
    }
  }
`;

export const StyledAside = styled.aside`
  box-sizing: border-box;
  position: fixed;
  z-index: 999;
  width: 19rem;
  min-width: 19rem;
  height: calc(100vh - 80px);
  left: 0;
  top: 80px;
  overflow-y: auto;
  background-color: ${(props) => props.theme.appBar};
  border-right: 0.5px solid rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;

  .profile-tabs {
    margin-right: 1px;
    a {
      width: 100%;
      color: ${(props) => props.theme.secondaryWhite};
      letter-spacing: 0.015em;
      font-size: 1.25rem;
      border-radius: 0;
      padding: 6px 30px;
      font-weight: 400;
      text-transform: capitalize;
      span {
        display: flex;
        align-items: center;
        justify-content: normal;
        svg {
          min-height: 22px;
          max-height: 22px;
          max-width: 22px;
          min-width: 22px;
          margin-right: 8%;
        }
      }

      ${(props) => props.theme.breakpoints.down("sm")} {
        padding: 6px 15px;
      }
    }

    > a:hover {
      background: ${(props) => props.theme.secondaryLigt};
      font-weight: 600;
    }

    a.selected {
      color: ${(props) => props.theme.secondaryColor};
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.12),
          rgba(255, 255, 255, 0.12)
        ),
        #050505;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12);
      border-radius: 1px 5px 5px 1px;
      font-weight: 600;
      svg {
        path {
          fill: ${(props) => props.theme.secondaryColor};
        }
      }
    }

    a.selected {
      .selected-border {
        border-radius: 1px;
        border: ${(props) => `2px solid ${props.theme.secondaryColor}`};
        position: absolute;
        left: 0;
        height: 100%;
      }
    }
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    display: none;
  }
`;

export const RightPane = styled.div`
  margin-left: 19rem;
  margin-top: 80px;
  ${(props) => props.theme.breakpoints.up("sm")} {
    .profile-card {
      display: none;
    }
  }
  ${(props) => props.theme.breakpoints.down("xs")} {
    margin-bottom: 56px;
    margin-left: 0;
  }
`;

export const BottomTabs = styled.nav`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-around;
  background-color: ${(props) => props.theme.appBar};
  border-top: 0.5px solid rgba(255, 255, 255, 0.5);
  a {
    color: ${(props) => props.theme.white};
    span {
      display: flex;
      flex-direction: column;
    }
    svg {
      path {
        fill: ${(props) => props.theme.white};
      }
    }
  }

  a.selected {
    color: ${(props) => props.theme.secondaryColor};
    svg {
      path {
        fill: ${(props) => props.theme.secondaryColor};
      }
    }
  }

  ${(props) => props.theme.breakpoints.up("sm")} {
    display: none;
  }
`;

export const RouteContainer = styled.div``;

export const StyledImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 100px;
  object-fit: cover;
  box-sizing: border-box;
`;

export const ProfileContainer = styled.div``;

export const ComponentContainer = styled.div`
  background: ${(props) => props.theme.appBar};

  padding: 0 10%;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 0 16px;
  }
`;

export const StyledActionBtn = styled.div``;

export const StyledFab = styled(Fab)`
  && {
    min-height: 3rem;
    min-width: 3rem;
    max-height: 3rem;
    max-width: 3rem;
    position: fixed;
    right: 10px;
    bottom: 80px;
    z-index: 1;
    background: linear-gradient(155.12deg, #ef3b36 14.23%, #f16529 84.71%);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.12),
      0px 4px 5px rgba(0, 0, 0, 0.14);

    svg {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
`;

export const StyledButton = styled(Button)`
  white-space: nowrap;
`;
