import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export const StyledImg = styled.img`
  && {
    width: 100%;
    max-height: 240px;
    min-height: 240px;
    height: 100%;
    box-sizing: border-box;
    object-fit: cover;
    cursor: pointer;
    overflow: hidden;
  }
`;

export const StyledPhotoCard = styled(Grid)`
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: 0px 5px 16px rgba(18, 42, 76, 0.2);
  box-sizing: border-box;
  overflow: hidden;

  .user-heading,
  .action-btns {
    padding: 10px 20px;
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    .user-heading,
    .action-btns {
      padding: 5px;
    }
  }

  .user-heading {
    background: ${(props) => props.theme.primaryBlack} !important;
    width: 100%;
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
      min-width: 32px;
      min-height: 32px;
      max-height: 32px;
      max-width: 32px;
      border-radius: 100%;
    }

    .username {
      font-size: 1.25rem;
      text-decoration: none;
      font-weight: 900;
      color: ${(props) => props.theme.white};
    }

    > div {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
  }

  .action-btns {
    background: ${(props) => props.theme.primaryBlack} !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img,
    .like-btn {
      cursor: pointer;
    }

    .likes-count {
      font-size: 1.25rem;
      margin-left: 10px;
      font-weight: 900;
      color: ${(props) => props.theme.lightGreen};
    }
    .like-btn.liked {
      path {
        fill: ${(props) => props.theme.secondaryColor};
      }
    }

    .like-btn {
      path {
        fill: ${(props) => props.theme.lightGreen};
      }
    }
  }
`;
export const ActivityName = styled(Typography)`
  && {
    padding: 0 0 0 5%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;