import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Link from "@material-ui/core/Link";

export const DayPointer = styled(Grid)`
  background: ${(props) => props.color};
  max-width: 75px;
  min-width: 75px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    max-width: 55px;
    min-width: 55px;
  }
  margin-right: 20px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  white-space: nowrap;
  h6 {
    font-weight: 900;
    color: ${(props) => props.theme.appBar};
  }
  p {
    color: ${(props) => props.theme.appBar};
  }
`;

export const ActivityPointerCotainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 8px;
  min-width: 65px;
  max-width: 65px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    max-width: 45px;
    min-width: 45px;
  }
`;

export const ActivityPointer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h6 {
    color: ${(props) => props.color};
  }

  .icon-div {
    svg {
      min-height: 16px;
      min-width: 16px;
      height: 16px;
      width: 16px;
      fill: ${(props) => props.color};

      path {
        fill: ${(props) => props.color};
      }
    }
  }
  .activity-icon {
    height: 14px;
    width: 14px;
  }
`;

export const DayHeadingContainer = styled(Grid)``;

export const ActivityContainer = styled.div`
  padding: 10px 0 5px 0;
  .activity-content {
    padding: 10px;
    display: flex;
    flex-wrap: nowrap;
    background: ${(props) => props.theme.secondaryBlack};
  }
`;

export const VerticalDivider = styled.div`
  flex: 1;
  min-height: 56px;
  border-left: 1px solid #5460ec;
  margin: 15px 0px;
  opacity: 30%;
`;

export const DayDivider = styled.div`
  flex: 1;
  min-height: 32px;
  border-left: 1px solid #5460ec;
  margin: 15px 0px;
  opacity: 30%;
`;

export const EditButtonGrid = styled(Grid)`
  align-self: center;
  margin-left: 20px !important;
  button {
    padding: 0px;
  }
  svg {
    width: 14px;
    height: 14px;
  }
`;

export const ActivityFieldGrid = styled(Grid)`
  && {
    align-self: flex-start;
    margin-bottom: 10px;
  }
`;

export const DayFieldGrid = styled(Grid)`
  && {
    align-self: flex-end;
  }
`;

export const DayDeleteIconGrid = styled(Grid)`
  && {
    align-self: flex-end;
    button {
      padding: 0;
      svg {
        height: 20px;
        width: 16px;
      }
    }
  }
`;

export const DeleteIconGrid = styled(Grid)`
  && {
    margin-right: 10px;
    position: relative;
    display: flex;
    .activity-delete {
      padding: 0;
      span {
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
`;

export const AddActivityGrid = styled(Grid)`
  && {
    align-self: flex-end;
    .add-activity-btn {
      border: ${(props) => `1px solid  ${props.theme.lightGreen}`};
      color: ${(props) => `${props.theme.lightGreen}`};
      svg {
        margin-right: 10px;
      }
    }

    .add-activity-btn:hover {
      border: ${(props) => `1px solid  ${props.theme.lightGreen}`};
    }
  }
`;

export const ActivityGrid = styled(Grid)`
  && {
    ${(props) => props.theme.breakpoints.up("md")} {
      // flex-wrap: nowrap;
    }
  }
  .attachment {
    position: relative;
    margin-right: 10px;
    display: flex;
    svg {
      cursor: pointer;
      width: 16px;
      height: 20px;
    }
  }

  .recommendation {
    margin-right: 10px;
    display: flex;
    svg {
      cursor: pointer;
      width: 16px;
      height: 20px;
    }
  }

  .activity-type {
    top: 3px;
    position: relative;
    align-self: flex-start;
    height: 48px;
    display: flex;
    align-items: flex-end;
    img {
      width: 16px;
      height: 16px;
    }
  }
`;
export const BlackIcon = styled(AddIcon)`
  && {
    color: #fffff;
  }
`;

export const ActionImg = styled.img`
  margin-right: 6px;
`;

export const ActivityTextScreen = styled.span`
  ${(props) => props.theme.breakpoints.down(362)} {
    display: none;
  }
`;

export const ActivityTextSmallScreen = styled.span`
  display: none;
  ${(props) => props.theme.breakpoints.down(362)} {
    display: block;
  }
`;

export const AddDayButton = styled(Button)`
  && {
    position: absolute;
    top: 20px;
    right: 155px;
    border: ${(props) => `1px solid  ${props.theme.lightGreen}`};
    color: ${(props) => `${props.theme.lightGreen}`};
    svg {
      margin-right: 10px;
    }
    ${(props) => props.theme.breakpoints.down("xs")} {
      right: 5px;
    }
  }
  &&:hover {
    border: ${(props) => `1px solid  ${props.theme.lightGreen}`};
  }
`;

export const DrivingTime = styled(Typography)`
  && {
    font-size: 8px;
    white-space: nowrap;
    margin-left: 10px;
  }
`;

export const DrivingTimeContainer = styled.div`
  top: -20px;
  left: -10px;
  display: flex;
  position: absolute;
  align-items: center;
  img {
    align-self: flex-start;
  }
`;

export const ActivityTimeContainer = styled.div`
  display: flex;
  left: 35px;
  position: absolute;
  align-items: center;
  img {
    align-self: flex-start;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    left: 25px;
  }
`;

export const StyledImportHeading = styled(Typography)`
  && {
    margin-right: 20px;
  }
`;

export const StyledChip = styled(Chip)`
  background-color: #ff9e74 !important;
  margin-right: 10px;
  margin-bottom: 10px;

  svg.chip-icon {
    width: 16px;
    height: 16px;
    margin-right: 0px;
    margin-left: 10px;
    fill: ${(props) => props.theme.appBar};
    path {
      fill: ${(props) => props.theme.appBar};
    }
  }

  .chip-label {
    max-width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    color: ${(props) => props.theme.appBar};
    font-size: 14px;
  }

  .delete-icon {
    color: ${(props) => props.theme.appBar};
    width: 14px;
    height: 14px;
  }
`;

export const ChipsContainer = styled(Grid)`
  padding: 2% 4%;
`;

export const IconGrid = styled(Grid)`
  align-items: flex-end;
  max-height: 48px;
  width: auto !important;
  flex-wrap: nowrap !important;
  ${(props) => props.theme.breakpoints.down(600)} {
    align-items: flex-start;
  }
`;
export const DayContainer = styled(Link)`
  && {
    padding: 10px;
    margin: 2px;
    min-height: 63px;
    min-width: 85px;
    color: #0000;
    font-weight: 300;
    wrap: nowrap;
    color: #0000;
    border: 1px solid ${(props) => props.color};

    &:hover {
      background: ${(props) => props.color};
      cursor: pointer;
    }
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  overflow-x: auto;
  position: sticky;
  top: 75px;
  left: 20px;
  z-index: 100px;
`;
