import React from "react";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as LikeIcon } from "../../../assets/follow.svg";
import { ReactComponent as FollowIcon } from "../../../assets/follow-notif.svg";
import moment from "moment";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { USER_URL } from "../../../urls";

export default function Notification({
	noti_type,
	event_time,
	msg,
	req_id,
	user_id,
	noti_id,
	requestHandler,
	status,
	created_by,
	closeDrawer,
}) {
	const [expanded, setExpanded] = React.useState(false);

	const handleChange = () => (event) => {
		setExpanded(!expanded);
	};
	return (
		<MuiExpansionPanel className="notification" square expanded={expanded}>
			<MuiExpansionPanelSummary
				classes={{
					root: "panel-root",
					expanded: "expanded-root",
					content: "notification-heading",
				}}
			>
				<div className="notification-img">
					<Link onClick={() => closeDrawer()} to={`${USER_URL}/${created_by}`}>
						{noti_type === "1" ? (
							<LikeIcon className="like-notif" />
						) : (
							<FollowIcon className="follow-notif" />
						)}
					</Link>
				</div>
				<div onClick={handleChange()}>
					<Typography gutterBottom variant="caption" color="primary">
						{msg}
					</Typography>
					<Typography variant="body2" color="textPrimary">
						{moment(event_time).fromNow()}
					</Typography>
				</div>
				{status === 2 && <span className="unread-notification" />}
			</MuiExpansionPanelSummary>
			{noti_type === "3" && (
				<MuiExpansionPanelDetails classes={{ root: "action-btns" }}>
					<Button
						color="secondary"
						variant="outlined"
						onClick={() => {
							requestHandler(req_id, noti_id, user_id, false);
							closeDrawer();
						}}
					>
						Reject
					</Button>
					<Button
						color="secondary"
						variant="contained"
						onClick={() => {
							requestHandler(req_id, noti_id, user_id, true);
							closeDrawer();
						}}
					>
						Accept
					</Button>
				</MuiExpansionPanelDetails>
			)}
		</MuiExpansionPanel>
	);
}
