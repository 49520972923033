import React from 'react'
import { Typography } from '@material-ui/core'
import "./privacy.css";

const index = () => {
  return (
    <main className="yeteh-main">
    <div className='maincon'>
    <h2 className='heading'
      >PRIVACY POLICY</h2>
    <br/>
    <p className='para'>User privacy is of paramount importance to Yeteh and has developed this Privacy
Policy to explain the manner in which Yeteh collects, uses, discloses, transfers, and
stores User information. Users are advised to familiarize themselves with Yeteh privacy
practices and keep themselves updated with the changes hereto from time to time.
<br/>
By mere use of the Platform, the User expressly consents to Yeteh’s use and disclosure
of Users Personal Information (as defined below) in accordance with this Privacy Policy.
This Privacy Policy shall be deemed to be incorporated into the Terms and Conditions
and shall be read in addition to and in conjunction with the Terms and Conditions. In
the event of any inconsistency or ambiguity arising between the Terms and Conditions
and the terms of this Privacy Policy, the provisions in this Privacy Policy to the extent of
such inconsistency or ambiguity, shall prevail.
<br/>
For the purpose of this Privacy Policy, all capitalised terms shall have the meaning so
assigned to them in the Terms and Conditions.</p>
<br/>
<p>
<p className='para-head'>1. COLLECTION AND USE OF PERSONAL INFORMATION</p>
<br/>
<p className='para-con'>1.1. Yeteh may collect, through the Platform, the information which is of a
personal nature to the User, including without limitation the following: the users
name, age, contact details (email, address and telephone / mobile numbers),
payment information, (Personal Information). Yeteh may use this information
to send Users updates about their trips, questionnaires to measure User
satisfaction with Yeteh’s service and announcements about new features that
Yeteh may offer. Users may be required to give credit/debit card information in
future and Yeteh shall use this information only for billing purposes. For Users
convenience, Yeteh saves billing information in case Users want to use it again
but Yeteh does not use this information without Users permission.
<br/>
1.2. Yeteh may also use Personal Information for internal purposes such as
auditing, data analysis, and research to improve Yeteh’s services.
<br/>
1.3. Yeteh will occasionally ask the Users to complete optional online surveys.
Yeteh uses this data to tailor User experience on the Platform, providing the
Users with content that Yeteh thinks Users might be interested in and to display
content according to User preferences.</p>
<br/>
<br/>

<p className='para-head'>2. MANNER OF DEALING WITH PERSONAL INFORMATION</p>
<br/>
<p className='para-con'>2.1. Yeteh gathers some information automatically and stores it in log files. This
information includes Internet Protocol (IP) addresses, browser type and language,
Internet Service Provider (ISP), referring and exit pages, operating system,
date/time stamp, and click stream data.
<br/>
2.2. Yeteh uses this information to understand and analyse trends, to administer
the Platform, to learn about user behaviour on the Platform, and to gather
demographic information about Yeteh user base as a whole. Yeteh may use this
information in its marketing and advertising services.
<br/>
2.3. Yeteh uses Personal Information to provide the services that the User
requests. Yeteh uses Personal Information to resolve disputes; troubleshoot
problems; help promote a safe service; collect money; customize User
experience; detect and protect Yeteh against error, fraud and other criminal
activity; enforce Yeteh terms and conditions; and as otherwise described to the
User at the time of collection.</p>
<br/>
<br/>
<p className='para-head'>3. COOKIES AND OTHER TECHNOLOGIES</p>
<br/>
<p className='para-con'>3.1. When Users register themselves on the Platform, Yeteh creates Users profile,
assigning a personal identification number, this personal identification number is
then sent back to Users hard drive in the form of a cookie, which is a very small
bit of code. This code is unique to each User. It enables seamless travel across
the Platform, allowing User to use the Platform without having to enter his
password frequently or fill out registration forms with information User has
already provided.
<br/>
3.2. Yeteh uses cookies on certain pages of the Platform to help analyse Platform
web page flow, deliver ads that relate to Users interests, measure the
effectiveness of ad campaigns and promote trust and safety. Yeteh offers certain
features that are only available through the use of a cookie. Cookies can also
help Yeteh provide information that is targeted to the Users interests. Most
cookies are session cookies, meaning that they are automatically deleted from
the Users hard drive at the end of a session. Users are always free to decline
Yeteh cookies by disabling cookies in their internet browser, although in that
case the User may not be able to use certain features on the Platform and the
User may be required to re-enter his password more frequently during a session.
<br/>
3.3. Based on User's previous visits to the Platform, third-party vendors, including
Google, use "cookies" to display advertisements on behalf of Yeteh across the
internet.
<br/>
3.4. Additionally, the User may encounter "cookies" or other similar devices on
certain pages of the Platform that are placed by third parties. Yeteh does not
control the use of cookies by third parties. If the User chooses to plan trips on the
Platform, Yeteh collects information about User booking behaviour.</p>
<br/>
<br/>
<p className='para-head'>4. DISCLOSURE TO THIRD PARTIES</p>
<br/>
<p className='para-con'>4.1. At times Yeteh may make certain Personal Information of the Users
available to strategic partners or other professional organisations that work with
Yeteh to provide Services, or that help Yeteh market to Users.
<br/>
4.2. Service Providers
Yeteh shares Users Personal Information with companies who provide services
such as information processing, extending credit, fulfilling Users bookings,
managing and enhancing User data, providing customer service, assessing User
interest in Yeteh products and services, and conducting User research or
satisfaction surveys. These companies shall handle User Personal Information
according to this Privacy Policy.
<br/>
4.3. Others
<br/>
4.3.1. It may be necessary by law, legal process, litigation, and/or requests from
public and government authorities for Yeteh to disclose Users Personal
Information.
<br/>
4.3.2. Yeteh may also disclose information about the User if it determines that
for purposes of national security, law enforcement, or other issues of public
importance, disclosure is necessary or appropriate.
<br/>
4.3.3. Yeteh may also disclose information about the Users if it determines that
disclosure is reasonably necessary to enforce Yeteh terms and conditions or
protect its operations or Users. Additionally, in the event of reorganization,
merger, or sale Yeteh may transfer any and all Personal Information Yeteh
collects to the relevant third party.</p>
<br/>
<br/>
<p className='para-head'>5. PROTECTION OF PERSONAL INFORMATION</p>
<br/>
<p className='para-con'>5.1. Yeteh has taken adequate measures to protect the security of Users
Personal Information and to ensure that Users choices for its intended use are
honoured. Yeteh takes robust precautions to protect User data from loss, misuse,
unauthorized access or disclosure, alteration, or destruction.
<br/>
5.2. Yeteh assures Users that their payment transactions are safe and secure.
When Users place bookings or access their personal account information, they're
utilizing a secure server software SSL, which encrypts their Personal Information
before it's sent over the Internet. SSL is one of the safest encryption technologies
available.
<br/>
5.3. Users Personal Information is never shared outside Yeteh without User
permission, except under conditions explained above. Inside the company, data
is stored in password-controlled servers with limited access to employees and
other personnel or consultants on a need-to-know basis.
<br/>
5.4. To ensure safety of Users Personal Information, Users are advised against
sharing their user name and password with anyone.</p>
<br/>
<br/>
<p className='para-head'>6. ACCESS TO PERSONAL INFORMATION</p>
<p className='para-con'>Yeteh provides Users with the means to ensure that their Personal Information is
correct and current. Users may review and update this information at any time by
logging into their account or by visiting the My Accounts section. Users can also
change their passwords and other details related to their profile by logging into
their account.</p>
<br/>
<br/>
<p className='para-head'>7. THIRD-PARTY SITES AND SERVICES</p>
<br/>
<p className='para-con'>7.1. Yeteh contracts with third-party vendors, including Google, to display
advertisements on behalf of Yeteh across the internet. These third-party vendors
may use information (not including User name, address, email address, or
telephone number) about User's visits to the Platform and User's interaction with
Yeteh goods and services on the Platform to display such advertisements Yeteh
shall however, not be responsible for misuse of such information of the User by
any third-party vendors.
<br/>
7.2. The Platform links to other websites or applications that may collect Users
Personal Information. Yeteh is not responsible for the privacy practices or the
content of those linked Platforms. Yeteh may indicate to the User that he is
getting linked to a third party Platform. The User is required to carefully read and
understand the terms of use or privacy policy of such third party Platform prior to
browsing such Platform or transacting thereon.
<br/>
7.3. Yeteh uses third-party advertising companies to serve ads when the User
visits the Platform. These companies may use information (not including User
name, address, email address, or telephone number) about Users visits to this
and other Platforms in order to provide advertisements about goods and services
of interest to the User.
<br/>
7.4. Any Third-Party Service Suppliers: Yeteh may use third-party service
suppliers to process Users Personal Information on Yeteh behalf. For example,
Yeteh may use service suppliers to send the order information on Yeteh behalf to
the supplier of the product by the User, or third parties may be instructed to
contact the User. The third parties involved in any of these services would be
bound by confidentiality agreements and would not be allowed to use the Users
Personal Information for any purposes other than those specified above.</p>
<br/>
<br/>
<p className='para-head'>8. CONSENT</p>
<br/>
<p className='para-con'>8.1. By using the Platform and/ or by providing his information, the User
consents to the collection and use of the information User discloses on the
Platform in accordance with this Privacy Policy, including but not limited to Users
consent for sharing Users Personal Information as per this Privacy Policy.
<br/>
8.2. The User agrees and acknowledges that his Personal Information collected
through the Platform may be transferred across national boundaries and stored
and processed in any the country around the world. User also acknowledges that
in certain countries or with respect to certain activities, the collection, transfer,
storage, and processing of User information may be undertaken by trusted third
party supplier of Yeteh such as credit card processors, web hosting providers,
communication services, and web analytic providers, to help facilitate Yeteh in
providing certain functions.
<br/>
8.3. Yeteh Privacy Policy is subject to change at any time without notice. To make
sure the Users are aware of changes, if any, Users are advised to review this
policy periodically.
<br/>
8.4. By visiting the Platform and availing the Services, Users agree to be bound
by the terms and conditions of this Privacy Policy. Users are advised to not use or
access the Platform, if they do not agree with the terms and conditions of this
Privacy Policy.
<br/>
8.5. Unless stated otherwise, the Privacy Policy applies to all information that
Yeteh has about the User and Users account.
<br/>
8.6. If Yeteh decides to change the Privacy Policy, Yeteh will post those changes
on the Platform, so that the Users are always aware of what information Yeteh
collects, how Yeteh uses it, and under what circumstances Yeteh discloses it.</p>
<br/>
<br/>
<p className='para-head'>9. OPT-OUT</p>
<br/>
<p className='para-con'>9.1. From time to time, Yeteh may communicate with Users who subscribe to its
services via email or text message. Yeteh provides Users the opportunity to
exercise an opt-out choice if they do not want to receive non-essential
communication from Yeteh, such as emails or updates from Yeteh regarding new
services offered on the Platform or if Users do not want Yeteh to share their
Personal Information with third parties. The opt-out choice may be exercised by
ticking or un-ticking the appropriate box if such checkbox is available at the
points where Users Personal Information is collected or by contacting Yeteh at
Yeteh. Yeteh will process Users unsubscribe request as soon as possible, in some
circumstances Users may receive a few more emails until the unsubscribe request
is processed. Users also may opt-out of receiving such emails by clicking on the
"unsubscribe" link within the text of the email.
<br/>
9.2. If the Users want to remove their Personal Information from the Platform or
withdraw all consents given by them to Yeteh, they can do so by writing to .
<br/>
9.3. For the avoidance of doubt, it is stated that User information like,
transaction records would be retained by Yeteh for internal audit and security
purposes for as long as legally permitted.
<br/>
9.4. User's may opt-out of Google's use of "cookies" by visiting Google's Ads
Settings. Alternatively, User's may opt-out of third-party vendors use of "cookies"
by visiting the Network Advertising Initiatives opt-out page.</p>
<br/>
<br/>
<p className='para-head'>10. LIMITATION OF LIABILITY</p>
<br/>
<p className='para-con'>10.1. Yeteh is not responsible for any breach of security or for any actions of any
third parties that receive the Users Personal Information.
<br/>
10.2. Notwithstanding anything contained in this Privacy Policy or elsewhere,
Yeteh shall not be held responsible for any loss, damage or misuse of the User
Personal Information, if such loss, damage or misuse is attributable to a Force
Majeure Event (as defined below).
<br/>
10.3. A Force Majeure Event shall mean any event that is beyond the
reasonable control of Yeteh and shall include, without limitation, sabotage, fire,
flood, explosion, acts of God, civil commotion, strikes, lockouts or industrial
action of any kind, riots, insurrection, war, acts of government, computer
hacking, civil disturbances, unauthorised access to computer data and storage
device, computer crashes, breach of security and encryption, and any other
similar events not within the control of Yeteh and which Yeteh is not able to
overcome.</p>
<br/>
<br/>
<p className='para-head'>11. GRIEVANCE OFFICER</p>
<p className='para-con'>In accordance with Information Technology Act 2000 and rules made there
under, the name and contact details of the Grievance Officer are provided
below:</p>
<br/>
<p className='para-con'>Chief Operating Officer
Parminder Singh Bhela
Email- info@yeteh.com</p>
<br/>
<br/>
<p className='para-head'>12. PRIVACY QUESTIONS</p>
<p className='para-con'>Queries regarding this Privacy Policy should be directed to info@yeteh.com</p></p>
</div></main>
  )
}

export default index
