import {
  NOTIFICATION_COUNT,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
} from "./types";
import {
  unreadCountApi,
  getNotificationsApi,
  readNotificationApi,
  accpetRejectFollowRequestApi,
} from "./services";
import { enqueueSnackbar } from "../../../../Master/Notifier/duck";
import { API_ERROR } from "../../../../constants";
import { UPDATE_FOLLOWERS } from "../../../../Profile/People/duck";

export const getUnreadCount = () => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const res = await unreadCountApi(state.auth.token);
      if (res.data.status === 200) {
        dispatch({
          type: NOTIFICATION_COUNT,
          payload: res.data.count,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getNotifications = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_NOTIFICATIONS_REQUEST,
    });
    const state = getState();
    try {
      const res = await getNotificationsApi(state.auth.token);
      if (res.data.status === 200) {
        return dispatch({
          type: GET_NOTIFICATIONS_SUCCESS,
          payload: res.data.data,
        });
      }
    } catch (err) {
      if (err.response.data.status === 391) {
        // return dispatchSuccessActionsOnType(tripType, dispatch, []);
      }
      dispatch(
        enqueueSnackbar({
          message: API_ERROR,
          variant: "error",
        })
      );
      return dispatch({
        type: GET_NOTIFICATIONS_FAILURE,
        payload: err,
      });
    }
  };
};

export const readNotifications = (ids) => {
  return (dispatch, getState) => {
    const state = getState();
    const notificationPromises = [];
    ids.forEach((id) => {
      notificationPromises.push(
        readNotificationApi(state.auth.token, id)
          .then((res) => res)
          .catch((err) => err)
      );
    });
    Promise.all(notificationPromises)
      .then((res) => {
        dispatch({
          type: NOTIFICATION_COUNT,
          payload: 0,
        });
      })
      .catch((err) => console.log(err));
  };
};

export const handleRequest = (id, notId, userId, status) => {
  return (dispatch, getState) => {
    const state = getState();
    accpetRejectFollowRequestApi(state.auth.token, id, notId, status)
      .then((res) => {
        dispatch({
          type: UPDATE_FOLLOWERS,
          payload: {
            follower: status ? true : false,
            userId,
          },
        });

        if (status) {
          dispatch(
            enqueueSnackbar({
              message: "Request Accepted",
              variant: "success",
            })
          );
        } else {
          dispatch(
            enqueueSnackbar({
              message: "Request Declined",
              variant: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            message: API_ERROR,
            variant: "error",
          })
        );
        console.log(err);
      });
  };
};
