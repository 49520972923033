import {
  GET_PERSONAL_PHOTOS_REQUEST,
  GET_PERSONAL_PHOTOS_SUCCESS,
  GET_PERSONAL_PHOTOS_FAILURE,
  GET_FOLLOWING_PHOTOS_REQUEST,
  GET_FOLLOWING_PHOTOS_SUCCESS,
  GET_COMMUNITY_PHOTOS_REQUEST,
  GET_COMMUNITY_PHOTOS_SUCCESS,
  GET_COMMUNITY_PHOTOS_FAILURE,
  GET_FOLLOWING_PHOTOS_FAILURE,
  FRIEND_PHOTO_LIKE_SUCCESS,
  COMMUNITY_PHOTO_LIKE_SUCCESS,
  USER_PHOTO_LIKE_SUCCESS
} from "./types";

const INITIAL_STATE = {
  personal: undefined,
  following: undefined,
  community: undefined,
  personalSearch: undefined,
  followingSearch: undefined,
  communitySearch: undefined,
  personalLoading: true,
  followingLoading: true,
  communityLoading: true,
  personalError: undefined,
  followingError: false,
  communityError: false,
  personalPageNo: 0,
  followingPageNo: 0,
  communityPageNo: 0,
  followingIsLast: true,
  personalIsLast: true,
  communityIsLast: true
};

export const photosReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PERSONAL_PHOTOS_REQUEST: {
      return {
        ...state,
        personalLoading: true
      };
    }

    case GET_FOLLOWING_PHOTOS_REQUEST: {
      return {
        ...state,
        followingLoading: true
      };
    }

    case GET_COMMUNITY_PHOTOS_REQUEST: {
      return {
        ...state,
        communityLoading: true
      };
    }

    case GET_PERSONAL_PHOTOS_SUCCESS: {
      const existing = state.personal;
      return {
        ...state,
        personalLoading: false,
        personalError: undefined,
        personal: (action.pageno === 0 ? [] : existing || []).concat(
          action.payload
        ),
        personalPageNo: action.pageno,
        personalIsLast: action.isLast,
        personalSearch: action.search
      };
    }

    case GET_FOLLOWING_PHOTOS_SUCCESS: {
      const existing = state.following;
      return {
        ...state,
        followingLoading: false,
        followingError: undefined,
        following: (action.pageno === 0 ? [] : existing || []).concat(
          action.payload
        ),
        followingPageNo: action.pageno,
        followingIsLast: action.isLast,
        followingSearch: action.search
      };
    }

    case USER_PHOTO_LIKE_SUCCESS: {
      if (!action.from) {
        const existing = state.personal;
        const newPhotos = existing.map(photo => {
          if (photo.photo_id === action.photoId) {
            return {
              ...photo,
              is_like: action.status,
              likes: action.status ? photo.likes + 1 : photo.likes - 1
            };
          }
          return photo;
        });
        return {
          ...state,
          personal: newPhotos
        };
      }
      return state;
    }

    case FRIEND_PHOTO_LIKE_SUCCESS: {
      if (!action.from) {
        const existing = state.following;
        const newPhotos = existing.map(photo => {
          if (photo.photo_id === action.photoId) {
            return {
              ...photo,
              is_like: action.status,
              likes: action.status ? photo.likes + 1 : photo.likes - 1
            };
          }
          return photo;
        });
        return {
          ...state,
          following: newPhotos
        };
      }
      return state;
    }

    case COMMUNITY_PHOTO_LIKE_SUCCESS: {
      if (!action.from) {
        const existing = state.community;
        const newPhotos = existing.map(photo => {
          if (photo.photo_id === action.photoId) {
            return {
              ...photo,
              is_like: action.status,
              likes: action.status ? photo.likes + 1 : photo.likes - 1
            };
          }
          return photo;
        });
        return {
          ...state,
          community: newPhotos
        };
      }
      return state;
    }

    case GET_COMMUNITY_PHOTOS_SUCCESS: {
      const existing = state.community;
      return {
        ...state,
        communityLoading: false,
        communityError: undefined,
        community: (action.pageno === 0 ? [] : existing || []).concat(
          action.payload
        ),
        communityPageNo: action.pageno,
        communityIsLast: action.isLast,
        communitySearch: action.search
      };
    }

    case GET_PERSONAL_PHOTOS_FAILURE: {
      return {
        ...state,
        personalLoading: false,
        personalError: true
      };
    }

    case GET_FOLLOWING_PHOTOS_FAILURE: {
      return {
        ...state,
        followingLoading: false,
        followingError: true
      };
    }

    case GET_COMMUNITY_PHOTOS_FAILURE: {
      return {
        ...state,
        communityLoading: false,
        communityError: true
      };
    }

    default:
      return state;
  }
};
