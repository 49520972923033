import React from "react";
import { Typography } from "@material-ui/core";
import LoadingButton from "../LoadingButton";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";

const NoContent = props => {
  const { heading, buttonText, to, alignText } = props;
  return (
    <>
      <Typography
        align={alignText}
        style={{
          padding: "4em 0.5em",
          fontSize: "22px"
        }}
        variant="h4"
      >
        {heading}
      </Typography>
      {buttonText && to && (
        <LoadingButton
          color="secondary"
          variant="contained"
          component={Link}
          to={to}
        >
          <AddIcon />
          {buttonText}
        </LoadingButton>
      )}
    </>
  );
};

export default NoContent;
