import React from "react";
import { connect } from "react-redux";
import { compose, lifecycle, branch, renderComponent } from "recompose";
import { getCheckins } from "../duck";
import LoadingScreen from "../../common/LoadingScreen";
import withWidth from "@material-ui/core/withWidth";

const mapStateToProps = (state, { userId }) => {
  if (userId) {
    const checkins = state.checkins;
    const userData = {};
    if (checkins.checkins && checkins.checkins.userName) {
      userData.profile_pic = checkins.checkins.profile_pic;
      userData.profile_pic = checkins.checkins.profile_pic;
      userData.first_name = checkins.checkins.first_name;
      userData.last_name = checkins.checkins.last_name;
      userData.user_name = checkins.checkins.userName;
    }
    return {
      checkins: state.checkins,
      auth: { userData },
    };
  }
  return {
    checkins: state.checkins,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCheckins: (userId) => dispatch(getCheckins(userId)),
  };
};

const ProfileCardHandlers = compose(
  withWidth(),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { userId } = this.props;
      this.props.getCheckins(userId);
    },
    componentDidUpdate({ userId: prevId }) {
      const { userId } = this.props;
      if (userId && prevId !== userId) {
        this.props.getCheckins(userId);
      }
    },
  }),
  branch(
    (props) => {
      const { checkins } = props;
      if (checkins.loading) {
        return true;
      } else {
        return false;
      }
    },
    renderComponent(() => <LoadingScreen />)
  )
);

export default ProfileCardHandlers;
