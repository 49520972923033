import styled from "styled-components";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

export const StyledDrawer = styled(SwipeableDrawer)`
  && {
    .notifications-container {
      max-height: calc(100vh - 63px);
      overflow-y: auto;
    }
    .notifications-body {
      ${(props) => props.theme.breakpoints.up("sm")} {
        max-width: 40%;
        min-width: 40%;
      }
      ${(props) => props.theme.breakpoints.down("xs")} {
        max-width: 100%;
        min-width: 100%;
      }

      .notification {
        border-top: 0.5px solid rgba(255, 255, 255, 0.37);
        margin: 0;

        .panel-root {
          min-height: 48px !important;
          max-height: 68px !important;
          .notification-heading {
            margin: 0;
            align-items: center;

            .notification-img {
              width: 31px;
              height: 31px;
              max-width: 31px;
              max-height: 31px;
              margin-right: 15px;
              a {
                svg {
                  width: 31px;
                  height: 31px;
                  max-width: 31px;
                  max-height: 31px;
                }
              }

              flex-shrink: 0;
            }

            p {
              color: ${(props) => props.theme.secondaryWhite};
              line-height: 16px
            }
          }
        }

        .action-btns {
          margin-left: 46px;
          button:first-child {
            margin-right: 20px;
          }
        }

        .unread-notification {
          color: ${(props) => props.theme.secondaryColor};
          border: ${(props) => `4px solid ${props.theme.secondaryColor}`};
          position: absolute;
          right: 8px;
          border-radius: 100%;
          height: 4px;
          width: 4px;
        }
      }
    }
  }
`;

export const NotificationDrawerHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 1.125rem;
    font-weight: 600;
  }
  padding: 20px 10px;
  button {
    padding: 0;
  }
`;
