import React from "react";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Close from "@material-ui/icons/Close";
import GoogleAutocomplete from "../../common/FormFields/googlePlaceAutocomplete";
import { reduxForm, Field, getFormValues, change } from "redux-form";
import CancelIcon from "@material-ui/icons/CloseOutlined";

import { ReactComponent as UploadIcon } from "../../assets/profile/add-photo.svg";

import { StyledDialog, UploadedImg, StyledPhotoDiv } from "./styles";
import LoadingButton from "../../common/LoadingButton";
import {
  maxPhotos,
  fileSize,
  required,
} from "../../common/FormFields/validations";
import FileInputField from "../../common/FormFields/FileUpload";
import { PRIVACY_OPTIONS, ACTIVITY_TYPES } from "../../constants";
import ReduxFormTextField from "../../common/FormFields/TextField";
import { uploadPhotos } from "./duck";

const maxPhotos7 = maxPhotos(7);
const fileSize10 = fileSize(100000000); 

const AddPhotosDialog = (props) => {
  const {
    isOpen: open,
    handleClose,
    fullScreen,
    photos,
    submitting,
    handleSubmit,
    removePhoto,
    photo: photosObjArr,
  } = props;

  return (
    <StyledDialog
      fullWidth={true}
      maxWidth="md"
      maxwidth="750px"
      fullScreen={fullScreen}
      open={open}
    >
      <DialogTitle disableTypography={true} className="title">
        <Grid
          container
          wrap="nowrap"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography className="heading" color="secondary">
              Add Photos
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              position: "absolute",
              right: "0",
            }}
          >
            <IconButton color="primary" onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="dialog-body">
        <Typography color="primary">
          Upload photos and location of your trip
        </Typography>
        <form onSubmit={handleSubmit}>
          <div className="activity-type">
            <Field
              name="activitytype"
              label="Activity type"
              select
              placeholder="Activity Type"
              component={ReduxFormTextField}
              validate={required}
            >
              {ACTIVITY_TYPES.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Field>
          </div>
          <StyledPhotoDiv>
            <UploadIcon />
            <Typography>Must be 7 photos or less. Minimum Photo width 500 pixels </Typography>
            <Field
              multi
              multiple
              accept="image/*"
              name="photos"
              validate={[maxPhotos7, fileSize10]}
              component={FileInputField}
            />
          </StyledPhotoDiv>
          <div className="upload-form-container">
            {photos.map((photo) => (
              <div key={photo.id} className="photo-div">
                <div className="privacy">
                  <Field
                    name={`photo[${photo.id}]privacy`}
                    select
                    padding="0 20px 0 0"
                    component={ReduxFormTextField}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  >
                    {PRIVACY_OPTIONS.map((opt, index) => (
                      <MenuItem key={index} value={opt.value}>
                        <img src={opt.icon} alt={opt.label} />
                      </MenuItem>
                    ))}
                  </Field>
                </div>
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <CancelIcon
                    style={{
                      position: "absolute",
                      right: "-12px",
                      top: "-14px",
                      color: "#08E8DE",
                      background: "#292929",
                      borderRadius: "12px",
                      cursor: "pointer",
                      height: "24px",
                      width: "24px",
                    }}
                    onClick={() => removePhoto(photo.id, photos, photosObjArr)}
                  />
                  <UploadedImg src={photo.data} alt={photo.title} />
                </div>
                <div>
                  <Field
                    name={`photo[${photo.id}]location`}
                    label="Location"
                    validate={required}
                    placeholder="Location"
                    position="fixed"
                    component={GoogleAutocomplete}
                  />
                </div>
              </div>
            ))}
          </div>
          <LoadingButton
            variant="contained"
            color="secondary"
            type={"submit"}
            submitting={submitting}
          >
            Upload
          </LoadingButton>
        </form>
      </div>
    </StyledDialog>
  );
};

const createPhotos = (filesData) => {
  return filesData.map((photo) => ({
    data: photo.data,
    title: "local",
    id: photo.id,
    file: photo.file,
  }));
};

const mapStateToProps = (state) => {
  const vals = getFormValues("add-photo-dialog")(state);

  let photos = [];
  if (vals) {
    if (vals.photos) {
      photos = createPhotos(vals.photos);
    }
  }

  const keys = photos.map((photo) => photo.id);
  const photoVal = {};
  keys.forEach((key) => {
    photoVal[key] = {
      privacy: "1",
      ...((vals || {}).photo ? (vals || {}).photo[key] : {}),
    };
  });

  return {
    photos,
    photo: (vals || {}).photo || [],
    auth: state.auth,
    initialValues: {
      photo: photoVal,
      photos,
      activitytype: (vals || {}).activitytype,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removePhoto: (id, photos, photosObjArr) => {
      photos = photos.filter((photo) => photo.id !== id);
      let photoKeys = Object.keys(photosObjArr);
      photoKeys = photoKeys.filter((key) => key !== id);
      const newObj = {};
      photoKeys.forEach((key) => (newObj[key] = photosObjArr[key]));
      dispatch(change("add-photo-dialog", "photos", photos));
      dispatch(change("add-photo-dialog", "photo", newObj));
    },
    uploadPhotos: (photos, photoData, callback) =>
      dispatch(uploadPhotos(photos, photoData, callback)),
  };
};

const handleSubmit = (values, dispatch, props) => {
  const { uploadPhotos, handleClose, getPersonalPhotos } = props;
  const photoIds = Object.keys(values.photo);
  const photos = photoIds.map((key) => ({ ...values.photo[key], id: key }));
  const photoDataObj = {};
  values.photos.forEach((pic) => {
    photoDataObj[pic.id] = pic;
  });

  return new Promise((res) => {
    return uploadPhotos(photos, photoDataObj, (err, data) => {
      if (!err) {
        getPersonalPhotos();
        handleClose();
      }
      return res();
    });
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "add-photo-dialog",
    destroyOnUnmount: true,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  })(withMobileDialog()(AddPhotosDialog))
);
