import styled from "styled-components";

export const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 400px;
  background: ${(props) => props.theme.primaryBlack};
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px 60px;

  ${(props) => props.theme.breakpoints.down("xs")} {
    max-width: 300px;
    padding: 20px 30px;
  }

  .fields-container,
  .tabs-root,
  .action-btns {
    flex-basis: 100%;
    max-width: 100%;
    flex-shrink: 0;
  }

  .tabs-root {
    padding: 0;
    background: transparent;
    margin-top: 20px;
  }

  .flexContainer {
    justify-content: unset;

    button {
      margin-right: 20px;
    }
  }
  .fields-container {
    min-height: 255px;

    .form-input {
      margin-bottom: 10px;
    }
  }
  .action-btns {
    display: flex;
    justify-content: space-around;
    button {
      font-weight: 900;
    }
  }
`;

export const StyledPhotoDiv = styled.div`
  flex-basis: 100%;
  max-width: 100%;
  flex-shrink: 0;
  position: relative;
  z-index: 1;
  width: fit-content;
  justify-content: center;
  align-items: center;
  display: flex;
`;
