import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export const PlanContainer = styled(Grid)`
  height: 100%;
  .plan-heading-container {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.37);
    padding: 20px 20px 5px 20px;
  }
`;

export const StyledTripName = styled(Typography)`
  && {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 14px;
  }
`;

export const StyledPrivacyHeading = styled(Typography)`
  && {
    font-family: Open Sans;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 0.0025em;
  }
`;

export const StyledUsername = styled(Typography)`
  && {
    font-family: Open Sans;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.0025em;
    margin-right: 10%;
  }
`;

export const UsernamePrivacyContainer = styled.div`
  display: flex;
  align-items: center;
  .date-privacy {
    display: flex;
    width: auto;
    justify-content: space-between;
    padding-right: 10px;

    hr {
      background: ${(props) => props.theme.primaryColor};
    }

    p:first-child {
      margin-left: 0;
    }

    hr,
    p {
      margin: 0 10px;
    }
  }

  .btn-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
  .edit-trip {
    border: ${(props) => `1px solid  ${props.theme.lightGreen}`};
    color: ${(props) => `${props.theme.lightGreen}`};
  }
  .edit-trip:hover {
    border: ${(props) => `1px solid  ${props.theme.lightGreen}`};
  }
`;

export const ActionImg = styled.img`
  margin-right: 6px;
`;

export const TotalAmoutSpan = styled.span`
  border-radius: 50px;
  background-color: #4fc099;
  padding: 3px 7px;
  fontsize: 10px;
`;
