import React from "react";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";

const AvatarYeteh = (props) => {
  const {
    handleClose,
    logout,
    open,
    handleClick,
    anchorEl,
    isLoggedIn,
    auth: {
      userData: { profile_pic, first_name },
    },
  } = props;
  return (
    <>
      <Avatar
        alt={first_name}
        src={profile_pic}
        onClick={handleClick}
        style={{
          cursor: "pointer",
        }}
      />
      <Popover
        id="simple-popper"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          {isLoggedIn ? (
            <>
              <ListItem
                onClick={() => {
                  handleClose();
                  logout();
                }}
                button
              >
                <ListItemText primary="Logout" />
              </ListItem>
            </>
          ) : (
            <ListItem
              component={Link}
              to="/login"
              onClick={() => handleClose()}
              button
            >
              <ListItemText primary="Sign-In" />
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  );
};

export default AvatarYeteh;
