import { compose, lifecycle, withState } from "recompose";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { enqueueSnackbar } from "../../Master/Notifier/duck/actions";
import { axiosInst, axiosConfig } from "../../axios";

const mapDispatchToProps = (dispatch) => {
  return {
    showSnackBar: (message, variant) =>
      dispatch(
        enqueueSnackbar({
          message: message,
          variant: variant ? variant : "success",
        })
      ),
  };
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const GoogleSearchHandlers = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withState("src", "setSrc", undefined),
  lifecycle({
    componentDidMount() {
      const { showSnackBar, auth, setSrc } = this.props;
      window.addEventListener("click", async (event) => {
        const clickedEle = event.target;
        if (
          (clickedEle.tagName === "B" &&
            event.path &&
            event.path[1] &&
            event.path[1].tagName === "A") ||
          (clickedEle.className === "gs-title" && clickedEle.tagName === "A")
        ) {
          let href = "";
          let loading = false;
          const token = auth.token;
          event.preventDefault();
          if (clickedEle.tagName === "B" && event.path[1].tagName === "A") {
            href = event.path[1].href;
          } else if (
            clickedEle.className === "gs-title" &&
            clickedEle.tagName === "A"
          ) {
            href = clickedEle.href;
          }
          try {
            if (!loading) {
              loading = true;
              const res = await axiosInst.post(
                "/checkxframe",
                {
                  url: href,
                  header: "x-frame-options",
                },
                axiosConfig(token)
              );
              if (res.data.hdr_status === "false") {
                setSrc(href);
                this.props.setZIndex(2);
              } else {
                showSnackBar(
                  "Website opened in new window due to origin policies"
                );
                window.open(
                  href,
                  "_blank",
                  `toolbar=yes,scrollbars=yes,resizable=yes,top=200,left=${
                  window.innerWidth
                  },height=${window.innerHeight - 90},width=${
                  window.innerWidth / 2
                  }`
                );
              }
            }
          } catch (err) {
            // console.log(err);
            showSnackBar("Something went wrong", "error");
          }
        }
      });
    },
  })
);

export default GoogleSearchHandlers;
