import { axiosInst, axiosConfig } from "../../../axios";

export const getPeopleApi = (token, tripType, pageNo, name) => {
  return axiosInst.post(
    "/following",
    { type: tripType, perpage: 10, pageno: pageNo, name },
    axiosConfig(token)
  );
};

export const followApi = (token, userid) => {
  return axiosInst.post(
    "/follow",
    { userid: String(userid) },
    axiosConfig(token)
  );
};

export const unfollowApi = (token, userid) => {
  return axiosInst.post(
    "/unfollow",
    { userid: String(userid) },
    axiosConfig(token)
  );
};
