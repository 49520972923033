import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { StyledList } from "./style";
import { getLatLngAndCity } from "../utils";

const handleSelect = (address, setValue, onSelect) => {
	getLatLngAndCity(address).then((res) => {
		setValue(res);
		if (onSelect) {
			onSelect(res);
		}
	});
};
class LocationSearchInput extends React.Component {
	render() {
		const {
			input: { value, onChange, name },
			label,
			placeholder,
			meta: { error, touched },
			lat,
			lng,
			onSelect,
			required,
		} = this.props;

		const searchOptions = {
			location: new window.google.maps.LatLng(lat, lng),
			radius: 2000,
			// types: ["address"]
		};

		return (
			<PlacesAutocomplete
				value={value.address ? value.address : value}
				onChange={onChange}
				searchOptions={searchOptions}
				onSelect={(addr) => handleSelect(addr, onChange, onSelect)}
				// onSelect={address => this.handleSelect(address, onChange)}
			>
				{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
					return (
						<FormControl
							fullWidth={true}
							error={error && touched ? true : false}
						>
							<InputLabel required={required} htmlFor="google-autocomplete">
								{label}
							</InputLabel>
							<Input
								id="google-autocomplete"
								aria-describedby="google-autocomplete-helper-text"
								label={label}
								fullWidth
								autoComplete={name}
								placeholder={placeholder}
								{...getInputProps({
									placeholder: placeholder,
								})}
								error={touched && error ? true : false}
								// onBlur={onBlur}
							/>
							{error && touched && (
								<FormHelperText id="google-autocomplete-helper-text" error>
									{error}
								</FormHelperText>
							)}
							{suggestions.length > 0 && (
								<StyledList
									style={{
										position: "absolute",
										zIndex: 1,
										top: "50px",
										minWidth: "200px",
									}}
									className="autocomplete-dropdown-container"
								>
									{loading && <div>Loading...</div>}
									{suggestions.map((suggestion) => {
										const className = suggestion.active
											? "suggestion-item--active"
											: "suggestion-item";
										// inline style for demonstration purpose
										const style = suggestion.active
											? {
													backgroundColor: "rgba(0, 0, 0, 0.08)",
													cursor: "pointer",
											  }
											: { backgroundColor: "#2E2E2E", cursor: "pointer" };
										return (
											<ListItem
												{...getSuggestionItemProps(suggestion, {
													className,
													style,
												})}
												value={suggestion}
											>
												<Typography>{suggestion.description}</Typography>
											</ListItem>
										);
									})}
								</StyledList>
							)}
						</FormControl>
					);
				}}
			</PlacesAutocomplete>
		);
	}
}

export default LocationSearchInput;
