import {
  GETTRIP_REQUEST,
  GETTRIP_SUCCESS,
  GETTRIP_FAILURE,
  DELETE_TRIP_REQUEST,
  DELETE_TRIP_SUCCESS,
  DELETE_TRIP_FAILURE,
  GET_CHECKIN_REQUEST,
  GET_CHECKIN_SUCCESS,
  GET_CHECKIN_FAILURE,
  GET_FRIEND_TRIP_REQUEST,
  GET_COMMUNITY_TRIP_REQUEST,
  GET_FRIEND_TRIP_SUCCESS,
  GET_COMMUNITY_TRIP_SUCCESS,
  GET_COMMUNITY_TRIP_ERROR,
  GET_FRIEND_TRIP_ERROR,
  CLEAR_MY_TRIPS,
} from "./types";
import { UPDATE_FOLLOWING, UPDATE_FOLLOWERS } from "../People/duck/types";

const INITIAL_STATE = {
  loading: true,
  error: undefined,
  trips: [],
  othertrips: [],
  communityTrips: undefined,
  search: undefined,
  pageNo: 0,
  isLast: true,
  friendsTrips: undefined,
  friendsSearch: undefined,
  communitySearch: undefined,
  friendsLoading: true,
  communityLoading: true,
  friendsError: false,
  communityError: false,
  friendsPageNo: 0,
  communityPageNo: 0,
  friendsIsLast: true,
  communityIsLast: true,
};

export const tripsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_MY_TRIPS: {
      return {
        ...state,
        trips: [],
      };
    }
    case GETTRIP_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_FRIEND_TRIP_REQUEST: {
      return {
        ...state,
        friendsLoading: true,
      };
    }

    case GET_COMMUNITY_TRIP_REQUEST: {
      return {
        ...state,
        communityLoading: true,
      };
    }

    case GETTRIP_SUCCESS: {
      const existing = state.trips;
      let existingUserTrips = state.othertrips;
      let trips = [];
      if (action.user === "otherUser") {
        trips = (action.pageno === 0 ? [] : existingUserTrips || []).concat(
          action.payload
        );
      } else {
        trips = (action.pageno === 0 ? [] : existing || []).concat(
          action.payload
        );
      }
      return {
        ...state,
        loading: false,
        error: undefined,
        trips: action.user === "otherUser" ? existing : trips,
        othertrips: action.user === "otherUser" ? trips : existingUserTrips,
        pageNo: action.pageno,
        isLast: action.isLast,
        search: action.search,
      };
    }

    case GET_FRIEND_TRIP_SUCCESS: {
      const existing = state.friendsTrips;
      return {
        ...state,
        friendsLoading: false,
        friendsError: undefined,
        friendsTrips: (action.pageno === 0 ? [] : existing || []).concat(
          action.payload
        ),
        friendsPageNo: action.pageno,
        friendsIsLast: action.isLast,
        friendsSearch: action.search,
      };
    }

    case GET_COMMUNITY_TRIP_SUCCESS: {
      const existing = state.communityTrips;
      return {
        ...state,
        communityLoading: false,
        communityError: undefined,
        communityTrips: (action.pageno === 0 ? [] : existing || []).concat(
          action.payload
        ),
        communityPageNo: action.pageno,
        communityIsLast: action.isLast,
        communitySearch: action.search,
      };
    }

    case DELETE_TRIP_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        trips: state.trips.filter((trip) => trip.trip_id !== action.payload),
      };
    }

    case GETTRIP_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        trips: undefined,
      };
    }

    case GET_FRIEND_TRIP_ERROR: {
      return {
        ...state,
        friendsLoading: false,
        friendsError: true,
        friendsTrips: action.payload,
      };
    }

    case GET_COMMUNITY_TRIP_ERROR: {
      return {
        ...state,
        communityLoading: false,
        communityError: true,
        communityTrips: undefined,
      };
    }

    default:
      return state;
  }
};

const INITIAL_DELETE_STATE = {
  loading: false,
  error: undefined,
  success: false,
};

export const deleteTripReducer = (state = INITIAL_DELETE_STATE, action) => {
  switch (action.type) {
    case DELETE_TRIP_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case DELETE_TRIP_SUCCESS: {
      return {
        loading: false,
        error: undefined,
        success: true,
      };
    }

    case DELETE_TRIP_FAILURE: {
      return {
        loading: false,
        error: true,
        success: false,
      };
    }

    default:
      return state;
  }
};

const INITIAL_CHECKINS_STATE = {
  loading: true,
  error: undefined,
  checkins: {},
};

export const checkinsReducer = (state = INITIAL_CHECKINS_STATE, action) => {
  switch (action.type) {
    case GET_CHECKIN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_CHECKIN_SUCCESS: {
      return {
        loading: false,
        error: undefined,
        checkins: action.payload,
      };
    }

    case UPDATE_FOLLOWING: {
      if (action.payload.following) {
        return {
          ...state,
          checkins: {
            ...state.checkins,
            following: state.checkins.following + 1,
          },
        };
      }

      return {
        ...state,
        checkins: {
          ...state.checkins,
          following:
            state.checkins.following - 1 >= 0
              ? state.checkins.following - 1
              : 0,
        },
      };
    }

    case UPDATE_FOLLOWERS: {
      if (action.payload.follower) {
        return {
          ...state,
          checkins: {
            ...state.checkins,
            followers: state.checkins.followers + 1,
          },
        };
      }

      return {
        ...state,
        checkins: {
          ...state.checkins,
          followers:
            state.checkins.followers - 1 >= 0
              ? state.checkins.followers - 1
              : 0,
        },
      };
    }

    case DELETE_TRIP_SUCCESS: {
      return {
        loading: false,
        error: undefined,
        checkins: {
          ...state.checkins,
          activitycount: state.checkins.activitycount - action.checkinCount,
          tripcount: state.checkins.tripcount - 1,
        },
      };
    }

    case GET_CHECKIN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        checkins: action.payload,
      };
    }

    default:
      return state;
  }
};
