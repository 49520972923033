import styled from "styled-components";
import { Toolbar } from "@material-ui/core";

export const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: flex-end;
  background: #ddd;
`;

export const StyledMainDiv = styled.div`
  background-color: ${(props) => props.theme.appBar};
  min-height: calc(100vh - 80px);
`;
