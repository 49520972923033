import React from "react";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as EditIcon } from "../../../assets/planner/edit.svg";
import SaveIcon from "../../../assets/save.svg";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { EditButtonGrid } from "../PlanForm/styles";
import LoadingButton from "../../../common/LoadingButton";

import moment from "moment";
import { StyledFab, FormHeaderContainer, CurrencyContainer } from "../styles";
import { getPrivacyIconAndLabel } from "../../../common/utils";

const PlanHeading = (props) => {
  const {
    submitting,
    trip: {
      tripdata: { name, to_dt, from_dt, type },
    },
    setDialogOpen,
    action,
    currency,
  } = props;

  const iconAndLabel = getPrivacyIconAndLabel(type);

  const getPrice = () => {
    let total = 0;
    const tripData = props.formValues?.plan;

    tripData &&
      tripData.forEach((trip) =>{
      if(trip.activities!=null)
        trip.activities.forEach((activity) => (total += +activity.activitycost))
    });
    
    return total;
  };

  if (action !== "create") {
    return (
      <FormHeaderContainer>
        <Grid container className="plan-heading">
          <Grid item container sm={7} xs={8} direction="column">
            <Grid item container wrap="nowrap">
              <Grid item className="plan-title-container">
                <Typography
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  variant="h4"
                >
                  {name}
                </Typography>
              </Grid>

              <EditButtonGrid item>
                <IconButton onClick={() => setDialogOpen(true)}>
                  <EditIcon />
                </IconButton>
              </EditButtonGrid>
            </Grid>
            <Grid item container wrap="nowrap">
              <Grid item>
                <Typography className="plan-date" variant="body2">
                  {moment(from_dt).format("Do MMM")} -{" "}
                  {moment(to_dt).format("Do MMM YY")}
                </Typography>
              </Grid>
              <Grid item className="privacy-type-container">
                <img src={iconAndLabel && iconAndLabel.icon} alt="privacy" />
              </Grid>
            </Grid>
            <Grid>
              <CurrencyContainer>
                {currency + " " + getPrice()}
              </CurrencyContainer>
            </Grid>
          </Grid>

          <Grid
            item
            style={{ flex: "1", display: "flex", justifyContent: "flex-end" }}
          >
            <Hidden smUp>
              <StyledFab type="submit" color="secondary">
                <img src={SaveIcon} alt="save" />
              </StyledFab>
            </Hidden>
            <Hidden xsDown>
              <LoadingButton
                variant="contained"
                color="secondary"
                type="submit"
                smallFont
                style={{
                  marginRight: "10px",
                }}
                className="save-plan-btn"
                submitting={submitting}
              >
                Save Plan
              </LoadingButton>
            </Hidden>
          </Grid>
        </Grid>
      </FormHeaderContainer>
    );
  }

  return null;
};

export default PlanHeading;
