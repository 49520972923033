import React from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import LoadingButton from "../../../common/LoadingButton";
import { getPrivacyIconAndLabel } from "../../../common/utils";
import { UsernamePrivacyContainer, TotalAmoutSpan } from "../styles";
import ImportTripDialog from "../../../common/ImportTripDialog/ImportTripDialog";
import { Hidden, Typography, Divider } from "@material-ui/core";
import moment from "moment";

const PlanHeading = (props) => {
  const {
    trip: { tripdata, data },
    type,
    auth: {
      userData: { currency },
    },
  } = props;

  const [isImportDialogOpen, setImportDialogOpen] = React.useState(false);
  const [importData, setImportData] = React.useState(undefined);

  const iconAndLabel = getPrivacyIconAndLabel(tripdata.trip_access_type);

  const handleImportTrip = () => {
    setImportDialogOpen(true);
    setImportData(tripdata);
  };

  const closeImportDialog = () => {
    setImportDialogOpen(false);
    setImportData(undefined);
  };

  const renderButton = () => {
    if (type === "community") {
      return (
        <LoadingButton
          color="secondary"
          variant="contained"
          onClick={handleImportTrip}
        >
          Import Trip
        </LoadingButton>
      );
    }

    if (type === "following") {
      return (
        <LoadingButton
          color="secondary"
          variant="contained"
          onClick={handleImportTrip}
        >
          Import Trip
        </LoadingButton>
      );
    }

    return (
      <LoadingButton
        color="secondary"
        variant="outlined"
        component={Link}
        to={`/trip-plan/edit/${tripdata.trip_id}`}
        className="edit-trip"
      >
        Edit Trip
      </LoadingButton>
    );
  };

  return (
    <Grid className="plan-heading-container" container justify="space-between">
      <Grid container item xs>
        <Grid item xs="auto">
          <Avatar
            alt={tripdata.user_name}
            src={tripdata.profile_pic}
            style={{
              marginRight: "10px",
            }}
          />
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <Grid item md={12}>
            <Typography variant="h4">{tripdata.name}</Typography>
            <TotalAmoutSpan>
              {`${currency} `}
              {tripdata.trip_cost
                ? type
                  ? tripdata.trip_local_cost
                  : tripdata.trip_cost
                : "0"}
            </TotalAmoutSpan>
          </Grid>
          <UsernamePrivacyContainer>
            <div className="date-privacy" style={{ display: "flex" }}>
              {!type && (
                <>
                  <Typography
                    className="trip-date"
                    color="secondary"
                    variant="body1"
                  >
                    {moment(tripdata.from_dt).format("Do")} -{" "}
                    {moment(tripdata.to_dt).format("Do MMM YY")}
                  </Typography>
                  <Divider flexItem orientation="vertical" />
                  <Typography color="secondary" variant="body1">
                    <Hidden xsDown>{iconAndLabel && iconAndLabel.label}</Hidden>
                    <Hidden smUp>
                      <img
                        src={iconAndLabel && iconAndLabel.icon}
                        alt="privacy"
                      />
                    </Hidden>
                  </Typography>
                </>
              )}
              {type && (
                <Typography gutterBottom color="secondary" variant="body1">
                  @{tripdata.user_name}
                </Typography>
              )}
            </div>
            {data.length > 0 && (
              <Grid item className="btn-container">
                {renderButton()}
              </Grid>
            )}
          </UsernamePrivacyContainer>
        </Grid>
      </Grid>
      {type && (
        <ImportTripDialog
          isOpen={isImportDialogOpen}
          handleClose={closeImportDialog}
          tripToImport={importData}
          type={type}
        />
      )}
    </Grid>
  );
};

export default PlanHeading;
