import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { ReactComponent as ExploreVector } from "../assets/landing-page/explore.svg";
import { ReactComponent as SearchVector } from "../assets/landing-page/search.svg";
import { ReactComponent as ShareVector } from "../assets/landing-page/share-vector.svg";
import { Link } from "react-router-dom";
import "./index.scss";
import { OnboardingContext } from "../Onboarding/OnboardingProvider";

export const LandingPage = (props) => {
  const onboardingContext = React.useContext(OnboardingContext);

  const [firstTimeOpen, setFirstTimeOpen] = useState(false);

  const { pathname } = props.location;
  console.log(props.location);

  useEffect(() => {
    if (
      (pathname === "/validatedLogin" || pathname === "/login") &&
      firstTimeOpen === false
    ) {
      setFirstTimeOpen(true);
      onboardingContext.setValidateOpen(false);
      onboardingContext.setLoginOpen(true);
    }
  }, [pathname, onboardingContext, firstTimeOpen]);

  useEffect(() => {
    if (pathname === "/set-password") {
      onboardingContext.setValidateOpen(true);
    }
  }, [pathname, onboardingContext]);

  return (
    <main className="yeteh-main">
      <section className="main-banner">
        <h1>
          Planning
          <br />
          <span>your next trip</span>
          <br />
          simplified
        </h1>
        <h5>
          Plan travel and log your
          <br />
          memories with us.
          <br />
          Get started.
        </h5>
        <Button
          onClick={() => {
            onboardingContext.setLoginOpen(true);
          }}
          variant="contained"
          color="secondary"
        >
          Log in
        </Button>
      </section>
      <section className="planning-banner" id="how-it-works">
        <h2>Simplify your planning</h2>
        <h5>3 steps to get you started!</h5>
      </section>
      <section className="info-section">
        <article>
          <div>
            <h2>Plan</h2>
            <p>
              For the adventurous spirit, planning a trip beyond the popular
              tourist spots can be rewarding and daunting at the same time.
              Often, travellers research scores of websites, blogs, social media
              posts toggling between one tab on the browser to another. A
              mapping software runs in the background where one tries all the
              permutations and combinations with the intention of covering most
              ground in an efficient way. Notes pile on and so does frustration.
              We are here to tell you that there is a much better way to plan
              the same trip. Yeteh allows travellers to note the itinerary, move
              things around and even store tickets and reservations very easily
              while browsing the internet on the same screen and guess what? The
              trip gets mapped automatically on the same screen as well. Travel
              planning has never been simpler.
            </p>
          </div>
          <div>
            <SearchVector />
          </div>
        </article>
        <article>
          <div>
            <h2>Record</h2>
            <p>
              Has a friend seen your social media post and asked you to share
              the details of the trip? Happens all the time. Do you have the
              details of the trip conveniently stored for your own record and
              someone else's? Rarely! Let's change that. Yeteh maintains your
              detailed travel itinerary, photos, maps and check-in's. So the
              next time you are reminiscing about a trip or looking up
              information for a friend, click the button and you shall have it
              in front of you.
            </p>
          </div>
          <div>
            <ShareVector />
          </div>
        </article>
        <article>
          <div>
            <h2>Share</h2>
            <p>
              The coolest feature of Yeteh is its social media capability.
              Follow your friends, relatives, travel bloggers and you will have
              access to their trips, itineraries and photos. But that's not even
              the best part. Our unique import feature will allow you to import
              someone else's trip into one that you are planning. Like a
              photograph someone has posted? Don't just hit the thumbs up,
              import the location tagged behind the photograph into a trip you
              are planning. At Yeteh, we want to elevate the social media
              experience and give travellers the opportunity to not only follow
              their favorite travel bloggers but use their itinerary as a
              template to create one's own trip.
            </p>
          </div>
          <div>
            <ExploreVector />
          </div>
        </article>
      </section>
      <section className="join-banner">
        <h2>Become a Participant by planning your next trip with us!</h2>
        <Button
          onClick={() => onboardingContext.setSignupOpen(true)}
          color="secondary"
          variant="contained"
        >
          Plan Now
        </Button>
      </section>
      <footer style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ margin: "5px 50px " }}>
          <h4>
            <a
              href="https://s3.ap-south-1.amazonaws.com/www.yeteh.com/Web-TERMS%26CONDITIONS.pdf"
              target="_blank"
            >
              Terms of Use
            </a>
            <Link
             to="/privacy-policy"
            >
              Privacy Policy
            </Link>

          </h4>
        </span>
        <span>
          <h4>Copyright &#169; 2020 Yeteh.com All Rights Reserved..</h4>
        </span>
        <span style={{ margin: "5px 50px " }}>
          <h4>v2.3.4</h4>
        </span>
      </footer>
    </main>
  );
};
