import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";

export const StyledGrid = styled(Grid)`
  background: ${(props) => props.theme.appBar};
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  overflow: hidden;
`;

export const StyledDialog = styled(({ ...other }) => (
  <Dialog
    {...other}
    classes={{
      paper: "paperContainer",
    }}
  />
))`
  .title {
    background: ${(props) => props.theme.primaryBlack};
    .heading {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  .dialog-body {
    padding: 16px 24px;
  }

  && .paperContainer {
    border-radius: 15px;
    max-width: ${(props) => (props.maxwidth ? props.maxwidth : "300px")};
    background: ${(props) => props.theme.appBar};

    .privacy-label {
      margin-bottom: 20px;
    }

    .privacy-option-checked {
      svg {
        color: #1dc7ea;
      }
    }
  }

  .activity-type {
    margin-top: 20px;
    max-width: 200px;
  }

  .upload-form-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }

  .photo-div {
    margin: 5px 10px;
    .privacy {
      width: auto;
      > div {
        width: auto;
        > div {
          > div {
            > div {
              display: flex;
            }
          }
        }
      }
    }
  }
`;

export const UploadedImg = styled.img`
  height: 124px;
  width: 166px;
  object-fit: cover;
`;

export const StyledPhotoDiv = styled.div`
  position: relative;
  z-index: 1;
  height: 150px;
  width: 166px;
  cursor: pointer;
  padding: 0px;
  overflow: hidden;
  flex-shrink: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    margin-top: 5px;
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.37);
  }
  img {
    height: 50px;
  }
`;
export const ActivityName = styled(Typography)`
  && {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const StyledItem = styled(ListItem)`
  && {
    cursor: pointer;
    font-size: 0.875rem;
    text-transform: capitalize !important;
    color: ${(props) => props.theme.secondaryWhite};
    svg {
      min-width: 13px;
      min-height: 13px;
      max-height: 13px;
      max-width: 13px;
      margin-right: 5px;
    }
  }

  &&:hover {
    background: ${(props) => props.theme.paleGreen};
    color: ${(props) => props.theme.appBar};
    svg {
      path {
        fill: ${(props) => props.theme.appBar};
      }
    }
  }
`;
