import React from "react";
import { Tooltip } from "@material-ui/core";
import { StyledPhotoCard, ActivityName, StyledImg } from "./styles";

const PhotoCard = ({
	photo,
	location,
	renderActions,
	onPhotoClick,
	renderHeader,
	ispersonal,
	style,
	imgStyle,
	btnStlye,
	className,
}) => {
	return (
		<StyledPhotoCard className={className} style={style}>
			{renderHeader && renderHeader(photo)}
			<StyledImg
				ispersonal={ispersonal ? 1 : 0}
				alt={photo.username}
				src={photo.trippic}
				onClick={onPhotoClick}
				style={imgStyle}
			/>
			<div>
				<Tooltip title={location}>
					<ActivityName>{location}</ActivityName>
				</Tooltip>
			</div>
			<div style={btnStlye} className="action-btns">
				{renderActions && renderActions()}
			</div>
		</StyledPhotoCard>
	);
};

export default PhotoCard;
