import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import withMobileDialog from "@material-ui/core/withMobileDialog";
// import { reset } from "redux-form";

const ResponsiveDialogPure = (props) => {
  const {
    isOpen,
    setIsOpen,
    handleAgree,
    title,
    contentText,
    fullScreen,
    renderContent,
    showActions = true,
    showTitle = true,
    contentClass = "",
    dialogClass = "",
  } = props;

  return (
    <Dialog
      classes={{
        paper: dialogClass,
      }}
      fullWidth
      open={isOpen}
      onClose={() => {
        //   setIsOpen(false); // This is commented to make sure forms are cleared before each close
      }}
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreen}
      maxWidth="md"
    >
      {showTitle && (
        <DialogTitle disableTypography={true}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography color="secondary" variant="h6">
                {title}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => setIsOpen(false)}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
      )}
      <DialogContent className={contentClass}>
        {contentText && <DialogContentText>{contentText}</DialogContentText>}
        {renderContent ? renderContent() : null}
      </DialogContent>
      {showActions && (
        <DialogActions>
          <Button
            variant={"text"}
            color="primary"
            onClick={() => setIsOpen(false)}
          >
            CANCEL
          </Button>
          <Button
            type="submit"
            variant={"contained"}
            color="secondary"
            onClick={handleAgree}
            autoFocus
          >
            DELETE
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default withMobileDialog()(ResponsiveDialogPure);
