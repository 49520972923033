import React from "react";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Close from "@material-ui/icons/Close";
import moment from "moment";
import { Link, withRouter } from "react-router-dom";
import { stringify } from "query-string";
import { StyledDialog, TripCardCotainer } from "./styles";
import LoadingButton from "../../common/LoadingButton";
import { getTrips, clearMyTrips } from "../../Profile/duck";
import LoadingScreen from "../LoadingScreen";
import { MY_ID } from "../../constants";
import { uid } from "react-uid";
import { ReactComponent as ImportIcon } from "../../assets/profile/import.svg";
import { ReactComponent as AddIcon } from "../../assets/profile/add.svg";
import { activityImportChip } from "../utils";

// import "./index.scss";

const createQueryParams = (tripToImport) => {
  const queryParams = {};
  queryParams.city = tripToImport.city;
  queryParams.name = tripToImport.name;
  queryParams.lat = tripToImport.lat;
  queryParams.longi = tripToImport.longi;
  // queryParams.origin = tripToImport.origin;
  queryParams.newTripId = tripToImport.trip_id;
  queryParams.from_dt = moment(); //.format("DD/MM/yyyy");
  queryParams.to_dt = moment(); //.format("DD/MM/yyyy");
  queryParams.type = "1";
  return stringify(queryParams);
};

const createActivityParams = (tripToImport) => {
  return stringify(activityImportChip(tripToImport));
};

const ImportTripDialog = (props) => {
  const {
    isOpen: open,
    handleClose,
    fullScreen,
    getTrips,
    trips: { loading },
    tripToImport,
    isPhoto,
    history,
    type,
    clearMyTrips,
  } = props;

  const friendsTrips = props.trips.trips;

  React.useEffect(() => {
    if (open) {
      clearMyTrips();
      getTrips();
    }
    return function cleanup() {};
  }, [getTrips, open]);

  const handleTripClick = (trip) => {
    if (!isPhoto) {
      return history.push(
        `/trip-plan/edit/${trip.trip_id}/${type}/${tripToImport.trip_id}`
      );
    }
    return history.push(
      `/trip-plan/edit/${trip.trip_id}/import?${createActivityParams(
        tripToImport
      )}`
    );
  };

  const renderTripList = () => {
    if (friendsTrips && friendsTrips.length > 0) {
      return (
        <div className="list-container">
          {friendsTrips.map((trip, index) => (
            <TripCardCotainer
              key={uid(trip, index)}
              onClick={() => handleTripClick(trip)}
            >
              <Typography className="trip-name" variant="body1">
                {trip.name}
              </Typography>
              <Typography className="trip-date" variant="body2">
                {moment(trip.from_dt).format("Do")} -{" "}
                {moment(trip.to_dt).format("Do MMM YY")}
              </Typography>
            </TripCardCotainer>
          ))}
        </div>
      );
    }
    return null;
  };

  if (loading || !friendsTrips || !tripToImport) {
    return (
      <StyledDialog
        fullWidth={true}
        maxWidth="sm"
        fullScreen={fullScreen}
        open={open}
      >
        <LoadingScreen />
      </StyledDialog>
    );
  }

  return (
    <StyledDialog
      fullWidth={true}
      maxWidth="sm"
      fullScreen={fullScreen}
      open={open}
      showbg={1}
    >
      <DialogTitle className="dialog-title" disableTypography={true}>
        <Grid
          container
          wrap="nowrap"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography className="heading">
              How do you want to import this?
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color="primary" onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div>
        <div className="btn-creator">
          {true && (
            <span>
              <LoadingButton
                component={Link}
                to={`/trip-plan/create?${createQueryParams(tripToImport)}`}
                classes={{ root: "import-new-root" }}
                className="import-new"
              >
                <AddIcon /> Import & Create New Trip
              </LoadingButton>
            </span>
          )}
          <span>
            <LoadingButton
              disabled
              classes={{ root: "import-new-root" }}
              className="import-new"
            >
              <ImportIcon style={{ width: "24px" }} /> Import to existing Trip ?
            </LoadingButton>
          </span>
        </div>
        {renderTripList()}
      </div>
    </StyledDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    trips: state.trips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTrips: () => dispatch(getTrips(MY_ID)),
    clearMyTrips: () => dispatch(clearMyTrips()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withMobileDialog()(ImportTripDialog))
);
