import React from "react";
import styled from "styled-components";
import Select from "@material-ui/core/Select";
import List from "@material-ui/core/List";
import RadioGroup from "@material-ui/core/RadioGroup";

export const StyledSelect = styled(({ ...other }) => (
  <Select
    {...other}
    classes={{
      select: "disabled-select",
    }}
  />
))`
  && .disabled-select {
    padding-right: ${(props) => (props.disabled ? 0 : "")};
    padding: ${(props) => props.padding}
    display: ${(props) => props.display}
  }
`;

export const StyledList = styled(List)`
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: ${(props) => props.theme.appBar};
  position: fixed;
  z-index: 1;
`;

export const StyledRadioGroup = styled(RadioGroup)`
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  margin-top: -6px;
  .primary-color {
    color: ${(props) => props.theme.white};
  }
`;

export const multiStyles = (theme) => ({
  horizontalLabel: {
    textAlign: "right",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
    },
  },
  paddingTop: {
    [theme.breakpoints.up("md")]: {
      marginTop: 10,
    },
  },
});
