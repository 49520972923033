import { connect } from "react-redux";
import {
  compose,
  lifecycle,
  branch,
  renderComponent,
  withHandlers,
  withState,
} from "recompose";
import LoadingScreen from "../common/LoadingScreen";
import { fetchTrip } from "../TourPlan/duck";
import { axiosInst, axiosConfig } from "../axios";
import { createMarkers } from "../common/utils";
import { MY_ID, FRIENDS_ID, COMMUNITY_ID } from "../constants";
import { enqueueSnackbar } from "../Master/Notifier/duck";

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    trip: state.trip,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTrip: (id, type) => dispatch(fetchTrip(id, type)),
    showSnackbar: (message, variant) =>
      dispatch(
        enqueueSnackbar({
          message: message,
          variant: variant,
        })
      ),
  };
};

const TripExplorerHandlers = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const {
        fetchTrip,
        match: {
          params: { type },
        },
      } = this.props;
      if (this.props.match.params.id) {
        let dataType = MY_ID;
        if (type && type === "following") {
          dataType = FRIENDS_ID;
        } else if (type && type === "community") {
          dataType = COMMUNITY_ID;
        }
        fetchTrip(this.props.match.params.id, dataType);
      }
    },
  }),
  branch((props) => {
    const { trip } = props;
    if (props.match.params.id && trip.loading) {
      return true;
    } else {
      return false;
    }
  }, renderComponent(LoadingScreen)),
  withState("initialValues", "setValues", (props) => {
    let initialValues = {};
    if (props.trip.data.length > 0) {
      initialValues = {
        plan: props.trip.data.map((day, index) => {
          const dayObj = {
            startingplace: {
              id: day.trip_day_id,
              lat: +day.lat,
              lng: +day.long,
              address: day.place || " ",
              city: day.city,
            },
            activities: day.activitydata.map((activity, index) => {
              const activityObj = {
                activityname: {
                  id: activity.activity_id,
                  lat: +activity.lat,
                  lng: +activity.long,
                  address: activity.place_from || " ",
                  city: activity.city,
                },
                to: {
                  lat: +activity.place_to_lat,
                  lng: +activity.place_to_long,
                  address: activity.place_to || " ",
                  city: activity.place_to_city,
                },
                activitytype: activity.type,
              };
              return activityObj;
            }),
          };
          return dayObj;
        }),
      };
    } else {
      initialValues = {
        plan: [
          {
            startingplace: "",
            activities: [
              {
                activityname: "",
                activitytype: "",
              },
            ],
          },
        ],
      };
    }
    return initialValues;
  }),
  withState("markers", "setMarkers", (props) => {
    const { initialValues } = props;
    return createMarkers(initialValues);
  }),
  withState("duration", "setDuration"),
  withState("checkboxes", "setCheckboxes", (props) => {
    let checkboxes = {};
    if (props.trip.data.length > 0) {
      props.trip.data.forEach((day, index) => {
        day.activitydata.forEach((activity, index) => {
          checkboxes[activity.activity_id] =
            activity.activity_status !== 0 ? true : false;
        });
      });
    }
    return checkboxes;
  }),
  withHandlers({
    handleCheckboxChange: (props) => (activityId, activity, day) => {
      const {
        checkboxes,
        setCheckboxes,
        trip: {
          tripdata: { trip_id },
        },
        showSnackbar,
        auth: { token },
        markers,
        setMarkers,
      } = props;

      axiosInst
        .post(
          "/ongoingeditstatus",
          {
            trip_id: String(trip_id),
            trip_activity_id: String(activity.activity_id),
            trip_day_id: String(day.trip_day_id),
            activity_status: !checkboxes[activityId] ? 1 : 0,
          },
          axiosConfig(token)
        )
        .then((res) => {
          if (res.data.status === 200) {
            const newState = {
              [activityId]: !checkboxes[activityId],
            };
            let newMarkers = [];
            markers.ungrouped.map((marker) => {
              let newMarker = { ...marker };
              if (
                marker.activityId &&
                !checkboxes[activityId] &&
                marker.activityId === +activityId
              ) {
                newMarker.checkedIn = true;
              } else {
                newMarker.checkedIn = false;
              }
              newMarkers.push(newMarker);
              return newMarker;
            });
            setMarkers({ ...markers, ungrouped: newMarkers });
            setCheckboxes({ ...checkboxes, ...newState });
          } else {
            showSnackbar("Some error occured", "error");
          }
        })
        .catch((err) => {
          showSnackbar("Some error occured", "error");
        });
    },
  }),
  withHandlers({
    handleActivityNote: (props) => (activity, day, onNoteChange) => {
      console.log(props);
      const {
        checkboxes,
        trip: {
          tripdata: { trip_id },
        },

        auth: { token },
      } = props;

      if (onNoteChange) {
        return axiosInst
          .post(
            "/ongoingeditstatus",
            {
              trip_id: String(trip_id),
              trip_activity_id: String(activity.activity_id),
              trip_day_id: String(day.trip_day_id),
              activity_status: checkboxes[activity.activity_id] ? 1 : 0,
              note: onNoteChange,
            },
            axiosConfig(token)
          )
          .then((res) => console.log(res))
          .catch((err) => console.log(err));
      }
    },
  })
);

export default TripExplorerHandlers;
