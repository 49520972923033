export const GET_PERSONAL_PHOTOS_REQUEST = "GET_PERSONAL_PHOTOS_REQUEST";
export const GET_FOLLOWING_PHOTOS_REQUEST = "GET_FOLLOWING_PHOTOS_REQUEST";
export const GET_COMMUNITY_PHOTOS_REQUEST = "GET_COMMUNITY_PHOTOS_REQUEST";

export const GET_PERSONAL_PHOTOS_SUCCESS = "GET_PERSONAL_PHOTOS_SUCCESS";
export const GET_FOLLOWING_PHOTOS_SUCCESS = "GET_FOLLOWING_PHOTOS_SUCCESS";
export const GET_COMMUNITY_PHOTOS_SUCCESS = "GET_COMMUNITY_PHOTOS_SUCCESS";

export const GET_PERSONAL_PHOTOS_FAILURE = "GET_PERSONAL_PHOTOS_FAILURE";
export const GET_FOLLOWING_PHOTOS_FAILURE = "GET_FOLLOWING_PHOTOS_FAILURE";
export const GET_COMMUNITY_PHOTOS_FAILURE = "GET_COMMUNITY_PHOTOS_FAILURE";

export const FRIEND_PHOTO_LIKE_SUCCESS = "FRIEND_PHOTO_LIKE_SUCCESS";
export const COMMUNITY_PHOTO_LIKE_SUCCESS = "COMMUNITY_PHOTO_LIKE_SUCCESS";
export const USER_PHOTO_LIKE_SUCCESS = "USER_PHOTO_LIKE_SUCCESS";
