export const GETTRIP_REQUEST = "GETTRIP_REQUEST";
export const GET_FRIEND_TRIP_REQUEST = "GET_FRIEND_TRIP_REQUEST";
export const GET_COMMUNITY_TRIP_REQUEST = "GET_COMMUNITY_TRIP_REQUEST";

export const GETTRIP_SUCCESS = "GETTRIP_SUCCESS";
export const GET_FRIEND_TRIP_SUCCESS = "GET_FRIEND_TRIP_SUCCESS";
export const GET_COMMUNITY_TRIP_SUCCESS = "GET_COMMUNITY_TRIP_SUCCESS";

export const GETTRIP_FAILURE = "GETTRIP_FAILURE";
export const GET_FRIEND_TRIP_ERROR = "GET_FRIEND_TRIP_ERROR";
export const GET_COMMUNITY_TRIP_ERROR = "GET_COMMUNITY_TRIP_ERROR";

export const DELETE_TRIP_REQUEST = "DELETE_TRIP_REQUEST";
export const DELETE_TRIP_SUCCESS = "DELETE_TRIP_SUCCESS";
export const DELETE_TRIP_FAILURE = "DELETE_TRIP_FAILURE";

export const GET_CHECKIN_REQUEST = "GET_CHECKIN_REQUEST";
export const GET_CHECKIN_SUCCESS = "GET_CHECKIN_SUCCESS";
export const GET_CHECKIN_FAILURE = "GET_CHECKIN_FAILURE";

export const CLEAR_MY_TRIPS = "CLEAR_MY_TRIPS";
