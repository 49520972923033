import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { reducer as formReducer } from "redux-form";
import { authReducer } from "./Onboarding/duck";
import { tripReducer, importTripReducer } from "./TourPlan/duck";
import notifierReducer from "./Master/Notifier/duck";
import {
  tripsReducer,
  deleteTripReducer,
  checkinsReducer
} from "./Profile/duck";
import { peopleReducer } from "./Profile/People/duck/reducers";
import { photosReducer } from "./Profile/Photos/duck/reducers";
import { notificationsReducer } from "./Master/AppBar/NotificationDrawer/duck/reducers";
import { nearbyReducer } from "./TripExplorer/duck/reducers";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["auth"],
  stateReconciler: autoMergeLevel2
};

const rootReducer = combineReducers({
  auth: authReducer,
  trip: tripReducer,
  checkins: checkinsReducer,
  form: formReducer,
  snackbar: notifierReducer,
  trips: tripsReducer,
  deleteTripStatus: deleteTripReducer,
  photos: photosReducer,
  people: peopleReducer,
  notifications: notificationsReducer,
  importTrip: importTripReducer,
  nearby: nearbyReducer
});

export default persistReducer(persistConfig, rootReducer);
