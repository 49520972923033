import React from "react";
import { Map, GeoJSON, Marker, Popup } from "react-leaflet";
import { ContainerDiv } from "./styles";
import "react-leaflet-fullscreen/dist/styles.css";
import FullscreenControl from "react-leaflet-fullscreen";
import L from "leaflet";
//import LCG from "leaflet-control-geocoder";

const pointerIcon = new L.Icon({
  iconUrl: require("../assets/profile/marker.svg"),
  iconAnchor: [0, 0], //[7, 41], These old numbers were putting markers out of position
  popupAnchor: [0, 0], //[10, -44],
  iconSize: [10, 10],
});

const visitedStyle = {
  color: "rgba(255, 255, 255, 0.87)",
  fillColor: "rgba(255, 255, 255, 0.7)",
  weight: 1,
  opacity: 1,
  fillOpacity: 1,
};

const countriesStyle = {
  color: "rgba(255, 255, 255, 0.37)",
  fillColor: "#2E2E2E",
  weight: 1,
  opacity: 1,
  fillOpacity: 1,
};

const WorldMapPure = (props) => {
  const { lat, lng, markers, countries, visited } = props;
  const position = [lat, lng];
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  return (
    <ContainerDiv>
      <Map
        style={{
          background: "#2E2E2E",
        }}
        center={position}
        minZoom={2}
        zoom={1.5}
        zoomControl={false}
        attributionControl={false}
        bounds={L.latLngBounds([
          [85, -180],
          [-85, 180],
        ])}
        boundsOptions={{ padding: [50, 50] }}
        maxBounds={L.latLngBounds([
          [85, -180],
          [-85, 180],
        ])}
      >
        {!iOS && (
          <FullscreenControl
            forcePseudoFullscreen={false}
            position="topright"
          />
        )}
        <GeoJSON data={countries} style={countriesStyle} />
        {visited.map(
          (key, index) =>
            key && (
              <GeoJSON
                key={key.properties.name + index}
                data={key}
                style={visitedStyle}
              />
            )
        )}
        {markers.map((marker, index) => (
          <Marker icon={pointerIcon} key={index} position={marker}>
            <Popup>{visited[index].id}</Popup>
          </Marker>
        ))}
      </Map>
    </ContainerDiv>
  );
};

export default WorldMapPure;
