import React from "react";
import { Hidden } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import { StyledFab, StyledButton } from "./styles";

const ActionBtn = (props) => {
  const { label, to, onClick, className } = props;

  if (to) {
    return (
      <>
        <Hidden smDown>
          <StyledButton
            className={className}
            color="secondary"
            variant="contained"
            component={Link}
            to={to}
          >
            <AddIcon /> {label}
          </StyledButton>
        </Hidden>
        <Hidden mdUp>
          <StyledFab
            className={className}
            color="secondary"
            component={Link}
            to={to}
          >
            <AddIcon />
          </StyledFab>
        </Hidden>
      </>
    );
  }

  return (
    <>
      <Hidden smDown>
        <StyledButton
          className={className}
          color="secondary"
          variant="contained"
          onClick={onClick}
        >
          <AddIcon /> {label}
        </StyledButton>
      </Hidden>
      <Hidden mdUp>
        <StyledFab className={className} color="secondary" onClick={onClick}>
          <AddIcon />
        </StyledFab>
      </Hidden>
    </>
  );
};

export default ActionBtn;
