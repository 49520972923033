import React, { useState } from "react";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { StyledTabs, StyledTab } from "../styles";
import Personal from "./Personal";
import Friends from "./Friends";
import Community from "./Community";
import SearchBar from "../../common/SearchBar";
import ActionBtn from "../ActionBtn";
import ComponentWrapper from "../ComponentWrapper";
import { getType } from "../../common/utils";
import { getPhotos, getUserPhotos } from "./duck";
import AddPhotosDialog from "./AddPhotosDialog";
import { MY_ID } from "../../constants";

const TABS = ["Photos", "Following", "Community"];

const USER_TABS = ["Photos"];

const Photos = ({ getPhotos, userId }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [isPhotoDialogOpen, setPhotoDialog] = useState(false);
  const [isSearchVisible, setSearchVisible] = useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getTabs = () => {
    if (userId) {
      return USER_TABS;
    }

    return TABS;
  };

  const handleSearch = (val) => {
    setSearchVal(val);
    getPhotos(getType(tabIndex), val);
  };

  const handleTabChange = (e, index) => {
    setTabIndex(index);
    setSearchVal("");
  };

  const handleAddPhotos = () => {
    setPhotoDialog(true);
  };

  return (
    <>
      <StyledTabs
        value={tabIndex}
        onChange={handleTabChange}
        isSearchVisible={isSearchVisible ? 1 : 0}
      >
        {getTabs().map(
          (tab) =>
            !isSearchVisible && (
              <StyledTab key={tab} label={tab} disableRipple />
            )
        )}
        {!userId && <ActionBtn onClick={handleAddPhotos} label="Add Photos" />}
        {!isSearchVisible && (
          <StyledTab
            className="search-bar search-bar-desktop"
            disabled
            label={
              <SearchBar
                onChange={handleSearch}
                initialSearchValue={searchVal}
                label="Search"
              />
            }
          />
        )}
        <StyledTab
          className="search-bar search-bar-mobile"
          disabled
          label={
            <div className="search-container">
              {!isSearchVisible && (
                <SearchIcon onClick={() => setSearchVisible(true)} />
              )}
              {isSearchVisible && (
                <span>
                  <SearchBar
                    onChange={handleSearch}
                    initialSearchValue={searchVal}
                    label="Search"
                  />
                  <ClearIcon
                    color="primary"
                    onClick={() => setSearchVisible(false)}
                  />
                </span>
              )}
            </div>
          }
        />
      </StyledTabs>
      <ComponentWrapper item xs={12}>
        {tabIndex === 0 && <Personal userId={userId} />}
        {tabIndex === 1 && <Friends />}
        {tabIndex === 2 && <Community />}
      </ComponentWrapper>
      {isPhotoDialogOpen && (
        <AddPhotosDialog
          isOpen={isPhotoDialogOpen}
          handleClose={() => setPhotoDialog(false)}
          getPersonalPhotos={() => getPhotos(MY_ID, "")}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch, { userId }) => {
  if (userId) {
    return {
      getPhotos: (type, name) => dispatch(getUserPhotos(userId, 0, name)),
    };
  }
  return {
    getPhotos: (type, name) => dispatch(getPhotos(type, 0, name)),
  };
};

export default connect(null, mapDispatchToProps)(Photos);
