import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import Fab from "@material-ui/core/Fab";

export const PlanContainer = styled(Grid)`
  position: relative;
  height: 100%;
  .plan-heading {
    border-bottom: 1px solid #777777;
    padding: 5px 20px;
    .privacy-type-container {
      display: flex;
    }
    .privacy-type {
      font-weight: 700;
      white-space: nowrap;
    }

    .plan-date {
      margin-right: 10px;
      white-space: nowrap;
    }
  }
  .plan-form {
    padding: 20px;
  }

  .save-plan-btn {
    // background: ${(props) => props.theme.paleGreen};
  }

  .save-plan-btn:hover {
    // background: ${(props) => props.theme.green};
  }

  .plan-title-container {
    @media (max-width: 1024px) {
      max-width: 76%;
    }
  }
`;

export const StyledFab = styled(Fab)`
  && {
    position: fixed;
    right: 5px;
    bottom: 50px;
    z-index: 1;
  }
`;

export const CurrencyContainer = styled.span`
  border-radius: 50px;
  background-color: #4fc099;
  padding: 3px 7px;
  font-size: 10px;
  font-weight: 500;
  color: #000;
  margin-top: -505px;
`;

export const FormHeaderContainer = styled.div`
  // position: sticky;
  // top: 0px;
  // left 20px;
  // z-index :100;
`;
