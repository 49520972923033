import { reduxForm } from "redux-form";
import { compose } from "recompose";
import { signupApi } from "../services";
import { enqueueSnackbar } from "../../Master/Notifier/duck";

const signupSubmit = (values, dispatch, props) => {
  const formData = {
    email: values.email,
    first_name: values.first_name,
    user_name: values.email,
    last_name: values.last_name,
    password: values.password,
    currency: values.currency,
    access_code: "30313", //values.access_code,
  };
  return signupApi(formData)
    .then((res) => {
      if (res.data.status === 200) {
        dispatch(
          enqueueSnackbar({
            message:
              "Sign up successful. Please check email/spambox to validate account",
            variant: "success",
          })
        );
        //  props.history.push("./login");
        props.reset("SignupPure");
        props.setIsOpen(false);
        props.setLoginOpen(true);
      } else {
        dispatch(
          enqueueSnackbar({
            message: res.data.message,
            variant: "error",
          })
        );
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        enqueueSnackbar({
          message: err.response.data.message,
          variant: "error",
        })
      );
    });
};

const SignupHandlers = compose(
  reduxForm({
    form: "signup-form",
    onSubmit: signupSubmit,
  })
);

export default SignupHandlers;
