import {
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  UPDATE_USER_DATA
} from "./types";

const INITIAL_STATE = {
  loading: false,
  isLoggedIn: false,
  error: undefined,
  token: undefined,
  userData: undefined
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case LOGIN_SUCCESS: {
      return {
        isLoggedIn: true,
        error: undefined,
        token: action.payload.token,
        userData: { ...action.payload }
      };
    }
    case UPDATE_USER_DATA: {
      return {
        ...state,
        userData: Object.assign(state.userData, action.payload)
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        isLoggedIn: false,
        error: action.payload
      };
    }
    case LOGOUT_REQUEST: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default authReducer;
