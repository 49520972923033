import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

const mapStateToProps = state => {
  return { auth: state.auth };
};

const LoginRouteHandlers = compose(
  withRouter,
  connect(
    mapStateToProps,
    null
  )
);

export default LoginRouteHandlers;
