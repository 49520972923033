import { compose, withState } from "recompose";
import { connect } from "react-redux";
import { deletePhoto, getNearbyPlaces } from "../../duck/actions";

const mapDispatchToProps = (dispatch, { fetchTrip }) => {
  return {
    deletePhoto: (photoId) => {
      dispatch(deletePhoto(photoId, fetchTrip));
    },
    getNearbyPlaces: (place, type) => {
      dispatch(getNearbyPlaces(place, type));
    },
  };
};

const DetailsHandelrs = compose(
  connect(null, mapDispatchToProps),
  withState("isOpen", "setIsOpen", false),
  withState("images", "setImages", []),
  withState("photoIndex", "setIndex", 0)
);

export default DetailsHandelrs;
