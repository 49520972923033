import { Component } from "react";
import { isWidthUp } from "@material-ui/core/withWidth";

const snackbarOptions = (variant, width) => {
  let pos = { vertical: "bottom", horizontal: "center" };
  if (isWidthUp("sm", width)) {
    pos = { vertical: "bottom", horizontal: "center" };
  }
  return {
    variant: variant,
    anchorOrigin: pos
  };
};

class NotifierComponent extends Component {
  displayed = [];

  storeDisplayed = id => {
    this.displayed = [...this.displayed, id];
  };

  componentDidMount() {
    const { notifications = [], width } = this.props;
    notifications.forEach(notification => {
      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(notification.key)) return;
      // Display snackbar using notistack
      this.props.enqueueSnackbar(
        notification.message,
        snackbarOptions(notification.variant, width)
      );
      // Keep track of snackbars that we've displayed
      this.storeDisplayed(notification.key);
      // Dispatch action to remove snackbar from redux store
      this.props.removeSnackbar(notification.key);
    });
  }

  shouldComponentUpdate({ notifications: newSnacks = [] }) {
    const { notifications: currentSnacks } = this.props;
    let notExists = false;
    for (let i = 0; i < newSnacks.length; i += 1) {
      if (notExists) continue;
      notExists =
        notExists ||
        !currentSnacks.filter(({ key }) => newSnacks[i].key === key).length;
    }

    return notExists;
  }

  componentDidUpdate() {
    const { notifications = [], width } = this.props;
    notifications.forEach(notification => {
      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(notification.key)) return;
      // Display snackbar using notistack
      this.props.enqueueSnackbar(
        notification.message,
        snackbarOptions(notification.variant, width)
      );
      // Keep track of snackbars that we've displayed
      this.storeDisplayed(notification.key);
      // Dispatch action to remove snackbar from redux store
      this.props.removeSnackbar(notification.key);
    });
  }

  render() {
    return null;
  }
}

export default NotifierComponent;
