import { compose, withProps } from "recompose";
import { connect } from "react-redux";
import { countries, countriesMap } from "./data";

import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyDvMqPmKwSrMqxiwFC-rMq20h72wC6yH58");
// Geocode.setApiKey("AIzaSyCakXjVhn38TJdt8lf3fjAh0chOlhkVg30");
const mapStateToProps = (state) => {
	return {
		checkins: state.checkins,
	};
};

const WorldMapHandelrs = compose(
	connect(mapStateToProps, null),
	withProps((props) => {
		const {
			checkins: {
				checkins: { location_details },
				loading,
				error,
			},
		} = props;
		let markers = [];
		let visited = [];
		// let addresses = [];
		// let count = 0;
		if (!loading && !error) {
			const countryMapObj = countriesMap();

			location_details.forEach((trip) => {
				let visitedObj = countryMapObj[trip.activity_city];
				if (visitedObj) {
					markers.push([trip.activity_lat, trip.activity_longi]);
					visited.push(visitedObj);
				}
				/*  Geocode.fromLatLng(trip.activity_lat, trip.activity_longi)
          .then(
            (response) => {
              addresses.push(response.results[0].formatted_address);
              count = count + 1;
            },
            (error) => {
              addresses.push(error);
            }
          );*/
			});
		}
		return {
			lat: 51.505,
			lng: -0.09,
			zoom: 1,
			markers,
			countries,
			visited,
		};
	})
);

export default WorldMapHandelrs;
