import { connect } from "react-redux";
import { compose } from "recompose";
import { reduxForm, arrayPush, getFormValues } from "redux-form";
import { submitForm } from "./submitTripForm";
import { enqueueSnackbar } from "../../Master/Notifier/duck";

const mapStateToProps = (state) => {
  return {
    values: getFormValues("trip-plan-form")(state),
    auth: state.auth,
    trip: state.trip,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addActivity: (fieldName) =>
      dispatch(arrayPush("trip-plan-form", fieldName, {})),
    showSnackBar: (message, variant) =>
      dispatch(
        enqueueSnackbar({
          message: message,
          variant: variant ? variant : "success",
        })
      ),
  };
};

const onSubmitTripFormError = (errors, dispatch, submitErro, props) => {
  console.log(errors);
  props.showSnackBar("Day or Activity field incomplete or Wrong", "error");
};

const TourPlanHandlers = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "trip-plan-form",
    onSubmit: submitForm,
    onSubmitFail: onSubmitTripFormError,
  }),
  connect(mapStateToProps, mapDispatchToProps)
);

export default TourPlanHandlers;
