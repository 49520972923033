import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ReactComponent as UploadIcon } from "../../assets/profile/add-photo.svg";
import { Field } from "redux-form";
import MenuItem from "@material-ui/core/MenuItem";
import { StyledDialog, StyledPhotoDiv, UploadedImg } from "./styles";
import ReduxFormTextField from "../../common/FormFields/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import FileInputField from "../../common/FormFields/FileUpload";
import {
  maxPhotos,
  fileSize,
  required,
} from "../../common/FormFields/validations";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import { PRIVACY_OPTIONS } from "../../constants";
import LoadingButton from "../../common/LoadingButton";

const maxPhotos7 = maxPhotos(7);
const fileSize10 = fileSize(100000000);

const createPhotos = (filesData) => {
  return filesData.map((photo) => ({
    src: photo.data,
    title: "local",
  }));
};

const PhotoUploadDialogPure = (props) => {
  const {
    submitting,
    handleSubmit,
    fullScreen,
    handleClose,
    open,
    selected,
    removePhoto,
  } = props;

  // console.log(selected);

  let photos = [];
  if (props.values) {
    if (props.values.photos) {
      photos = createPhotos(props.values.photos);
    }
  }
  return (
    <StyledDialog
      fullWidth={true}
      maxWidth={"md"}
      maxwidth="750px"
      fullScreen={fullScreen}
      open={open}
    >
      <DialogTitle disableTypography={true} className="title">
        <Grid
          container
          wrap="nowrap"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography className="heading" color="secondary">
              Log your Trip and Memories
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              position: "absolute",
              right: "0",
            }}
          >
            <IconButton color="primary" onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <div className="dialog-body">
        <Typography color="primary">
          Upload photos and add caption for your activity
        </Typography>
        <Typography color="primary" gutterBottom>
          {selected.activity && selected.activity.place_from}
        </Typography>
        <form onSubmit={handleSubmit}>
          <StyledPhotoDiv>
            <UploadIcon />
            <Typography>Must be 7 photos or less. Minimum Photo width 500 pixels</Typography>
            <Field
              multi
              multiple
              accept="image/*"
              name="photos"
              validate={[maxPhotos7, fileSize10]}
              component={FileInputField}
            />
          </StyledPhotoDiv>
          <div className="upload-form-container">
            {photos.map((photo, index) => (
              <div key={photo.id} className="photo-div">
                <div className="privacy">
                  <Field
                    name={`privacy[${index}]`}
                    select
                    padding="0 20px 0 0"
                    component={ReduxFormTextField}
                    validate={required}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  >
                    {PRIVACY_OPTIONS.map((opt, index) => (
                      <MenuItem key={index} value={opt.value}>
                        <img src={opt.icon} alt={opt.label} />
                      </MenuItem>
                    ))}
                  </Field>
                </div>
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <CancelIcon
                    style={{
                      position: "absolute",
                      right: "-12px",
                      top: "-14px",
                      color: "#08E8DE",
                      background: "#292929",
                      borderRadius: "12px",
                      cursor: "pointer",
                      height: "24px",
                      width: "24px",
                    }}
                    onClick={() => removePhoto(index, props.values.photos)}
                  />
                  <UploadedImg src={photo.src} alt={photo.title} />
                </div>
              </div>
            ))}
          </div>
          <div>
            <Typography color="primary">Add Caption</Typography>
            <Field
              name="caption"
              multiline
              rows={5}
              nativeInputProps={{
                style: {
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid white",
                },
              }}
              InputProps={{
                disableUnderline: true,
              }}
              component={ReduxFormTextField}
            />
          </div>
          <LoadingButton
            variant="contained"
            color="secondary"
            type="submit"
            submitting={submitting}
            disabled={photos.length === 0}
          >
            Upload
          </LoadingButton>
        </form>
      </div>
    </StyledDialog>
  );
};

export default withMobileDialog()(PhotoUploadDialogPure);
