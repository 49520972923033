import { compose } from "recompose";
import { reduxForm, getFormValues, change } from "redux-form";
import { connect } from "react-redux";
import { enqueueSnackbar } from "../../Master/Notifier/duck";
import { compress } from "../../common/utils";
import { uploadPhotoApi } from "../../Profile/Photos/duck/services";

const mapStateToProps = (state, props) => {
  return {
    values: getFormValues("photo-upload-dialog")(state),
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removePhoto: (id, photos) => {
      photos = photos.filter((photo, index) => index !== id);
      dispatch(change("photo-upload-dialog", "photos", photos));
    }
  };
};

const handlePhotoUpload = async (values, dispatch, props) => {
  const {
    selected: {
      activity: { activity_id, type, lat, long, city, place_from }
    },
    handleClose,
    auth: { token },
    fetchTrip
  } = props;

  const photoPromises = [];

  (values.photos || []).forEach((photo, index) => {
    
    photoPromises.push(compress(photo.file, 500).then(compressFiled => {
      let formData = new FormData();
     
      const formValues = {
        type: values.privacy[index],
        upl: compressFiled,
        trip_activity_id: String(activity_id),
        activity_type: type,
        lat,
        longi: long,
        city,
        location: place_from
      };
      if (values.caption) {
        formValues.comment = values.caption;
      }
      Object.keys(formValues).forEach(key => {
        formData.append([key], formValues[key]);
      });
      console.log("formdata is here",formData)
      return uploadPhotoApi(token, formData).then(resp => {
        if (resp.data.status === 200) {
          return resp;
        }
        throw resp;
      }).catch(err => err);
    }));
  });





  try {
    const res = await Promise.all(photoPromises);
    if (res && res[0] && res[0].data.status === 200) {
      dispatch(enqueueSnackbar({
        message: "Success",
        variant: "success"
      }));
      handleClose();
      fetchTrip();
    }
    else {
      throw res;
    }
  }
  catch (err_1) {
    console.log("err1",err_1)
    dispatch(enqueueSnackbar({
      message: "Some error occured",
      variant: "error"
    }));
  }
};

const PhotoUploadDialogHandlers = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: "photo-upload-dialog",
    onSubmit: handlePhotoUpload,
    destroyOnUnmount: true
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default PhotoUploadDialogHandlers;
