import React from "react";
import { StyledSearch } from "./styles";
import { InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

let delayTimer;

const SearchBar = (props) => {
  const { onChange, initialSearchValue, className } = props;
  const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    if (!initialSearchValue) {
      setSearch("");
    }
  }, [initialSearchValue]);

  const handleChange = (event) => {
    setSearch(event.target.value);
    const val = event.target.value;
    clearTimeout(delayTimer);
    delayTimer = setTimeout(() => {
      onChange(val);
    }, 300);
  };

  return (
    <StyledSearch
      className={className}
      placeholder="Search"
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      value={search}
      onChange={handleChange}
    />
  );
};

export default SearchBar;
