import moment from "moment";

export const required = (value) => (value ? undefined : "Required");

export const requiredAddress = (value) => {
  const error = (value && !value.address) || !value ? "Required" : undefined;
  return error;
};

export const spaces = (value) =>
  value.split(" ").length > 1 ? "Spaces are not allowed" : undefined;

export const requiredCheckbox = (values) => {
  let present = 0;
  if (values) {
    const length = Object.keys(values).length;
    Object.keys(values).forEach((value) => {
      values[value] && present++;
    });
    return present === length ? undefined : "Required";
  } else {
    return "Required";
  }
};

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const maxPhotos = (max) => (value) =>
  value && value.length > max ? `Must be ${max} photos or less` : undefined;

export const fileSize = (max) => (photos) => {
  let error = undefined;
  photos &&
    photos.forEach((photo) => {
      if (!error)
        error =
          photo && photo.file && photo.file.size > max
            ? `Size Must be less than ${max / 100000} MBs`
            : undefined;
    });
  return error;
};

export const fullname = (value) =>
  value && value.split(" ").length <= 1 ? `Last name is required` : undefined;

export const minLength = (min) => (value) =>
  value && value.length < min
    ? `Must be ${min} characters or greater`
    : undefined;

export const exactLength = (len) => (value) =>
  value && value.length !== len ? `Must be ${len} characters` : undefined;

export const number = (value) =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;

// export const minValue = (min) => (value) =>
//   value && value < min ? `Must be at least ${min}` : undefined;

export const minValue = (value) =>
  value && value < 0 ? `Must be at least 0` : undefined;

export const validateDate = (value, allValues, props) => {
  const { from_dt } = allValues;
  if (from_dt && value) {
    if (moment(value).isBefore(moment(from_dt))) {
      return "End Date should be after Start Date";
    }
  }
};

export const confirmPassword = (value, allValues, props) => {
  const { password } = allValues;
  return password && value !== password ? 'Passwords don"t match' : undefined;
};

export const validateGoogleOrigin = (value, allValues, props) => {
  // console.log("_______________________");
  // console.log(value);
  // console.log("_______________________");

  return value && typeof value == "object" ? undefined : "Please select origin";
};
